import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ExchangeRates } from "../../../redux/toolkit/exchangeRates/operation";
import CurrencyFlag from "react-currency-flags";
import "./index.css";
import { LOGO_URL } from '../../../constants/stringConstants'


const InterestAccountCard = (props:any) => {
  const dispatch = useDispatch<AppDispatch>();
  const exchangeRates: any = useSelector(
    (state: RootState) => state.exchangeRates.data
  );
  const [exchangeRateState, setExchnageRate] = useState<any>([]);
  const [loader, setLoader] = useState(false);
  const accData: any = localStorage.getItem("AccountData");
  const ParsedData: any = JSON.parse(accData);

  const { t } = useTranslation();

  const handleRefresh = () => {
    setLoader(true);
  };

  useEffect(() => {
    const payLoadData = {
      accCurrency: ParsedData?.currency,
    };

    dispatch(ExchangeRates(payLoadData));
    setLoader(true);
  }, [dispatch, loader]);

  useEffect(() => {
    if (exchangeRates && exchangeRates.data && exchangeRates.data.data) {
      setExchnageRate(exchangeRates.data.data);
      localStorage.setItem(
        "exchangeRates",
        JSON.stringify(exchangeRates.data.data)
      );
      setLoader(false);
    }
  }, [exchangeRateState, exchangeRates, loader]);

  const CurrencyFlagComponent = (logo: string) => (
    <CurrencyFlag currency={logo} />
  );

  return (
    <div className="interest-account-card-wrapper" style={{marginTop:20,marginRight:props.showLogo?150:0}}>
      {props.hideInfo?'': <p className="interest-text">{t("interestAccountCard.title")}</p>}

      <div className="interest-account-box">
        {props.showLogo?
      <div className="image-icons-bank mb-5">
          <img src={LOGO_URL} alt="avatar" className="image-logo-card" />
          <span className="card-currency">{props.currency}</span>
        </div>:""}
      <p style={{color:"white"}}><span style={{fontWeight:"bold"}}>{t("interestAccountCard.depositAmount")}: </span><span>$100000</span></p>
      <p style={{color:"white"}}><span style={{fontWeight:"bold"}}>{t("interestAccountCard.interestAmount")}: </span><span>$100</span></p>
      {props.hideInfo?'':<p><span style={{fontWeight:"bold"}}>{t("interestAccountCard.total")}: </span><span className="text-success">$1565</span></p>}
      </div>
    </div>
  );
};
export default InterestAccountCard;
