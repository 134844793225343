import React from "react";
import { AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from 'react-bootstrap/Accordion'
import '../index.css'

const AccordianHeaderData = (props: any) => {
  return (
    <Accordion.Header
    //   expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1bh-content"
      id="panel1bh-header"
      style={{ alignItems: "center" }}
    >
      {/* <p
        style={{
          width: "40%",
          fontWeight: "bold",
          flexShrink: 0,
          textAlign: "left",
          margin: "0",
        }}
      >
        2FA
      </p> */}
      <div className="accordianIcon">
        <img src={props?.img} />
      </div>
      <div className="accordianSummary">
        <p className="accordianTitle">{props?.accordianTitle}</p>
        <p className="accordianDes" style={{ textAlign: "left", margin: "0px" }}>
          {/* {t("verify.title.description")} */}
          {props?.accordianDes}
        </p>
      </div>
    </Accordion.Header>
  );
};

export default AccordianHeaderData;
