import React from "react";
import CurrencyFlag from "react-currency-flags";
import '../index.css'

const CurrencyFlagAndValue = (props: {
  currencyAmount: string | undefined;
  currencyToConvert: string | undefined;
}) => {
  const CurrencyFlagComponent = (logo?: string) => (
    <CurrencyFlag currency={logo} size="lg" />
  );
  return (
    <div className="d-flex justify-content-between align-items-center p-3">
      <div className="d-flex justify-content-between align-items-between">
        <div className="exchangeRateCountryFlag">
          {CurrencyFlagComponent(props?.currencyToConvert)}
        </div>
        <p className="countryCode">{props?.currencyToConvert}</p>
      </div>
      <div>
        <p className="currency-amount">
          {props?.currencyAmount}
        </p>
      </div>
    </div>
  );
};

export default CurrencyFlagAndValue;
