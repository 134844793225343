import { createSlice } from "@reduxjs/toolkit";
import { ExpenseCardResponse } from "./interface";
import { getUserExpense } from "./operation";

const initialState = {
  data: {},
} as ExpenseCardResponse;

const expenseSlice = createSlice({
  name: "expenseDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserExpense.fulfilled, (state: any, action) => {
      state.data = action.payload;
    });
  },
});

export default expenseSlice.reducer;
