import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { errorMsgs, regexStrConst } from "../../../../constants/stringConstants";

import '../../index.css'
import { B4BCardHolder, B4BCardHolderAddress, B4BCardHolderShippingAddress } from "../../interface";
import { useDispatch, useSelector } from "react-redux";
import cardSelectors from "../../../../redux/toolkit/cards/selector";
import { AppDispatch, RootState } from "../../../../redux/store";
import { createPhysicalCard, createVirtualCard, getAllCardOrders, getAllCards } from "../../../../redux/toolkit/cards/operation";
import { CreateCardPayload, CreateVirtualCardPayload } from "../../../../redux/toolkit/cards/interface";
import * as iso from "iso-3166-1";
import { Form, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import { Box, Tab, Tabs } from "@mui/material";
import { toNumber } from "lodash";
import InputTypeText from "../../../../components/shared/Input/Basic/InputTypeText";
import BasicButton from "../../../../components/shared/Buttons/Basic";
import { Country } from "iso-3166-1/dist/iso-3166";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/bootstrap.css'
import { showAlert } from "../../../../redux/toolkit/swal/reducer";
import { ErrorImage, SuccessImage } from "../../../../constants/Images";

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  tabPanel: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, tabPanel, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabPanel !== index}
      id={`card-typepanel-${index}`}
      aria-labelledby={`card-type-${index}`}
      {...other}
    >
      {tabPanel === index && (
        <Box sx={{ p: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `card-type-${index}`,
    'aria-controls': `card-typepanel-${index}`,
  };
}

type DefaultAccountsOptions = {
  accountName: string;
  accB4BUUID: string;
}

type ParsedAccountsOptions = {
  label: string; //Business Account Name
  value: string; //Business Account UUID - accB4BUUID
}

// Get the current year
const currentYear = new Date().getFullYear();
const maxYear = currentYear - 18;

// Construct the regular expression
const dobRegex = new RegExp(`^(19[0-9]{2}|20[0-${maxYear.toString().slice(2, 3)}][0-${maxYear.toString().slice(3)}])-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$`);
const emailRegex = regexStrConst.emailRegex;

const ApplyforCardForm = (props: {
  cardHolder: B4BCardHolder,
  setCardHolder: React.Dispatch<React.SetStateAction<B4BCardHolder>>,
  cardHolderAddress: B4BCardHolderAddress,
  setCardHolderAddress: React.Dispatch<React.SetStateAction<B4BCardHolderAddress>>,
  setApplyCard: any
}) => {
  const { cardHolder, setCardHolder, cardHolderAddress, setCardHolderAddress, setApplyCard } = props;
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [errors, setErrors] = useState<B4BCardHolder & B4BCardHolderAddress & B4BCardHolderShippingAddress>({
    // mandatory fields for card holder
    first_name: "",
    last_name: "",
    dateOfBirth: "",
    email: "",
    locale: "",
    //optional fields for card holder
    title: "",
    gender: "",
    mobileNo: "",
    telephoneNo: "",
    //CONDITIONAL: Mandatory for physical card order
    embossedName: "",

    // mandatory fields for card holder address
    line_1: "",
    city: "",
    post_code: "",
    //optional fields for card holder address
    country_code: "",
    line_2: "",
    line_3: "",

    // mandatory fields for card holder SHIPPING address
    s_line_1: "",
    s_city: "",
    s_post_code: "",
    // optional fields for card holder SHIPPING address
    s_country_code: "",
    s_line_2: "",
    s_line_3: "",
  })
  const [missingFields, setMissingFields] = useState<boolean>();
  const [termsChecked, setTermsChecked] = useState<boolean>(false);
  const [privacyChecked, setPrivacyChecked] = useState<boolean>(false);
  const [differentShippingAddress, setDifferentShippingAddress] = useState<boolean>(false);
  const [tabPanel, setTabPanel] = useState<number>(0);
  const [parsedBusinessAccounts, setParsedBusinessAccounts] = useState<ParsedAccountsOptions[]>([]);
  const [ibanAccUUID, setIbanAccUUID] = useState<string>("");

  const [shippingAddress, setShippingAddress] = useState<B4BCardHolderShippingAddress>({
    s_line_1: "",
    s_city: localStorage.getItem("city") || "",
    s_post_code: localStorage.getItem("postalCode") || "",
    s_country_code: localStorage.getItem("country") !== null ? iso.whereCountry(localStorage.getItem("country")!)!.numeric : "",
  });

  const createPhysicalCardSelector = useSelector(cardSelectors.createPhysicalCardSelect);
  const createVirtualCardSelector = useSelector(cardSelectors.createVirtualCardSelect);
  const businessAccSelector: any = useSelector((state: RootState) => state.getAllAccounts.data);

  const cardHolderFields = ["embossedName", "title", "gender", "first_name", "last_name", "dateOfBirth", "email", "mobileNo", "telephoneNo"];
  const cardHolderAddressFields = ["line_1", "city", "post_code", "country_code"];
  const shippingAddressFields = ["s_line_1", "s_city", "s_post_code", "s_country_code"];

  // Define an array of mandatory field names for checking error before submit
  const mandatoryCardHolderFields: Array<keyof B4BCardHolder> = ["first_name", "last_name", "dateOfBirth", "email", "locale"];

  // A list of all countries to create a country list dropdown
  const countries: Country[] = iso.all();

  useEffect(() => {
    if (businessAccSelector && businessAccSelector?.data?.data) {
      setParsedBusinessAccounts(parseBusinessAccounts(businessAccSelector.data.data))
    } else {
      setParsedBusinessAccounts([])
    }
  }, [businessAccSelector]);

  // Effect to set the value to the first option on component mount
  useEffect(() => {
    if (parsedBusinessAccounts.length > 0) {
      setIbanAccUUID(parsedBusinessAccounts[0].value);
    }
  }, [parsedBusinessAccounts]);

  const parseBusinessAccounts = (data: DefaultAccountsOptions[]): ParsedAccountsOptions[] => {
    return data.map(account => ({
      label: account.accountName,
      value: account.accB4BUUID
    }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (cardHolderFields.includes(name)) {
      if (name !== "mobileNo" && name !== "telephoneNo" && name !== "dateOfBirth") {
        if (name === "email") {
          !emailRegex.test(value)
            ? setErrors({ ...errors, email: errorMsgs.incorrectEmail })
            : setErrors({ ...errors, email: "" })
          const trimmedValue = value.replace(/\s/g, '');
          setCardHolder({ ...cardHolder, [name]: trimmedValue })
        } else {
          setCardHolder({ ...cardHolder, [name]: value })
        }
      } else {
        // Allow only numeric characters in the fields below
        if (name === "dateOfBirth") {
          !dobRegex.test(value)
            ? setErrors({ ...errors, dateOfBirth: t("applyForCardModal.validDate") })
            : setErrors({ ...errors, dateOfBirth: "" })
          const numericValue = value.replace(/[^-\d]/g, ''); // Filter out non-numeric characters except '-' for DOB
          setCardHolder({ ...cardHolder, [name]: numericValue });
        } else {
          const numericValue = value.replace(/[^+\d]/g, ''); // Filter out non-numeric characters except '+' for mobile and telephone number
          setCardHolder({ ...cardHolder, [name]: numericValue });
        }
      }
    } else if (cardHolderAddressFields.includes(name)) {
      setCardHolderAddress({ ...cardHolderAddress, [name]: value });
    } else if (shippingAddressFields.includes(name)) {
      setShippingAddress({ ...shippingAddress, [name]: value });
    }
  };

  const handleBlur = (name: string, value: string, errorMessage: string) => {
    if (value === "") {
      setErrors({ ...errors, [name]: errorMessage })
    } else if (value !== "" && name === "dateOfBirth") {
      !dobRegex.test(value)
        ? setErrors({ ...errors, dateOfBirth: t("applyForCardModal.validDate") })
        : setErrors({ ...errors, dateOfBirth: "" })
    } else if (value !== "" && name === "email") {
      !emailRegex.test(value)
        ? setErrors({ ...errors, email: errorMsgs.incorrectEmail })
        : setErrors({ ...errors, email: "" })
    } else {
      setErrors({ ...errors, [name]: "" })
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Ensure all the fields have been filled in
    const missingCardHolderField = mandatoryCardHolderFields.some(field => cardHolder[field] === "");
    const missingCardHolderAddressField = Object.values(cardHolderAddress).some(field => field === "");
    if (missingCardHolderField || missingCardHolderAddressField
      // || (!termsChecked || !privacyChecked)
    ) {
      setMissingFields(true);
    } else {
      setMissingFields(false);

      switch (tabPanel) {
        case 0: {
          const virtualCardPayload: CreateVirtualCardPayload = {
            userId: localStorage.getItem('userId')!,
            cardDetails: {
              uuid: ibanAccUUID,
              cardholder: {
                title: cardHolder.title,
                first_name: cardHolder.first_name,
                last_name: cardHolder.last_name,
                gender: cardHolder.gender,
                dob: cardHolder.dateOfBirth,
                tel: cardHolder.telephoneNo,
                mobile: cardHolder.mobileNo,
                email: cardHolder.email,
                locale: cardHolder.locale
              },
              address: {
                line_1: cardHolderAddress.line_1,
                city: cardHolderAddress.city,
                post_code: cardHolderAddress.post_code,
                country_code: toNumber(cardHolderAddress.country_code)
              }
            }
          }
          // console.log("Virtual CARD SELECTED", virtualCardPayload);
          dispatch(createVirtualCard(virtualCardPayload))
            .unwrap()
            .then((resp) => {
              // console.log("this is create card resp", resp)
              setApplyCard(false);
              dispatch(getAllCards({ userId: localStorage.getItem('userId')! }))
              // Swal.fire({
              //   icon: "success",
              //   title: "Success",
              //   confirmButtonColor: "#86B6EF",
              //   text: "Your virtual card is ready to use!",
              // });
              dispatch(showAlert({
                type: 'success',
                title: `${t("sweetAlerts.success")}`,
                text: `${t("sweetAlerts.virtualCardReady")}`,
                imageUrl: SuccessImage,
                imageAlt: 'Success Image'
              }));
            })
            .catch((err) => {
              // console.log("this is create card error", err)
              // Swal.fire({
              //   icon: "error",
              //   title: "Error",
              //   confirmButtonColor: "#86B6EF",
              //   text: "Error while creating virtual card!",
              // });
              dispatch(showAlert({
                type: 'error',
                title: `${t("sweetAlerts.error")}`,
                text: `${t("sweetAlerts.errorCreatingVirtualCard")}`,
                imageUrl: ErrorImage,
                imageAlt: 'Error Image'
              }));
            });
          break;
        }
        case 1: {
          const physicalCardPayload: CreateCardPayload = {
            userId: localStorage.getItem('userId')!,
            cardDetails: {
              uuid: ibanAccUUID,
              embossed_name: cardHolder.embossedName || `${cardHolder.first_name} ${cardHolder.last_name}`,
              cardholder: {
                title: cardHolder.title,
                first_name: cardHolder.first_name,
                last_name: cardHolder.last_name,
                gender: cardHolder.gender,
                dob: cardHolder.dateOfBirth,
                tel: cardHolder.telephoneNo,
                mobile: cardHolder.mobileNo,
                email: cardHolder.email,
                locale: cardHolder.locale
              },
              address: {
                line_1: cardHolderAddress.line_1,
                city: cardHolderAddress.city,
                post_code: cardHolderAddress.post_code,
                country_code: toNumber(cardHolderAddress.country_code)
              },
              ...(differentShippingAddress ? {
                delivery_address: {
                  fao: `${cardHolder.first_name} ${cardHolder.last_name}`,
                  address: {
                    line_1: shippingAddress.s_line_1,
                    city: shippingAddress.s_city,
                    post_code: shippingAddress.s_post_code,
                    country_code: toNumber(shippingAddress.s_country_code)
                  }
                }
              } : { "": "" }),
              shipping_supplier: 'standard',
              // PAYMENT METHOD IS FOR THE CARD. FOR ALL OUR USERS, IT WILL BE CREDITS AS WE PURCHASE THE CARD STOCK
              // AND ASSIGN IT TO OUR USERS. IT CAN BE INVOICE FOR TESTING AS CREDITS WOULD END IN AN ERROR OTHERWISE.
              payment_method: process.env.REACT_APP_ENV === 'production' ? 'credits' : 'invoice'
            }
          };
          // console.log("Physical CARD SELECTED", physicalCardPayload);
          dispatch(createPhysicalCard(physicalCardPayload))
            .unwrap()
            .then((resp) => {
              // console.log("this is create card resp", resp)
              setApplyCard(false);
              dispatch(getAllCardOrders({ userId: localStorage.getItem('userId')! }))
              // Swal.fire({
              //   icon: "success",
              //   title: "Success",
              //   confirmButtonColor: "#86B6EF",
              //   text: "Your physical card has been ordered!",
              // });
              dispatch(showAlert({
                type: 'success',
                title: `${t("sweetAlerts.success")}`,
                text: `${t("sweetAlerts.physicalCardOrdered")}`,
                imageUrl: SuccessImage,
                imageAlt: 'Success Image'
              }));
            })
            .catch((err) => {
              // console.log("this is create card error", err)
              // Swal.fire({
              //   icon: "error",
              //   title: "Error",
              //   confirmButtonColor: "#86B6EF",
              //   text: "Error ordering physical card!",
              // });
              dispatch(showAlert({
                type: 'error',
                title: `${t("sweetAlerts.error")}`,
                text: `${t("sweetAlerts.errorOrderingPhysicalCard")}`,
                imageUrl: ErrorImage,
                imageAlt: 'Error Image'
              }));
            });
          break;
        }
        default:
          break;
      }
    }
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabPanel(newValue);
  };

  const getCurrentDateMinus18Years = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 18);
    return today.toISOString().split('T')[0];
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Tabs
          value={tabPanel}
          onChange={handleTabChange}
          aria-label="card variant tabs"
          variant="fullWidth"
        >
          <Tab sx={{ fontFamily: 'Montserrat', fontWeight: 600 }} label= {t("applyForCardModal.virtualCard")} {...a11yProps(0)} />
          <Tab sx={{ fontFamily: 'Montserrat', fontWeight: 600 }} label= {t("applyForCardModal.physicalCard")} {...a11yProps(1)} />
        </Tabs>

        {/* VIRTUAL CARD TAB */}
        <CustomTabPanel tabPanel={tabPanel} index={0}>
          <label>{t("applyForCardModal.businessAccount")} *</label>
          <br />
          <Form.Text id="businessAccountSelectionHelpBlock" muted>
            {t("applyForCardModal.businessAccountInfo")}
          </Form.Text>
          <Form.Select
            className="enter-num-inp"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setIbanAccUUID(e.target.value);
            }}
            aria-describedby="businessAccountSelectionHelpBlock"
          >
            {parsedBusinessAccounts.length > 0
              ? parsedBusinessAccounts.map((busAcc: ParsedAccountsOptions, index: number) => (
                <option key={busAcc.value} value={busAcc.value}>
                  {busAcc.label}
                </option>
              ))
              : <option key={"no-accounts"} value={""} disabled>
                {t("applyForCardModal.noCorrespondingAccounts")}
              </option>
            }
          </Form.Select>

          <label>Title</label>
          <Form.Select
            className="enter-num-inp"
            name="title"
            value={cardHolder.title}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setCardHolder({ ...cardHolder, title: e.target.value });
            }}
            aria-describedby="cardHolderTitleSelect"
          >
            <option value="" disabled>{t("applyForCardModal.selectTitle")}</option>
            <option value="Mr">Mr</option>
            <option value="Mrs">Mrs</option>
            <option value="Miss">Miss</option>
            <option value="Ms">Ms</option>
            <option value="Dr">Dr</option>
          </Form.Select>

          <label>{t("applyForCardModal.firstName")} <Form.Text>({t("applyForCardModal.appearsOnCard")})</Form.Text>*</label>
          <InputTypeText
            compulsoryValue
            maxLength={20}
            placeholder={t("applyforcard.form.firstName")}
            value={cardHolder.first_name}
            name="first_name"
            onBlur={(event) => handleBlur(event.target.name, event.target.value, t("applyForCardModal.enterFirstName"))}
            onChange={(e) => handleInputChange(e)}
            errorMsg={errors.first_name}
          />

          <label>{t("applyForCardModal.lastName")} <Form.Text>({t("applyForCardModal.appearsOnCard")})</Form.Text>*</label>
          <InputTypeText
            compulsoryValue
            maxLength={20}
            placeholder={t("applyforcard.form.lastName")}
            value={cardHolder.last_name}
            name="last_name"
            onBlur={(event) => handleBlur(event.target.name, event.target.value, t("applyForCardModal.enterLastName"))}
            onChange={(e) => handleInputChange(e)}
            errorMsg={errors.last_name}
          />

          <label>{t("applyForCardModal.DOB")} *</label>
          <InputTypeText
            compulsoryValue
            maxLength={10}
            placeholder={t("applyforcard.form.dob")}
            value={cardHolder.dateOfBirth}
            name="dateOfBirth"
            onBlur={(event) => handleBlur(event.target.name, event.target.value, t("applyForCardModal.emptyDOB"))}
            onChange={(e) => handleInputChange(e)}
            errorMsg={errors.dateOfBirth}
            type="date"
            min="1900-01-01"
            max={getCurrentDateMinus18Years()}
          />

          <label>{t("applyForCardModal.email")} *</label>
          <InputTypeText
            compulsoryValue
            maxLength={50}
            placeholder={t("applyforcard.form.email")}
            value={cardHolder.email}
            name="email"
            onBlur={(event) => handleBlur(event.target.name, event.target.value, errorMsgs.emptyEmail)}
            onChange={(e) => handleInputChange(e)}
            errorMsg={errors.email}
          />

          <label>{t("applyForCardModal.mobileNumber")}</label>
          <PhoneInput
            inputClass="countryPhoneInput"
            buttonClass="countryFlagButtonPhoneInput"
            country={iso.whereCountry(localStorage.getItem("country")!)!.alpha2.toLowerCase()}
            value={cardHolder.mobileNo}
            placeholder={t("applyforcard.form.mobilenumber")!}
            inputProps={{
              name: "mobileNo",
              maxLength: 20,
              minLength: 10,
            }}
            inputStyle={{
              fontWeight: "600",
              fontFamily: "Montserrat",
              color: "black",
            }}
            buttonStyle={{
              border: "none",
              backgroundColor: "#E6F0FA",
            }}
            containerStyle={{
              marginBottom: "10px",
            }}
            onChange={(value, country, e, formattedValue) => handleInputChange(e)}
          />

          <label>{t("applyForCardModal.telephoneNumber")}</label>
          <PhoneInput
            inputClass="countryPhoneInput"
            buttonClass="countryFlagButtonPhoneInput"
            country={iso.whereCountry(localStorage.getItem("country")!)!.alpha2.toLowerCase()}
            value={cardHolder.telephoneNo}
            placeholder={t("applyforcard.form.phonenumber")!}
            inputProps={{
              name: "telephoneNo",
              maxLength: 20,
              minLength: 10,
            }}
            inputStyle={{
              fontWeight: "600",
              fontFamily: "Montserrat",
              color: "black",
            }}
            buttonStyle={{
              border: "none",
              backgroundColor: "#E6F0FA",
            }}
            containerStyle={{
              marginBottom: "10px",
            }}
            onChange={(value, country, e, formattedValue) => handleInputChange(e)}
          />

          <label>{t("applyForCardModal.address")} *</label>
          <InputTypeText
            compulsoryValue
            maxLength={50}
            placeholder={t("applyforcard.form.address")}
            value={cardHolderAddress.line_1}
            name="line_1"
            onBlur={(event) => handleBlur(event.target.name, event.target.value, errorMsgs.emptyAddress)}
            onChange={(e) => handleInputChange(e)}
            errorMsg={errors.line_1}
          />

          <label>{t("applyForCardModal.city")} *</label>
          <InputTypeText
            compulsoryValue
            maxLength={20}
            placeholder={t("applyforcard.form.city")}
            value={cardHolderAddress.city}
            name="city"
            onBlur={(event) => handleBlur(event.target.name, event.target.value, errorMsgs.emptyCity)}
            onChange={(e) => handleInputChange(e)}
            errorMsg={errors.city}
          />

          <label>{t("applyForCardModal.zipCode")} *</label>
          <InputTypeText
            compulsoryValue
            placeholder={t("applyforcard.form.zipCode")}
            value={cardHolderAddress.post_code}
            name="post_code"
            onBlur={(event) => handleBlur(event.target.name, event.target.value, errorMsgs.emptyZipCode)}
            onChange={(e) => handleInputChange(e)}
            errorMsg={errors.post_code}
          />

          <label>{t("applyForCardModal.country")} *</label>
          <Form.Select
            className="enter-num-inp"
            name="country_code"
            value={cardHolderAddress.country_code}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setCardHolderAddress({ ...cardHolderAddress, country_code: e.target.value });
            }}
            aria-describedby="cardHolderCountrySelect"
          >
            <option value="" disabled>{t("addRecipient.form.selectCountry")}</option>
            {countries &&
              countries.map((ele: Country, index: number) => (
                <option value={ele.numeric} key={index}>
                  {ele.country}
                </option>
              ))}
          </Form.Select>
        </CustomTabPanel>

        {/* PHYSICAL CARD TAB */}
        <CustomTabPanel tabPanel={tabPanel} index={1}>
          <label>{t("applyForCardModal.businessAccount")} *</label>
          <br />
          <Form.Text id="businessAccountSelectionHelpBlock" muted>
            {t("applyForCardModal.businessAccountInfo")}
          </Form.Text>
          <Form.Select
            className="enter-num-inp"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setIbanAccUUID(e.target.value);
            }}
            aria-describedby="businessAccountSelectionHelpBlock"
          >
            {parsedBusinessAccounts.length > 0
              ? parsedBusinessAccounts.map((busAcc: ParsedAccountsOptions, index: number) => (
                <option key={busAcc.value} value={busAcc.value}>
                  {busAcc.label}
                </option>
              ))
              : <option key={"no-accounts"} value={""} disabled>
                {t("applyForCardModal.noCorrespondingAccounts")}
              </option>
            }
          </Form.Select>

          <label>{t("applyForCardModal.embossedName")} <Form.Text>({t("applyForCardModal.appearsOnCard")})</Form.Text>*</label>
          <InputTypeText
            compulsoryValue
            maxLength={27}
            placeholder= {t("applyForCardModal.nameToBeEmbossed")}
            value={cardHolder.embossedName}
            name="embossedName"
            onBlur={(event) => handleBlur(event.target.name, event.target.value, t("applyForCardModal.enterNameToBeEmbossed"))}
            onChange={(e) => handleInputChange(e)}
            errorMsg={errors.embossedName}
            inputContainerMargins="0"
          />

          {/* Cardholder Information Section::: START */}
          <div>
            <br />
            <Form.Text>{t("applyForCardModal.cardholderInfo")}</Form.Text>
            <br />
            <label>{t("applyForCardModal.title")}</label>
            <Form.Select
              className="enter-num-inp"
              name="title"
              value={cardHolder.title}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setCardHolder({ ...cardHolder, title: e.target.value });
              }}
              aria-describedby="cardHolderTitleSelect"
            >
              <option value="" disabled>{t("applyForCardModal.selectTitle")}</option>
              <option value="Mr">Mr</option>
              <option value="Mrs">Mrs</option>
              <option value="Miss">Miss</option>
              <option value="Ms">Ms</option>
              <option value="Dr">Dr</option>
            </Form.Select>

            <label>{t("applyForCardModal.firstName")} *</label>
            <InputTypeText
              compulsoryValue
              maxLength={20}
              placeholder={t("applyforcard.form.firstName")}
              value={cardHolder.first_name}
              name="first_name"
              onBlur={(event) => handleBlur(event.target.name, event.target.value, t("applyForCardModal.enterFirstName"))}
              onChange={(e) => handleInputChange(e)}
              errorMsg={errors.first_name}
            />

            <label>{t("applyForCardModal.lastName")} *</label>
            <InputTypeText
              compulsoryValue
              maxLength={20}
              placeholder={t("applyforcard.form.lastName")}
              value={cardHolder.last_name}
              name="last_name"
              onBlur={(event) => handleBlur(event.target.name, event.target.value, t("applyForCardModal.enterLastName"))}
              onChange={(e) => handleInputChange(e)}
              errorMsg={errors.last_name}
            />

            <label>{t("applyForCardModal.DOB")} *</label>
            <InputTypeText
              compulsoryValue
              maxLength={10}
              placeholder={t("applyforcard.form.dob")}
              value={cardHolder.dateOfBirth}
              name="dateOfBirth"
              onBlur={(event) => handleBlur(event.target.name, event.target.value, t("applyForCardModal.emptyDOB"))}
              onChange={(e) => handleInputChange(e)}
              errorMsg={errors.dateOfBirth}
              type="date"
              min="1900-01-01"
              max={getCurrentDateMinus18Years()}
            />

            <label>{t("applyForCardModal.email")} *</label>
            <InputTypeText
              compulsoryValue
              maxLength={50}
              placeholder={t("applyforcard.form.email")}
              value={cardHolder.email}
              name="email"
              onBlur={(event) => handleBlur(event.target.name, event.target.value, errorMsgs.emptyEmail)}
              onChange={(e) => handleInputChange(e)}
              errorMsg={errors.email}
            />

            <label>{t("applyForCardModal.mobileNumber")}</label>
            <PhoneInput
              inputClass="countryPhoneInput"
              buttonClass="countryFlagButtonPhoneInput"
              country={iso.whereCountry(localStorage.getItem("country")!)!.alpha2.toLowerCase()}
              value={cardHolder.mobileNo}
              placeholder={t("applyforcard.form.mobilenumber")!}
              inputProps={{
                name: "mobileNo",
                maxLength: 20,
                minLength: 10,
              }}
              inputStyle={{
                fontWeight: "600",
                fontFamily: "Montserrat",
                color: "black",
              }}
              buttonStyle={{
                border: "none",
                backgroundColor: "#E6F0FA",
              }}
              containerStyle={{
                marginBottom: "10px",
              }}
              onChange={(value, country, e, formattedValue) => handleInputChange(e)}
            />

            <label>{t("applyForCardModal.telephoneNumber")}</label>
            <PhoneInput
              inputClass="countryPhoneInput"
              buttonClass="countryFlagButtonPhoneInput"
              country={iso.whereCountry(localStorage.getItem("country")!)!.alpha2.toLowerCase()}
              value={cardHolder.telephoneNo}
              placeholder={t("applyforcard.form.phonenumber")!}
              inputProps={{
                name: "telephoneNo",
                maxLength: 20,
                minLength: 10,
              }}
              inputStyle={{
                fontWeight: "600",
                fontFamily: "Montserrat",
                color: "black",
              }}
              buttonStyle={{
                border: "none",
                backgroundColor: "#E6F0FA",
              }}
              containerStyle={{
                marginBottom: "10px",
              }}
              onChange={(value, country, e, formattedValue) => handleInputChange(e)}
            />
          </div>
          {/* Cardholder Information Section::: END */}

          {/* Cardholder Address Section::: START */}
          <div>
            <br />
            <Form.Text>{t("applyForCardModal.address")}</Form.Text>
            <br />
            <label>{t("applyForCardModal.address")} *</label>
            <InputTypeText
              compulsoryValue
              maxLength={50}
              placeholder={t("applyforcard.form.address")}
              value={cardHolderAddress.line_1}
              name="line_1"
              onBlur={(event) => handleBlur(event.target.name, event.target.value, errorMsgs.emptyAddress)}
              onChange={(e) => handleInputChange(e)}
              errorMsg={errors.line_1}
            />

            <label>{t("applyForCardModal.city")} *</label>
            <InputTypeText
              compulsoryValue
              maxLength={20}
              placeholder={t("applyforcard.form.city")}
              value={cardHolderAddress.city}
              name="city"
              onBlur={(event) => handleBlur(event.target.name, event.target.value, errorMsgs.emptyCity)}
              onChange={(e) => handleInputChange(e)}
              errorMsg={errors.city}
            />

            <label>{t("applyForCardModal.zipCode")} *</label>
            <InputTypeText
              compulsoryValue
              placeholder={t("applyforcard.form.zipCode")}
              value={cardHolderAddress.post_code}
              name="post_code"
              onBlur={(event) => handleBlur(event.target.name, event.target.value, errorMsgs.emptyZipCode)}
              onChange={(e) => handleInputChange(e)}
              errorMsg={errors.post_code}
            />

            <label>{t("applyForCardModal.country")} *</label>
            <Form.Select
              className="enter-num-inp"
              name="country_code"
              value={cardHolderAddress.country_code}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setCardHolderAddress({ ...cardHolderAddress, country_code: e.target.value });
              }}
              aria-describedby="cardHolderCountrySelect"
            >
              <option value="" disabled>{t("addRecipient.form.selectCountry")}</option>
              {countries &&
                countries.map((ele: Country, index: number) => (
                  <option value={ele.numeric} key={index}>
                    {ele.country}
                  </option>
                ))}
            </Form.Select>
          </div>
          {/* Cardholder Address Section::: END */}

          {/* Separate Shipping Address Validation */}
          <div style={{ display: "flex", alignItems: "center", width: "100%", gap: "10px" }}>
            <input
              type="checkbox"
              id="shippingAddCheck"
              name="shippingAddCheck"
              checked={differentShippingAddress}
              onChange={() => setDifferentShippingAddress(!differentShippingAddress)}
            />
            <label htmlFor="shippingAddCheck" style={{ width: "100%", fontSize: "13px", cursor: "pointer" }} >
              {t("applyForCardModal.shippingAddressIsDifferent")}
            </label>
          </div>

          {/* Shipping Address Section::: START */}
          {differentShippingAddress &&
            <div>
              <br />
              <Form.Text>{t("applyForCardModal.shippingAddress")}</Form.Text>
              <br />
              <label>{t("applyForCardModal.address")} *</label>
              <InputTypeText
                compulsoryValue
                maxLength={50}
                placeholder={t("applyforcard.form.address")}
                value={shippingAddress.s_line_1}
                name="s_line_1"
                onBlur={(event) => handleBlur(event.target.name, event.target.value, errorMsgs.emptyAddress)}
                onChange={(e) => handleInputChange(e)}
                errorMsg={errors.s_line_1}
              />

              <label>{t("applyForCardModal.city")} *</label>
              <InputTypeText
                compulsoryValue
                maxLength={20}
                placeholder={t("applyforcard.form.city")}
                value={shippingAddress.s_city}
                name="s_city"
                onBlur={(event) => handleBlur(event.target.name, event.target.value, errorMsgs.emptyCity)}
                onChange={(e) => handleInputChange(e)}
                errorMsg={errors.s_city}
              />

              <label>{t("applyForCardModal.zipCode")} *</label>
              <InputTypeText
                compulsoryValue
                placeholder={t("applyforcard.form.zipCode")}
                value={shippingAddress.s_post_code}
                name="s_post_code"
                onBlur={(event) => handleBlur(event.target.name, event.target.value, errorMsgs.emptyZipCode)}
                onChange={(e) => handleInputChange(e)}
                errorMsg={errors.s_post_code}
              />

              <label>{t("applyForCardModal.country")} *</label>
              <Form.Select
                className="enter-num-inp"
                name="s_country_code"
                value={shippingAddress.s_country_code}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setShippingAddress({ ...shippingAddress, s_country_code: e.target.value });
                }}
                aria-describedby="cardHolderCountrySelect"
              >
                <option value="" disabled>{t("addRecipient.form.selectCountry")}</option>
                {countries &&
                  countries.map((ele: Country, index: number) => (
                    <option value={ele.numeric} key={index}>
                      {ele.country}
                    </option>
                  ))}
              </Form.Select>
            </div>}
          {/* Shipping Address Section::: END */}
        </CustomTabPanel>

        {ibanAccUUID === ""
          ? <p style={{ color: "red", marginBottom: 0 }}>{t("applyForCardModal.businessAccountsNotFound")}</p>
          : missingFields === true
            ? <p style={{ color: "red", marginBottom: 0 }}>{t("applyForCardModal.fillAllFields")}</p>
            : <></>}
        <div className="applyforCard_btnSubmit">
          <BasicButton
            type="submit"
            disabled={ibanAccUUID === ""}
            style={{ height: "40px" }}
            text= {t("forgotPassword.submit")}
            isLoading={createPhysicalCardSelector.status === "loading" || createVirtualCardSelector.status === "loading"}
            spinnerVariant="sm"
          />
        </div>
      </div>
    </form>
  );
};

export default ApplyforCardForm;
