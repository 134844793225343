import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { Logout } from "../../redux/toolkit/auth/operation";
import Swal from "sweetalert2";
import { useIdleTimer } from "react-idle-timer";
import COLORS from "../../utils/colors";
import { differenceInMilliseconds, addMilliseconds } from "date-fns";
import { ErrorImage, SessionExpiredImage } from "../Images";
import { socketActions } from "../../redux/toolkit/auth/reducer";
import { showAlert } from "../../redux/toolkit/swal/reducer";

const PrivateRoute = ({ redirectTo = "/" }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const token: any = localStorage.getItem("token");
  const [state, setState] = useState<string>('')

  // const userLogout: any = useSelector((state: RootState) => state.qrDetails);

  const userLogout: any = useSelector((state: RootState) => {
    if (state.qrDetails && Object.keys(state.qrDetails.data).length !== 0) {
      return state.qrDetails;
    } else if (state.otpVerifyDetails && Object.keys(state.otpVerifyDetails.data).length !== 0) {
      return state.otpVerifyDetails
    }
  });

  axios.interceptors.response.use(
    function (response: any) {
      return Promise.resolve(response);
    },
    function (error: any) {
      if (error.response.status === 401) {
        dispatch(Logout(token));
      }
      if (error.response.status === 440) {
        dispatch(Logout(token));
        // Swal.fire({
        //   // icon: "error",
        //   imageUrl: SessionExpiredImage,
        //   title: `${t("sessionExpired")}`,
        //   // confirmButtonColor: COLORS.pimary,
        //   text: `${t("pleaseLoginAgain")}`,
        //   customClass: {
        //     confirmButton: 'modalBtnOK'
        //   }
        // });
        dispatch(showAlert({
          type: 'error',
          title: `${t("sessionExpired")}`,
          text: `${t("pleaseLoginAgain")}`,
          imageUrl: SessionExpiredImage,
          imageAlt: 'SessionExpired Image'
        }));
      } else {
        return Promise.reject(error);
      }
    }
  );

  const onIdle = () => {
    setState('idle')
    dispatch(Logout(token));
    // Swal.fire({
    //   // icon: "error",
    //   imageUrl: ErrorImage,
    //   title: `${t("sessionExpired")}`,
    //   // confirmButtonColor: COLORS.pimary,
    //   text: `${t("pleaseLoginAgain")}`,
    //   customClass: {
    //     confirmButton: 'modalBtnOK'
    //   }
    // });
    dispatch(showAlert({
      type: 'error',
      title: `${t("sessionExpired")}`,
      text: `${t("pleaseLoginAgain")}`,
      imageUrl: ErrorImage,
      imageAlt: 'Error Image'
    }));
  }
  const onAction = (event: any) => {
    setState('action')
    const lastActiveTime: any = getLastActiveTime()
    const remainingTime = getRemainingTime()
    const expiryTime = addMilliseconds(new Date(lastActiveTime), remainingTime)
    localStorage.setItem("sessionExpiry", JSON.stringify({ expiryTime }));
    const counterSeconds = differenceInMilliseconds(expiryTime, Date.now());
    if (counterSeconds < 0) {
      dispatch(Logout(token));
      // Swal.fire({
      //   // icon: "error",
      //   imageUrl: SessionExpiredImage,
      //   title: `${t("sessionExpired")}`,
      //   // confirmButtonColor: COLORS.pimary,
      //   text: `${t("pleaseLoginAgain")}`,
      //   customClass: {
      //     confirmButton: 'modalBtnOK'
      //   }
      // });
      dispatch(showAlert({
        type: 'error',
        title: `${t("sessionExpired")}`,
        text: `${t("pleaseLoginAgain")}`,
        imageUrl: SessionExpiredImage,
        imageAlt: 'SessionExpired Image'
      }));
    }
    // CONSOLES
    // console.log('getRemainingTime  =>', remainingTime)
    // console.log('getTotalActiveTime  =>', getTotalActiveTime())
    // console.log('getLastActiveTime  =>', lastActiveTime)
    // console.log('expiryTime         =>', expiryTime)
    // console.log('counterSeconds =>', counterSeconds)
    // console.log('=======================================>');
  }

  const onActive = (event: any) => {
    setState('active')
  }

  const { getRemainingTime, getActiveTime, getLastActiveTime, getTotalActiveTime } = useIdleTimer({
    onIdle,
    onAction,
    onActive,
    timeout: 500000, // for testing purpose of this functionality you can change the timeout to a lower one
    throttle: 500,
    // crossTab: true,
    // leaderElection: true,
  })


  const checkIsexpired = () => {
    const expObj: any = localStorage.getItem("sessionExpiry");
    if (expObj) {
      const exp = JSON.parse(expObj);
      const counterSeconds = differenceInMilliseconds(new Date(exp.expiryTime), Date.now());
      if (counterSeconds < 0) {
        dispatch(Logout(token));
        // Swal.fire({
        //   // icon: "error",
        //   imageUrl: SessionExpiredImage,
        //   title: `${t("sessionExpired")}`,
        //   // confirmButtonColor: COLORS.pimary,
        //   text: `${t("pleaseLoginAgain")}`,
        //   customClass: {
        //     confirmButton: 'modalBtnOK'
        //   }
        // })
        dispatch(showAlert({
          type: 'error',
          title: `${t("sessionExpired")}`,
          text: `${t("pleaseLoginAgain")}`,
          imageUrl: SessionExpiredImage,
          imageAlt: 'SessionExpired Image'
        }));
      }
      // console.log("counterSeconds =>", counterSeconds);
    }
  }


  useEffect(() => {
    if (userLogout?.data === undefined || userLogout?.data === "") {
      localStorage.clear();
      dispatch(socketActions.disconnect());
      navigate("/");
    }
  }, [userLogout]);

  useEffect(() => {
    checkIsexpired();
  })


  return token ? <Outlet /> : <Navigate to={redirectTo} />;
};

export default PrivateRoute;
