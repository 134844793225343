import React from "react";
import "../index.css";

type InputProps = {
  disabled?: boolean;
  inputStyle?: object;
  name?: string;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string | any;
  readOnly?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  type?: string;
  step?: string;
  ref?: any;
  value?: string | number;
  defaultValue?: string;
  className?: string;
  errorMsg?: string;
  errorMsgColor?: string;
  min?: string;
  max?: string;
  compulsoryValue?: boolean;
  maxLength?: number;
  minLength?: number;
  id?: string;
  inputContainerMargins?: string
};

const InputTypeText = (props: InputProps) => {
  return (
    <div style={{ margin: props.inputContainerMargins ? props.inputContainerMargins : '0 0 15px 0' }}>
      <div>
        {!props.compulsoryValue ?
          <input
            // className={`${props?.className ?props?.className: `enter-num-inp`}`}
            className={`inputTypeText ${props?.className}`}
            tabIndex={0}
            type={props.type}
            step={props.step}
            name={props.name}
            onChange={props.onChange}
            onBlur={props.onBlur}
            placeholder={props.placeholder}
            style={props.inputStyle}
            autoFocus={props.autoFocus}
            disabled={props.disabled}
            defaultValue={props.defaultValue}
            readOnly={props.readOnly}
            value={props?.value}
            required={props.required}
            ref={props.ref}
            min={props.min}
            max={props.max}
            maxLength={props.maxLength}
            minLength={props.minLength}
            id={props.id}
            onKeyPress={props.onKeyPress}
          />
          :
          <input
            // className={`${props.className ? props.className : `enter-num-inp`}`}
            className={`inputTypeText ${props?.className}`}
            tabIndex={0}
            type={props.type}
            step={props.step}
            name={props.name}
            onChange={props.onChange}
            onBlur={props.onBlur}
            placeholder={props.placeholder}
            style={props.inputStyle}
            autoFocus={props.autoFocus}
            disabled={props.disabled}
            defaultValue={props.defaultValue}
            value={props.value}
            readOnly={props.readOnly}
            required={props.required}
            ref={props.ref}
            min={props.min}
            max={props.max}
            maxLength={props.maxLength}
            minLength={props.minLength}
            id={props.id}
            onKeyPress={props.onKeyPress}
          />}
      </div>
      {props.errorMsg ? (
        <span
          style={{ color: props.errorMsgColor ? props.errorMsgColor : "red" }}
        >
          {props.errorMsg}
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default InputTypeText;