import React from 'react'
import CardTemplate from "../../../assets/images/cardAssets/omwCardTemplate.svg"
import { useTranslation } from 'react-i18next'

const PendingCardsKYBScreen = () => {
  const { t } = useTranslation();
  return (
    <div className="cardsKYBScreen">
      <img
        src={CardTemplate}
        className={`cardTemplateViewDetails`}
      />
      <p style={{ fontSize: "24px", fontWeight: "600", margin: 0 }}>
        {t("pendingCardsKYBScreen.cardOnTheWay")}
      </p>
      <p style={{ fontSize: "18px", margin: 0 }}>
        {t("pendingCardsKYBScreen.issueCardsForYourself")}
      </p>
      <p style={{ fontSize: "15px", color: "rgba(173, 182, 192, 1)", margin: 0 }}>
        {t("pendingCardsKYBScreen.kindlySendEmail")}
      </p>
    </div>
  )
}

export default PendingCardsKYBScreen;