import { createSlice } from "@reduxjs/toolkit";
import { RecentOperationResponse } from "./interface";
import { getTheRecentOeprations } from "./operation";
import { Logout } from "../auth/operation";

const initialState = {
  data: {},
} as RecentOperationResponse;

const recentOeprationSlice = createSlice({
  name: "recentOperation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTheRecentOeprations.fulfilled, (state: any, action) => {
      state.data = action.payload;
    });

    builder.addCase(Logout.fulfilled, (state: any) => {
      state.data = "";
    });
  },
});

export default recentOeprationSlice.reducer;
