import i18next from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./en/en.json";
import da from "./da/da.json";
import sv from "./sv/sv.json";

export const resources = {
  en: {
    translation: en,
  },
  da: {
    translation: da,
  },
  sv: {
    translation: sv,
  },
};

// const options = {
//   // order and from where user language should be detected
//   order: [
//     "querystring",
//     "cookie",
//     "localStorage",
//     "sessionStorage",
//     "navigator",
//     "htmlTag",
//     "path",
//     "subdomain",
//   ],

//   // keys or params to lookup language from
//   lookupQuerystring: "lng",
// };

i18next
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    // detection: options,
    fallbackLng: "en",
    debug: true,
    lng: localStorage.getItem("lng") || "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18next;
