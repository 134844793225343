import { RootState } from "../../store";

const allCardsSelect = (state: RootState) => state.getAllCards
const cardDetailsSelect = (state: RootState) => state.getCardDetails
const paymentDetailsTokenSelect = (state: RootState) => state.paymentDetailsToken
const pinControlTokenSelect = (state: RootState) => state.pinControlToken
const updateCardSelect = (state: RootState) => state.updatePrepaidCard
const createPhysicalCardSelect = (state: RootState) => state.createPhysicalCard
const createVirtualCardSelect = (state: RootState) => state.createVirtualCard
const reauthPasswordSelect = (state: RootState) => state.reauthenticatePassword
const cardTopUpSelect = (state: RootState) => state.cardTopUp
const cardUnloadSelect = (state: RootState) => state.cardUnload
const cardNotificationsSelect = (state: RootState) => state.cardNotifications
const cardNotificationsPaginatedSelect = (state: RootState) => state.cardNotificationsPaginated
const cardOrdersSelect = (state: RootState) => state.cardOrders
const activateCardSelect = (state: RootState) => state.activateCard
const createCardPromptSelect = (state: RootState) => state.createCardPrompt
const cardAutoTopupSelect = (state: RootState) => state.cardAutoTopupToggle


export default {
    allCardsSelect,
    cardDetailsSelect,
    paymentDetailsTokenSelect,
    pinControlTokenSelect,
    updateCardSelect,
    createPhysicalCardSelect,
    createVirtualCardSelect,
    reauthPasswordSelect,
    cardTopUpSelect,
    cardUnloadSelect,
    cardNotificationsSelect,
    cardOrdersSelect,
    activateCardSelect,
    cardNotificationsPaginatedSelect,
    createCardPromptSelect,
    cardAutoTopupSelect
};