import React from 'react';
import { useDispatch } from 'react-redux';
import { hideMessage } from '../../../redux/toolkit/infoMessage/reducer';
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from 'react-i18next';
import "./index.css";

const InfoMessage: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const closeMessage = () => {
    dispatch(hideMessage());
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#54a5f4',
        borderRadius: '10px',
        padding: '5px 16px',
        marginBottom: '10px',
      }}
    >
      <p
        style={{
          color: 'white',
          margin: 0,
          flex: 1,
          textAlign: 'center',
        }}
      >
        {t("infoMessage.message")}
      </p>
      <IconButton
        className='infoText-Icon'
        style={{
          backgroundColor: 'white',
          color: '#54a5f4',
          width: '22px',
          height: '22px',
          marginLeft: '10px',
        }}
        onClick={closeMessage}
      >
        <CloseIcon style={{ fontSize: '1.2rem' }} />
      </IconButton>
    </div>
  );
};

export default InfoMessage;