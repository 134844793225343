import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { Modal, Col } from "react-bootstrap";
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";
import cardSelectors from "../../../../redux/toolkit/cards/selector";
import BasicButton from "../../../../components/shared/Buttons/Basic";
import InputTypeText from "../../../../components/shared/Input/Basic/InputTypeText";
import { errorMsgs, regexStrConst } from "../../../../constants/stringConstants";
import { AppDispatch } from "../../../../redux/store";
import { activatePhysicalCard, getAllCardOrders, getAllCards } from "../../../../redux/toolkit/cards/operation";
import { ActivateCardResponse, FormattedCardOrder } from "../../../../redux/toolkit/cards/interface";
import Swal from "sweetalert2";
import { SuccessImage } from "../../../../constants/Images";
import { useTranslation } from "react-i18next";

type ActivationDetails = {
  cardCode: string,
  email: string,
  dob: string,
}

// Get the current year
const currentYear = new Date().getFullYear();
const maxYear = currentYear - 18;

// Construct the regular expression
const dobRegex = new RegExp(`^(19[0-9]{2}|20[0-${maxYear.toString().slice(2, 3)}][0-${maxYear.toString().slice(3)}])-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$`);
const cardCodeRegex = new RegExp(/^\d{9}$/);

const uk_client_enterprise_link = "https://www.b4bpayments.com/prepaid/wp-content/uploads/2020/09/B4B-UK-Corporate-Conditions-of-Business-Enterprise-Clients.pdf"
const uk_client_small_business_link = "https://www.b4bpayments.com/prepaid/wp-content/uploads/2020/09/B4B-UK-Conditions-of-Business-Small-Business.pdf"

const uk_cardholder_enterprise_link = "https://www.b4bpayments.com/prepaid/wp-content/uploads/2020/09/B4B-UK-Corporate-Card-User-Terms-Enterprise.pdf"
const uk_cardholder_small_business_link = "https://www.b4bpayments.com/prepaid/wp-content/uploads/2020/09/B4B-UK-Corporate-Card-User-Terms-Small-Business.pdf"
const uk_cardholder_link = "https://www.b4bpayments.com/prepaid/wp-content/uploads/2020/09/B4B-UK-Cardholder-Terms.pdf"

const eu_client_business_eng = "https://www.b4bpayments.com/prepaid/wp-content/uploads/2021/11/2020-10-23-B4B-Europe-Terms-and-Conditions-of-Business-v1.6.pdf"
const eu_client_business_lith = "https://www.b4bpayments.com/prepaid/wp-content/uploads/2021/11/2020-10-23-B4B-Europe-Terms-and-Conditions-of-Business-v1.6-LT.pdf"

const eu_card_user_eng = "https://www.b4bpayments.com/prepaid/wp-content/uploads/2021/11/2020-09-21-B4B-Europe-Corporate-Card-User-Terms-v1.0.pdf"
const eu_card_user_lith = "https://www.b4bpayments.com/prepaid/wp-content/uploads/2021/11/2020-09-21-B4B-Europe-Corporate-Card-User-Terms-v1.0_lt-4.pdf"

const eu_cardholder_eng = "https://www.b4bpayments.com/prepaid/wp-content/uploads/2021/11/2020-09-17-B4B-Europe-Cardholder-Terms-v1.0.pdf"
const eu_cardholder_lith = "https://www.b4bpayments.com/prepaid/wp-content/uploads/2021/11/LT-2020-09-17-B4B-Europe-Cardholder-Terms-v1.0-1.pdf"

const ActivateCardModal = (props: { cardOrder: FormattedCardOrder, activateCard: boolean, handleClose: () => void }) => {
  const { cardOrder, activateCard, handleClose } = props;
  const dispatch = useDispatch<AppDispatch>();
  const [activationDetails, setActivationDetails] = useState<ActivationDetails>({
    cardCode: "",
    email: "",
    dob: "",
  })
  const [errors, setErrors] = useState<ActivationDetails>({
    cardCode: "",
    email: "",
    dob: "",
  })
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [showTermsConditions, setShowTermsConditions] = useState<boolean>(false);
  const {t} = useTranslation();

  const activateCardSelector: any = useSelector(cardSelectors.activateCardSelect);

  useEffect(() => {
    // Check if any error message exists
    const hasErrors = Object.values(errors).some(error => error !== '');
    setHasErrors(hasErrors);
  }, [errors]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const emailRegex = regexStrConst.emailRegex;
    if (name === "cardCode") {
      !cardCodeRegex.test(value)
        ? setErrors({ ...errors, cardCode: "Ensure 9 digits are entered" })
        : setErrors({ ...errors, cardCode: "" })
    } else if (name === "email") {
      !emailRegex.test(value)
        ? setErrors({ ...errors, email: errorMsgs.incorrectEmail })
        : setErrors({ ...errors, email: "" });
    } else if (name === "dob") {
      !dobRegex.test(value)
        ? setErrors({ ...errors, dob: "Please enter the correct format (yyyy-mm-dd)" })
        : setErrors({ ...errors, dob: "" })
    }
    setActivationDetails({ ...activationDetails, [name]: value });
  };

  const handleBlur = (name: string, value: string, errorMessage: string) => {
    if (value === "") {
      setErrors({ ...errors, [name]: errorMessage });
    }
  };

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (!hasErrors) {
      dispatch(activatePhysicalCard({
        orderId: cardOrder.id,
        accB4BUUID: cardOrder.accB4BUUID,
        userId: cardOrder.userId,
        validationData: {
          card_code: activationDetails.cardCode,
          email: activationDetails.email,
          dob: activationDetails.dob,
        }
      }))
        .unwrap()
        .then((resp: ActivateCardResponse) => {
          if (resp.data) {
            console.log("RESPONSE FOR CARD ACTIVATION:", resp.data);
            dispatch(getAllCards({ userId: localStorage.getItem("userId")! }))
            dispatch(getAllCardOrders({ userId: localStorage.getItem("userId")! }))
            Swal.fire({
              // icon: "success",
              title: `${t("sweetAlerts.activationSuccess")}`,
              confirmButtonColor: "#86B6EF",
              text: `${t("sweetAlerts.cardActivated")}`,
              imageUrl: SuccessImage,
            }).then(() => { handleClose(); });
          }
        })
        .catch((err: any) => {
          // console.log("Error occured while activating physical card:", err);
          // Extract error messages from the error object
          const errorString = err.message;
          // Update errors object based on error string
          const updatedErrors = {
            cardCode: errorString.includes("Card code") ? "Card code does not match." : "",
            email: errorString.includes("Email") ? "Email does not match." : "",
            dob: errorString.includes("Date of birth") ? "Date of birth does not match." : "",
          };
          // Update state with new errors object
          setErrors(updatedErrors);
          setShowTermsConditions(false);
        });
    }
  }

  return (
    <Modal
      show={activateCard}
      centered
      onHide={handleClose}
    >
      <Modal.Header style={{ display: 'flex', alignItems: 'center', borderBottom: 0 }}>
        <div style={{ flex: "1", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Title>Activate your card</Modal.Title>
        </div>
        <IconButton
          style={{ backgroundColor: '#006ff4', color: 'white', width: '22px', height: '22px' }}
          onClick={handleClose}
        >
          <CloseIcon style={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Modal.Header>

      {!showTermsConditions
        ? <Modal.Body className="py-2">
          <label htmlFor="cardCode">9-Digit card code</label>
          <InputTypeText
            compulsoryValue
            placeholder="Enter 9-digit card code"
            onChange={handleInputChange}
            name="cardCode"
            id="cardCode"
            value={activationDetails.cardCode}
            errorMsg={errors.cardCode}
            onBlur={(event) => handleBlur(event.target.name, event.target.value, "Card code field cannot be empty")}
          />

          <label htmlFor="email">Email</label>
          <InputTypeText
            type="email"
            compulsoryValue
            placeholder="Email"
            onChange={handleInputChange}
            name="email"
            id="email"
            value={activationDetails.email}
            errorMsg={errors.email}
            onBlur={(event) => handleBlur(event.target.name, event.target.value, errorMsgs.emptyEmail)}
          />

          <label htmlFor="dob">Date of birth</label>
          <InputTypeText
            compulsoryValue
            placeholder="Date of birth (yyyy-mm-dd)"
            onChange={handleInputChange}
            name="dob"
            id="dob"
            value={activationDetails.dob}
            errorMsg={errors.dob}
            onBlur={(event) => handleBlur(event.target.name, event.target.value, "Please enter your date of birth")}
            inputContainerMargins="0"
          />
        </Modal.Body>
        : <Modal.Body className="py-2">
          <p>Please read the terms and conditions before proceeding:</p>
          <p className="mb-0 text-decoration-underline fw-bold fs-5">For residents of the UK</p>
          <p style={{ fontSize: "12px" }}>
            OneMoneyWay Corporate Mastercard® Terms and Conditions issued by B4B Payments. The following Terms & Conditions definitions apply to UK clients and cardholders.<br></br>

            <span className="text-decoration-underline fw-bold fs-6">Client Terms & Conditions</span><br></br>

            <span className="fw-bold termsCondsLink">
              <a href={uk_client_enterprise_link} target="_blank" rel="noopener noreferrer">- Enterprise Corporate Terms and Conditions</a>
            </span><br></br>
            <span className="fw-bold termsCondsLink">
              <a href={uk_client_small_business_link} target="_blank" rel="noopener noreferrer">- Small Business Corporate Terms</a>
            </span><br></br>

            <span className="text-decoration-underline fw-bold fs-6">Card User/Holder Terms:</span><br></br>
            <span className="fw-bold termsCondsLink">
              <a href={uk_cardholder_enterprise_link} target="_blank" rel="noopener noreferrer">- Enterprise Card User Terms</a>
            </span>: For users of enterprise expense and incentive products.<br></br>
            <span className="fw-bold termsCondsLink">
              <a href={uk_cardholder_small_business_link} target="_blank" rel="noopener noreferrer">- Small Business Card User Terms</a>
            </span>: For users of small business Expense and Incentive products.<br></br>
            <span className="fw-bold termsCondsLink">
              <a href={uk_cardholder_link} target="_blank" rel="noopener noreferrer">- Cardholder Terms</a>
            </span>: For recipients of funds using Payout and Payroll products.<br></br>
          </p>

          <p className="m-0 text-decoration-underline fw-bold fs-5">For residents of the EU</p>
          <p className="m-0" style={{ fontSize: "12px" }}>
            The following Terms & Conditions definitions apply to clients and cardholders located in Europe, outside of the UK.<br></br>

            <span className="text-decoration-underline fw-bold fs-6">Client Terms & Conditions</span><br></br>
            <span className="fw-bold termsCondsLink">
              <a href={eu_client_business_eng} target="_blank" rel="noopener noreferrer">
                - Europe Business Terms & Conditions
              </a>
              {/* <span className="fw-bold termsCondsLink">
                  [English]
              </span>
              {" "}|{" "}
              <span className="fw-bold termsCondsLink">
                <a href={eu_client_business_lith} target="_blank" rel="noopener noreferrer">[Lithuanian]</a>
              </span> */}
            </span><br></br>

            <span className="text-decoration-underline fw-bold fs-6">Card User/Holder Terms:</span><br></br>

            <span className="fw-bold termsCondsLink">
              <a href={eu_card_user_eng} target="_blank" rel="noopener noreferrer">
                - Europe Card User Terms & Conditions:{" "}
              </a>
              {/* <span className="fw-bold termsCondsLink">
                [English]
              </span>
              {" "}|{" "}
              <span className="fw-bold termsCondsLink">
                <a href={eu_card_user_lith} target="_blank" rel="noopener noreferrer">[Lithuanian]</a>
              </span> */}
            </span>
            For users of expense and incentive products located in the EU.<br></br>

            <span className="fw-bold termsCondsLink">
              <a href={eu_cardholder_eng} target="_blank" rel="noopener noreferrer">
                - Europe Cardholder Terms and Conditions:{" "}
              </a>
              {/* <span className="fw-bold termsCondsLink">
                [English]
              </span>
              {" "}|{" "}
              <span className="fw-bold termsCondsLink">
                <a href={eu_cardholder_lith} target="_blank" rel="noopener noreferrer">[Lithuanian]</a>
              </span> */}
            </span>
            For recipients of funds using Payout and Payroll products located in the EU.
          </p>

        </Modal.Body>
      }

      <Modal.Footer style={{ borderTop: "0px" }}>
        <div style={{ display: 'flex', width: '100%' }}>
          <Col className="col-6 d-flex justify-content-center">
            {!showTermsConditions
              ? <BasicButton
                type="button"
                text="Validate information"
                style={{ width: '95%' }}
                disabled={hasErrors || Object.values(activationDetails).some(value => value === '')}
                onClick={() => setShowTermsConditions(true)}
              />
              : <BasicButton
                type="button"
                text="I agree"
                isLoading={activateCardSelector.status === "loading"}
                style={{ width: '95%' }}
                onClick={handleSubmit}
              />
            }
          </Col>

          <Col className="col-6 d-flex justify-content-center">
            <BasicButton
              type="button"
              text="Close"
              onClick={handleClose}
              style={{
                width: '95%',
                backgroundColor: "white",
                color: "#006ff4",
                border: "1px solid #006ff4",
              }}
            />
          </Col>
        </div>
      </Modal.Footer>
    </Modal >
  )
};

export default ActivateCardModal;