import * as React from "react";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { MdKeyboardArrowDown } from "react-icons/md";

const DropDownInputSolid = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    width: "100%",
    color: "#FFFFFF",
    borderRadius: 10,
    backgroundColor: "#006FF4",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    fontFamily: "Montserrat, sans-serif !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      borderRadius: 10,
      //   borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      backgroundColor: "#006FF4",
    },
  },
}));

const DropDownInputWhite = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    width: "100%",
    color: "#231F20",
    borderRadius: 10,
    backgroundColor: "#FFFFFF",
    border: "2px solid #D7F0FF",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    fontFamily: "Montserrat, sans-serif !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      borderRadius: 10,
      //   borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      backgroundColor: "#FFFFFF",
    },
  },
}));

const DropDownArrowIconWhite = styled(MdKeyboardArrowDown)(({ theme }) => ({
  color: "#FFFFFF !important",
  marginRight: "10px",
  fontSize: "25px",
}));

const DropDownArrowIconSolid = styled(MdKeyboardArrowDown)(({ theme }) => ({
  color: "#231F20 !important",
  marginRight: "10px",
  fontSize: "25px",
}));

export {
  DropDownInputSolid,
  DropDownInputWhite,
  DropDownArrowIconWhite,
  DropDownArrowIconSolid,
};
