import React from "react";
import { IconButton } from "@mui/material";
import { Modal, Button, Spinner, Col } from "react-bootstrap";
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from "react-redux";
import cardSelectors from "../../../../redux/toolkit/cards/selector";
import BasicButton from "../../../../components/shared/Buttons/Basic";
import { useTranslation } from "react-i18next";

const UserConfirmationModal = (props: { showUserConfirmation: boolean, handlePassReauth: any, handleClose: any }) => {
  const { showUserConfirmation, handlePassReauth, handleClose } = props;

  const updateCardSelector = useSelector(cardSelectors.updateCardSelect);
  const { t } = useTranslation();

  return (
    <Modal
      show={showUserConfirmation}
      centered
      onHide={handleClose}
    >
      <Modal.Header style={{ display: 'flex', alignItems: 'center', borderBottom: 0 }}>
        <div style={{ flex: "1", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Modal.Title>{t("confirmationModal.title")}</Modal.Title>
        </div>
        <IconButton
          style={{ backgroundColor: '#006ff4', color: 'white', width: '22px', height: '22px' }}
          onClick={handleClose}
        >
          <CloseIcon style={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Modal.Header>

      <Modal.Body className="text-center pt-2 pb-2">
        <p className="mb-3">{t("confirmationModal.areYouSure")}</p>
        <div style={{ display: 'flex', width: '100%' }}>
          <Col className="col-6 d-flex justify-content-center">
            <BasicButton
              type="button"
              text={t("confirmationModal.yes")}
              onClick={() => handlePassReauth()}
              isLoading={updateCardSelector.status === "loading"}
              style={{ width: '95%' }}
            />
          </Col>

          <Col className="col-6 d-flex justify-content-center">
            <BasicButton
              type="button"
              variant="outlined"
              text={t("confirmationModal.no")}
              onClick={handleClose}
              style={{ width: '95%' }}
              disabled={updateCardSelector.status === "loading"}
            />
          </Col>
        </div>
      </Modal.Body>
    </Modal>
  )
};

export default UserConfirmationModal;