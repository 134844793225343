import { createSlice } from "@reduxjs/toolkit";
import { Logout } from "../auth/operation";
import { GetAllAccountsResponse } from "./interface";
import { getAllAccounts, getFilterdAccount } from "./operation";

const initialState = {
  data: {},
} as GetAllAccountsResponse;

const getAllAccountSlice = createSlice({
  name: "getallaccounts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllAccounts.fulfilled, (state: any, action) => {
      state.data = action.payload;
    });
    builder.addCase(getAllAccounts.rejected, (state: any, action) => {
      state.data = {};
    });
    builder.addCase(getFilterdAccount.fulfilled, (state: any, action) => {
      state.data = action.payload;
    });
    builder.addCase(Logout.fulfilled, (state: any) => {
      state.data = "";
    });
  },
});

export default getAllAccountSlice.reducer;
