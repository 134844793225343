import React, { useState } from "react";
import { Form, Modal, ModalFooter } from "react-bootstrap";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import BasicButton from "../../../../components/shared/Buttons/Basic";
import { useTranslation } from "react-i18next";

const ReportCardModal = (props: {
  showReportCardModal: boolean,
  setshowReportCardModal: any,
  selectedCardStatus: string,
  handleClose: any,
  setReauthAction: any,
  setShowUserConfirmation: any,
}) => {
  const { showReportCardModal, setshowReportCardModal, selectedCardStatus, handleClose, setReauthAction, setShowUserConfirmation } = props;

  const [selectedOption, setSelectedOption] = useState<string>(
    selectedCardStatus === "Lost" ?
      "Lost" :
      selectedCardStatus === "Stolen" ?
        "Stolen" :
        ""
  );
  const { t } = useTranslation();

  const handleRadioChange = (event: any) => {
    const value = event.target.value;
    setSelectedOption(value);
    setReauthAction(value === "Lost" ? "lost" : "stolen");
  };

  const handleReportClick = () => {
    setShowUserConfirmation(true);
    setshowReportCardModal(false);
  };

  return (
    <Modal show={showReportCardModal} onHide={handleClose} centered>
      <Modal.Header style={{ borderBottom: "0px" }}>
        <div>
          <Modal.Title>{t("reportCardModal.title")}</Modal.Title>
        </div>
        <IconButton
          style={{ backgroundColor: '#006ff4', color: 'white', width: '22px', height: '22px' }}
          onClick={handleClose}
        >
          <CloseIcon style={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Modal.Header>
      <Modal.Body className="pt-2 pb-2">
        <p style={{ marginBottom: 0, }}>
          {t("reportCardModal.chooseOption")}
          <span style={{ marginLeft: '5px', fontSize: "11px", fontWeight: "bolder" }}>
            ({t("reportCardModal.irreversibleAction")})
          </span>
        </p>
        <Form.Group style={{ marginTop: "15px" }}>
          <Form.Check
            type="radio"
            label= {t("reportCardModal.lost")}
            name="reportOptions"
            id="Lost"
            value="Lost"
            checked={selectedOption === "Lost"}
            onChange={handleRadioChange}
          />
          <Form.Check
            type="radio"
            label= {t("reportCardModal.stolen")}
            name="reportOptions"
            id="Stolen"
            value="Stolen"
            checked={selectedOption === "Stolen"}
            onChange={handleRadioChange}
          />
        </Form.Group>

      </Modal.Body>
      <ModalFooter style={{ borderTop: "0px" }}>
        <BasicButton
          type="button"
          onClick={handleReportClick}
          text= {t("reportCardModal.report")}
          disabled={selectedOption === ""}
        />
      </ModalFooter>
    </Modal>



  )
};

export default ReportCardModal;