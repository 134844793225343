// alertSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SweetAlertIcon = 'success' | 'error' | 'warning' | 'info' | 'question';

type AlertState = {
  show: boolean;
  type: 'success' | 'error' | 'warning' | 'info';
  title: string;
  text?: string;
  html?: string;
  icon?: SweetAlertIcon;
  imageUrl?: string;
  imageAlt?: string;
}

const initialState: AlertState = {
  show: false,
  type: 'info',
  title: '',
  text: '',
  html: '',
  icon: undefined,
  imageUrl: '',
  imageAlt: '',
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    showAlert: (state, action: PayloadAction<Omit<AlertState, 'show'>>) => {
      state.show = true;
      state.type = action.payload.type;
      state.title = action.payload.title;
      state.text = action.payload.text;
      state.html = action.payload.html;
      state.icon = action.payload.icon;
      state.imageUrl = action.payload.imageUrl;
      state.imageAlt = action.payload.imageAlt;
    },
    hideAlert: (state) => {
      state.show = false;
      state.type = 'info';
      state.title = '';
      state.text = '';
      state.html = '';
      state.icon = undefined;
      state.imageUrl = '';
      state.imageAlt = '';
    },
  },
});

export const { showAlert, hideAlert } = alertSlice.actions;
export default alertSlice;
