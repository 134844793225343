import React, { useEffect } from "react";
import { LOGO_URL } from '../../../constants/stringConstants'
import {OMWLogoWhite,OMWLogoBlack} from '../../../constants/Images'
import BankcardProps from "./BankCardProps";
import { MdOutlineMoreHoriz } from "react-icons/md";
import { BsTextLeft } from "react-icons/bs";
import "./index.css";
import { useTranslation } from "react-i18next";

const BankCard = (props: BankcardProps) => {

  const { t } = useTranslation();

  const handleChangeClick = () => {
    if (!props.inactive) {
      const bankCard: Element = document.getElementsByClassName("bank-card")[0];
      const bankCardChild: any = Array.from(bankCard.children);
      bankCardChild.forEach((element: any) => {
          // element.children[0].style.background = "white";
          element.children[0].children[0].classList.remove('cardSelected');
          element.children[0].children[0].children[0].children[0].src=OMWLogoBlack
        }
      );
  
      const index: any = props.index;
      // bankCardChild[index].children[0].style.background ="linear-gradient(248.17deg, #82C8ED 0%, #A2C4FA 100%)";
      bankCardChild[index].children[0].children[0].classList.add('cardSelected')
      bankCardChild[index].children[0].children[0].children[0].children[0].src=OMWLogoWhite

    }
    // bankCardChild[i].children[0].children[0].classList.add('cardSelected');

  };

  useEffect(() => {
    
      if (props.accId === localStorage.getItem("accountId")) {
        handleChangeClick();
      }
    
  }, []);
  return (
    
    <div 
      onClick={handleChangeClick}
      // className="col-xl-2 col-md-12 col-lg-6 col-sm-12 pb-2 pb-xl-2 px-lg-1"
      id={props.accId}
    >
      <div className={`${props?.className?props?.className:'bankAccountCard'}`} onClick={props.handleClick}>
        <div className="image-icons-bank">
          <img src={
            // ( props.accId === localStorage.getItem("accountId") && !props.inactive ) ? OMWLogoWhite: 
            OMWLogoBlack} 
            alt="avatar" 
            className="image-logo-card" />
          {/* <span className="card-currency">{props.currency}</span> */}
          <span className="cardCurrencyAndIban me-2">{props.currency}</span>
        </div>
        <div className="text-content-card-general">
          <span className="card-name-textt cardIban">{props.name}</span>
          {/* {!props.inactive ? <span className="iban-number-textt">IBAN No:</span> : null} */}
          {!props.inactive ? <span className="cardCurrencyAndIban mt-3">IBAN No:</span> : null}
          {!props.inactive ? <p className="iban-numbers cardIban">{props.ibanNo}</p> 
          : 
          <p className="msg-textt">{t("bankAccounts.inactive")}</p>}
          <div className="d-flex justify-content-between align-items-baseline">
          <MdOutlineMoreHoriz
            className="eye-Iconss"
            size={23}
            onClick={ !props.inactive ? props.handleEyeClick : undefined}
          />
          <BsTextLeft
            className="eye-Iconss"
            size={20}
            onClick={ !props.inactive ? props.handleViewAccClick:undefined}
          />
          </div>
         
          
        </div>
      </div>
    </div>
  );
};

export default BankCard;
