import { createSlice } from "@reduxjs/toolkit";
import { ExchangeRateResponse } from "./interface";
import { ExchangeRates } from "./operation";

const initialState = {
  data: {},
} as ExchangeRateResponse;

const exchangeRateSlice = createSlice({
  name: "exchangeRate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ExchangeRates.fulfilled, (state: any, action) => {
      state.data = action.payload;
    });
  },
});

export default exchangeRateSlice.reducer;
