import { createSlice } from "@reduxjs/toolkit";
import { Logout } from "../auth/operation";
import { AccountBalanceResponse } from "./interface";
import { getCardAccountBalance } from "./operation";

const initialState = {
  data: {},
} as AccountBalanceResponse;

const accountBalanceSlcie = createSlice({
  name: "accountBalance",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCardAccountBalance.fulfilled, (state: any, action) => {
      state.data = action.payload;
    });
    builder.addCase(Logout.fulfilled, (state: any) => {
      state.data = "";
    });
  },
});

export default accountBalanceSlcie.reducer;
