import React from "react";
import { useTranslation } from "react-i18next";

const ConversionDetails = (props: any) => {
  console.log("ConversionDetailsprops: ", props);

  const { t } = useTranslation();

  return (
    <div className="">

      <div className="row receipt__data">
        <div className="col-12 conversionDetailText">
          <h6>{t("convert.modal.text")}:</h6>
        </div>
        <div className="col-12 conversionDetailText">
          <p>{props?.convertingValue}</p>
        </div>

        <div className="col-12 conversionDetailText">
          <h6>{t("convert.form.to")}:</h6>
        </div>
        <div className="col-12 conversionDetailText">
          <p>{props?.convertingTo}</p>
        </div>

        <div className="col-12 conversionDetailText">
          <h6>{t("convert.modal.receivingAmount")}:</h6>
        </div>
        <div className="col-12 conversionDetailText">
          <p>{props?.recipientGet}</p>
        </div>

        <div className="col-12 conversionDetailText">
          <h6>{t("convert.modal.rate")}:</h6>
        </div>
        <div className="col-12 conversionDetailText">
          <p>{props?.exchangeRates}</p>
        </div>
      </div>
    </div>
  );
};
export default ConversionDetails;

