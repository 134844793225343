import React, { useState, useEffect } from "react";
import { Modal, ModalFooter } from "react-bootstrap";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment'
import { CreditedTransactionCodes, DebitedTransactionCodes } from "../../../../constants";
import { B4BCardNotificationType } from "../../../../redux/toolkit/cards/interface";
import BasicButton from "../../../../components/shared/Buttons/Basic";
import { useTranslation } from "react-i18next";

const headingsStyle = {
  fontSize: "14px", margin: 0, color: "#ADB6C0"
}

const primaryTextStyle = {
  marginTop: "5px", fontSize: "18px", marginBottom: "10px", fontWeight: "600"
}

const secondaryTextStyle = {
  marginTop: "5px", fontSize: "16px", marginBottom: "10px", fontWeight: "600"
}

const TransactionDetailModal = (props: {
  showTransactionDetail: boolean,
  setShowTransactionDetail: React.Dispatch<React.SetStateAction<boolean>>,
  selectedTransaction: B4BCardNotificationType | undefined
}) => {
  const { showTransactionDetail, setShowTransactionDetail, selectedTransaction } = props;
  const { t } = useTranslation();

  const handleClose = () => {
    setShowTransactionDetail(false);
  }

  return (
    <Modal
      show={showTransactionDetail}
      centered
      onHide={handleClose}
    >
      <Modal.Header style={{ display: 'flex', alignItems: 'center', borderBottom: 0 }}>
        <div>
          <Modal.Title>{t("transactionDetailsModal.title")} {selectedTransaction?.declined ? `(Declined)` : ""}</Modal.Title>
        </div>
        <IconButton
          style={{ backgroundColor: '#006ff4', color: 'white', width: '22px', height: '22px' }}
          onClick={handleClose}
        >
          <CloseIcon style={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Modal.Header>

      <Modal.Body className="pt-2 pb-2">
        {/* MERCHANT NAME */}
        <p style={headingsStyle}>{t("transactionDetailsModal.merchantName")}</p>
        <p style={primaryTextStyle}>
          {selectedTransaction?.merchant_name || selectedTransaction?.note || '-'}
        </p>

        {/* MERCHANT DESCRIPTION */}
        <p style={headingsStyle}>{t("transactionDetailsModal.merchantDescription")}</p>
        <p style={primaryTextStyle}>
          {selectedTransaction?.mcc_description || '-'}
        </p>

        {/* TRANSACTION AMOUNT AND CURRENCY */}
        {/* Check 1: In case a transaction is declined, we will not be showing its amount
        Check 2: In case an FX transaction took place, we will show the transaction amoung and the charged amount
        If it is a normal transaction, just display the settlement amount */}
        {selectedTransaction?.declined
          ? <></>
          : selectedTransaction?.fx_padding !== 0
            ? <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "50%" }}>
                <p style={headingsStyle}>{t("transactionDetailsModal.actualAmountCharged")}</p>
                <p style={secondaryTextStyle}
                  className={`${DebitedTransactionCodes.includes(selectedTransaction!.transaction_type)
                    ? "amountDeducted"
                    : CreditedTransactionCodes.includes(selectedTransaction!.transaction_type)
                      ? "amountAdded"
                      : ""}
                `}
                >
                  {(selectedTransaction!.settlement_amount
                    + selectedTransaction!.fx_padding
                    + selectedTransaction!.fees_rate_amount).toFixed(2)
                  }
                  {selectedTransaction!.settlement_currency}
                </p>
              </div>
              <div style={{ width: "50%" }}>
                <p style={headingsStyle}>{t("transactionDetailsModal.transactionAmount")}</p>
                <p style={secondaryTextStyle}
                >
                  {(selectedTransaction!.amount).toFixed(2)} {selectedTransaction!.currency}
                </p>
              </div>
            </div>
            : <>
              <p style={headingsStyle}>{t("threeDSAuthModal.amount")}</p>
              <p style={secondaryTextStyle}
                className={`${DebitedTransactionCodes.includes(selectedTransaction!.transaction_type)
                  ? "amountDeducted"
                  : CreditedTransactionCodes.includes(selectedTransaction!.transaction_type)
                    ? "amountAdded"
                    : ""}
            `}
              >
                {(selectedTransaction!.settlement_amount).toFixed(2)} {selectedTransaction!.settlement_currency}
              </p>
            </>
        }

        {/* TRANSACTION NOTE (IF ANY) */}
        {selectedTransaction?.note
          && <>
            <p style={headingsStyle}>{t("transactionDetailsModal.note")}</p>
            <p style={secondaryTextStyle}>
              {selectedTransaction.note}
            </p>
          </>
        }

        {/* TRANSACTION DATE AND TIME*/}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "50%" }}>
            <p style={headingsStyle}>{t("transactionDetailsModal.date")}</p>
            <p style={secondaryTextStyle}>
              {moment.utc(selectedTransaction!.transaction_timestamp).local().format("DD MMM, YYYY") || '-'}
            </p>
          </div>
          <div style={{ width: "50%" }}>
            <p style={headingsStyle}>{t("transactionDetailsModal.time")}</p>
            <p style={secondaryTextStyle}>
              {moment.utc(selectedTransaction!.transaction_timestamp).local().format("h:mm A") || '-'}
            </p>
          </div>
        </div>

        {/* <p style={headingsStyle}>Last 4 card digits</p>
        <p style={secondaryTextStyle}>{maskCardNumber(data.transaction.cardNumber)}</p> */}

      </Modal.Body>

      <ModalFooter style={{ borderTop: "0px" }}>
        <BasicButton
          type="button"
          onClick={handleClose}
          text={t("configureLimitModal.okay")}
        />
      </ModalFooter>
    </Modal>
  )
};

export default TransactionDetailModal;