import { BeneficiaryResponse } from "./interface";
import { createSlice } from "@reduxjs/toolkit";
import { AddBeneficiary, GetALlbeneficiaries, UpdateBeneficiary, deleteBeneficiaryAsync,GetALlbeneficiariesPaginated } from "./operation";

const initialState = {
  data: {},
} as BeneficiaryResponse;

const beneficiarySlice = createSlice({
  name: "beneficiary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(AddBeneficiary.fulfilled, (state: any, action) => {
      //  state.data = action.payload;
      state = initialState;
    });
    builder.addCase(GetALlbeneficiaries.fulfilled, (state: any, action) => {
      state.data = action.payload;
    });
    builder.addCase(GetALlbeneficiariesPaginated.fulfilled, (state: any, action) => {
      state.data = action.payload;
    });
    builder.addCase(deleteBeneficiaryAsync.fulfilled, (state:any, action) => {
      const itemId = action.payload;
      state.data.data.data= state?.data?.data?.data.filter((item:any) => item._id !== itemId);
    });
    builder.addCase(UpdateBeneficiary.fulfilled, (state:any, action) => {
      const itemId = action.payload;
      state.data.data.data= state?.data?.data?.data.map((item:any) =>
       item._id === action.payload.data.result._id?action.payload.data.result:item
       );
    });
  },
});

export default beneficiarySlice.reducer;
