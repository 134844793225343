import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiHelper } from "../../../api helper";
import { AccountBalancePayload, AccountBalanceResponse } from "./interface";

export const getCardAccountBalance = createAsyncThunk<
  AccountBalanceResponse,
  AccountBalancePayload
>("accountBalance/getAccountBalance", async (accId) => {
  const token = localStorage.getItem("token");
  const data = await apiHelper(
    `activeUser/getAccountDetails?accB4BUUID=${accId}`,
    "get",
    {},
    {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
  );
  return data;
});
