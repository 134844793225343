import React, { useEffect, useState,useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getTheRecentOeprations,getTheRecentOeprationsPaginated  } from "../../../redux/toolkit/recentOperation/operation";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { BsArrowRight } from "react-icons/bs";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Button, Col, Modal, Row } from "react-bootstrap";
import "./index.css";
import axios from "axios";
import saveAs from "file-saver";
import BASE_URL from "../../../utils/baseURL";
import { RecentOperationTemplate } from '../../../templates';
import html2pdf from "html2pdf.js/dist/html2pdf.js";
import ModalHeader from "../Modal/ModalHeader";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';  
import {TableFooter,TableBody, Table,TableHead} from '@mui/material';
import InputTypeText from "../Input/Basic/InputTypeText";
import _ from 'lodash' 
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { useScreenSize } from "../ResizeHook";


const RecentOperationsCard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const screenSize = useScreenSize();
  const [columnSize, setColumnSize] = useState(200);
  const recentOpSelector: any = useSelector((state: RootState) => state.recentOperation.data);
  const [recentOpSate, setRecentOp] = useState<[]>([]);
  const [recentOpFirrstFourRecord, setRecentOpFirrstFourRecord] = useState<[]>([]);
  const [showAll, setShowAll] = useState(false);
  const [wasShowAllOpen, setWasShowAllOpen] = useState(false);
  const [accountId, setAccountId] = useState<any>(localStorage.getItem('accountId'));
  const [loader, setLoader] = useState(false);
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState(false);
  const [transactionData, setTransactionData] = useState<any>("");
  const [searchText, setSearchText] = useState<any>("");
  const [transactionModal, setTransactionModal] = useState(false);
  const userEmail = localStorage.getItem("userEmail");
  const accountData: any = localStorage.getItem("AccountData");
  const parsedData: any = JSON.parse(accountData);
  const companyName: any = localStorage.getItem("companyName");
  const [iconSize, setIconSize] = useState<'small' | 'large'>('large');
  const seeAllRecentRef = useRef(null);
  const recentOpPayload ={
    userId: accountId,
    page:1,
    limit:5,
    search:''
  } 
 
  const updateColumnSize = () => {
    if (window.innerWidth <= 576) {
      setColumnSize(72);
    } else {
      setColumnSize(200);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      updateColumnSize();
    };

    updateColumnSize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // dispatch(getTheRecentOeprations(accountId));
    dispatch(getTheRecentOeprationsPaginated(recentOpPayload));
    setLoader(true);
  }, [dispatch, loader, accountId]);

  useEffect(() => {
    if (recentOpSelector?.data?.data) {
      // setRecentOp(recentOpSelector?.data);
      if (recentOpSelector?.data?.data?.length <= 5) setRecentOpFirrstFourRecord(recentOpSelector?.data?.data);
      else if (recentOpSelector?.data?.data?.length === 0)setRecentOpFirrstFourRecord([])
      setRecentOp(recentOpSelector?.data?.data);
      setLoader(false);
    }
  }, [recentOpSelector, recentOpSate, loader ]);
 
  

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 576) {
        setIconSize("small");
      } else {
        setIconSize("large");
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleDotClick = (data: any) => {
    setTransactionData(data);
    setTransactionModal(false);
  };
  const handleViewClick = (data: any) => {
    setTransactionData(data);
    if (showAll) {
      setWasShowAllOpen(true);
      setShowAll(false);
    }
    setTransactionModal(true);
  };

  useEffect(() => {
    document.addEventListener("CardValue", (event: any) => {
      const { detail } = event;
      setAccountId(detail.accountId);
    });
  }, []);
 

  const handleClose = () => {
    setShowAll(false);
  };
  const handleCloseTransaction = () => {
    setTransactionModal(false);
    if (wasShowAllOpen) {
      setShowAll(true);
      setWasShowAllOpen(false);
    }
  };

  const createAndDownloadPdf = async () => {
    const template = RecentOperationTemplate({
      payerName: transactionData?.payer_name && transactionData?.posting_key === "cr"
        ? transactionData?.payer_name
        : "Debited by you",
      beneficiaryName: transactionData?.posting_key === "cr" ? companyName :
        transactionData?.offsetting_transaction_entity ? transactionData?.offsetting_transaction_entity : "Self",
      amount: transactionData?.amount,
      currency: transactionData?.currency,
      description: transactionData?.description
        ? transactionData?.description
        : "None",
      reference: transactionData.posting_key === "cr" ?
        (transactionData.details ? transactionData.details : transactionData.reference ? transactionData.reference : transactionData.external_ref)
        : transactionData.reference ? transactionData.reference : transactionData.external_ref,
      details: transactionData.posting_key === "cr" ?
        (transactionData.details ? transactionData.details : transactionData?.reference) :
        transactionData?.reference, // else show payment reference replace both
      createdAt: transactionData?.created_at,
      transactionType: transactionData?.offsetting_transaction_type ? transactionData?.offsetting_transaction_type : "None",
      paymentScheme: transactionData?.payment_scheme ? transactionData?.payment_scheme : "None"

    })

    const opt = {
      filename: "receipt.pdf",
      margin: [0, 0, 0, 0],
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    const pdf = await html2pdf().set(opt).from(template).save();

    // axios
    //   .post(BASE_URL+"create-pdf", {
    //     payerName:
    //       transactionData?.payer_name && transactionData?.posting_key === "cr"
    //         ? transactionData?.payer_name
    //         : "Debited by you",
    //     beneficiaryName: transactionData?.offsetting_transaction_entity
    //       ? transactionData?.offsetting_transaction_entity
    //       : "Self",
    //     // reference: transactionData?.reference
    //     //   ? transactionData?.reference
    //     //   : "-",
    //     reference: transactionData.posting_key === "cr" ? (transactionData.details ? transactionData.details : transactionData.reference ? transactionData.reference : transactionData.external_ref) 
    //     : transactionData.reference ? transactionData.reference : transactionData.external_ref,
    //     amount: transactionData?.amount,
    //     currency: transactionData?.currency,
    //     description: transactionData?.description
    //       ? transactionData?.description
    //       : "None",
    //     details:transactionData.posting_key === "cr" ? (transactionData.details ? transactionData.details : transactionData?.reference) : transactionData?.reference, // else show payment reference replace both
    //     createdAt : transactionData?.created_at
    //   })
    //   .then(() => axios.get(BASE_URL+"fetch-pdf", { responseType: "blob" }))
    //   .then((res) => {
    //     const pdfBlob = new Blob([res.data], { type: "application/pdf" });

    //     saveAs(pdfBlob, "receipt.pdf");
    //   });
  };
  const recentOpRowClick = (ele: any) => {
    handleViewClick(ele)
  }
  const handlePageChange = async (event: React.ChangeEvent<unknown>, newPage: number) => {
    setIsLoadingSkeleton(true)
    await dispatch(getTheRecentOeprationsPaginated({...recentOpPayload, page:newPage,limit:10,search:searchText}));
    setIsLoadingSkeleton(false)
 };
 const seeAllHandlerRecentOp =async()=>{
   dispatch(getTheRecentOeprationsPaginated({...recentOpPayload, page:1,limit:10}));
  setShowAll(true)
 }
 const onSearch =async(text:string)=>{
  // setIsLoadingSkeleton(true)
  setSearchText(text);
  debouncedSave(text)

  // await dispatch(getTheRecentOeprationsPaginated({...recentOpPayload,page:1,limit:10,search:text}));
  // setIsLoadingSkeleton(false)
 }
 const debouncedSave = useCallback(
  _.debounce(
    (value: string) => {
      if (accountId) {
        dispatch(getTheRecentOeprationsPaginated({
          ...recentOpPayload,
          userId: accountId,
          page: 1,
          limit: 10,
          search: value.trim()
        }));
      }
    },
    600
  ),
  [accountId, dispatch] // Dependency array
);

 const hangleCloseRec =()=>{
  setSearchText('')
  setShowAll(false)
 }

  return (
    <div>
      <div className="titile-see-all-button">
        <div className="recent-op-text-div">
          <p className="recent-op-text">{t("recentOperation.title")}</p>
        </div>
        <div className="load-more-text-recent-div">
          <span
            className="load-more-text-recent"
            onClick={seeAllHandlerRecentOp}
          >
            {t("recentOperation.seeAll")}
            {/* <BsArrowRight size={12} /> */}
          </span>
        </div>
      </div>

      <div className="recent-op-rates-box">
        {loader ? (
          <Box sx={{ width: 300 }}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </Box>
        ) : (
          <table className="table table-recentOpt p-4" style={{border:'1px solid #FFFFFF'}}>
            <tbody>
              {/* {recentOpSate && recentOpSate.length > 0 ? (
                recentOpSate.slice(-4).reverse() */}
              {recentOpFirrstFourRecord && recentOpFirrstFourRecord.length > 0 ? (
                recentOpFirrstFourRecord.slice(-4).reverse()
                  .map((ele: any, index: number) => {
                    return (
                      <>
                        {/* <tr key={index}>
                            <td colSpan={2} className="amount-op-text newClass">
                              {ele.beneficiary_name}
                            </td>
                            <td className="negative-op-text-new">
                              {ele.payment_amount} {ele.payment_currency}
                            </td>
                            <td className="brandName-text">{ele.status}</td>
                            <td className="brandName-text">
                              {ele.payment_reason}
                            </td>
                            <td className="date-text">
                              {ele.createdAt.slice(0, 10)}
                            </td> */}
                        <tr style={{ cursor: 'pointer',verticalAlign:'middle' }} key={index} onClick={() => recentOpRowClick(ele)}>
                          <td colSpan={2} className="amount-op-text newClass">
                            {
                              ele.offsetting_transaction_entity ? ele.posting_key === 'cr' ? parsedData?.accountName : ele.offsetting_transaction_entity :
                                ele?.reference?.includes("Payment Reversal") ? "Reversal" : "Received"
                            }
                          </td>
                          <td className="negative-op-text-new">
                            {ele.payer_name && ele.posting_key === "cr"
                              ? ele.payer_name
                              : !ele.payer_name && ele.posting_key === "cr"
                                ? ele?.reference?.includes("Payment Reversal")
                                  ? "Payment Reversed"
                                  : "N/A"
                                : "Debited by you"}
                          </td>
                          <td
                            className="negative-op-text-new"
                            style={{
                              color: ele.posting_key === "cr" ? "#05d401" : "#f40f00", fontWeight:"600"
                            }}
                          >
                            {ele.posting_key === "cr" ? (
                              <ArrowDropUpIcon fontSize={iconSize} />
                            ) : (
                              <ArrowDropDownIcon  fontSize={iconSize}/>
                            )}
                            {ele.amount} {ele.currency}
                          </td>
                          <td
                            className="negative-op-text-new"
                          >
                            {ele.posting_key === "cr" ?
                              (ele.details ? ele.details : ele.reference ? ele.reference : ele.external_ref ? ele.external_ref : ele.description)
                              : (ele.reference ? ele.reference : ele.external_ref ? ele.external_ref : ele.description)}
                          </td>
                          {/* <td> */}
                            {/* {ele.posting_key === "cr" && !ele?.reference?.includes("Payment Reversal")
                              ? ele.reference : ele.external_ref
                              // : 
                            } */}
                            {/* {ele.reference ? ele.reference : ele.external_ref} */}
                          {/* </td> */}
                          {/* <td className="brandName-text">
                            {ele.description ? ele.description : "None"}
                          </td> */}
                          <td className="date-text">
                            {ele.created_at.slice(0, 10)}
                          </td>
                          <td>
                            <div className="dropdown text-end dropup m-0">
                              {/* <button className="btn btn-secondary" type="button">
                          Dropdown button
                        </button> */}
                              <BsThreeDotsVertical
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                className="three-dot-op dropdown-toggle"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleDotClick(ele)
                                }}
                                // size={20}
                                style={{color:"#006FF4"}}
                              />
                              <ul className="dropdown-menu">
                                <li>
                                  <Button
                                    className="dropdown-item"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleViewClick(ele)
                                    }}
                                  >
                                    {t("recentOperation.button.view")}
                                  </Button>
                                </li>
                                <React.Fragment>
                                  <hr />
                                  <li>
                                    <Button
                                      className="dropdown-item"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        createAndDownloadPdf();
                                      }}
                                    >
                                      {t("recentOperation.button.download")}
                                    </Button>
                                  </li>
                                </React.Fragment>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })
              ) : (
                <div className="no-data-container">
                  <span className="no-recent-op">
                    {t("recentOperation.noData")}
                  </span>
                </div>
                
              )}
                
            </tbody>
          </table>
        )}
      </div>
      <Modal
        // style={{ marginTop: 100, maxHeight: "75%" }}
        show={showAll}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        // width={"100%"}
        scrollable
        size="xl"
        className="allRecentOperationsTable"
        dialogClassName="my-modal"
      >
        <ModalHeader title={t("recentOperation.seeAllModal.title")} onClose={hangleCloseRec} modalBodyRef={seeAllRecentRef}/>
        <Modal.Body ref={seeAllRecentRef}>
          <div>
          <InputTypeText
                placeholder={"Search"}
                  value={searchText}
                  className="search-input-recent-op-modal"
                  onChange={(e) => { onSearch(e.target.value) }}
                  // defaultValue=""
                />
          <Table className="table p-4 table-recentOpt-modal">
              <TableBody>
                {recentOpSate && recentOpSate.length > 0 ? (
                  recentOpSate
                    .slice(0)
                    .reverse()
                    .map((ele: any, index: number) => {
                      return (
                        <>
                          {!isLoadingSkeleton && <tr style={{ cursor: 'pointer' }} key={index} onClick={() => recentOpRowClick(ele)}>
                            <td
                              colSpan={2}
                              className="amount-op-text newClass"
                              style={{
                                maxWidth: `${columnSize}px`,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                fontSize:'18px',
                                fontWeight:400,
                              }}
                            >
                              {
                                ele.offsetting_transaction_entity ? ele.posting_key === "cr" ? parsedData?.accountName : ele.offsetting_transaction_entity
                                  : ele?.reference?.includes("Payment Reversal") ? "Reversal"
                                    : "Received"
                              }

                            </td>


                            <td
                              className="negative-op-text-new"
                              style={{
                                textAlign: "center",
                                maxWidth: `${columnSize}px`,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                fontSize:'18px',
                                fontWeight:400,
                                color:'#1A1A1A',
                              }}
                            >
                              {ele.payer_name && ele.posting_key === "cr"
                                ? ele.payer_name
                                : !ele.payer_name && ele.posting_key === "cr"
                                  ? ele?.reference?.includes("Payment Reversal")
                                    ? "Payment Reversed"
                                    : "N/A"
                                  : "Debited by you"}
                            </td>


                            <td
                              className="negative-op-text-new"
                              style={{
                                color:
                                ele.posting_key === "cr" ? "#05d401" : "#f40f00",
                                maxWidth: `${columnSize}px`,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                fontWeight:600,
                                fontSize:'18px',
                              }}
                            >
                              {ele.posting_key === "cr" ? (
                                <ArrowDropUpIcon fontSize={iconSize} />
                              ) : (
                                <ArrowDropDownIcon fontSize={iconSize} />
                              )}
                              {ele.amount} {ele.currency}
                            </td>
                            <td
                            style={{
                              maxWidth: `${columnSize}px`,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              color:'#1A1A1A',
                              fontSize:"18px",
                              fontWeight:'400'
                            }}
                          >
                          {ele.posting_key === "cr" ? 
                          (ele.details ? ele.details : ele.reference ? ele.reference : ele.external_ref ? ele.external_ref : ele.description)
                              : (ele.reference ? ele.reference : ele.external_ref ? ele.external_ref : ele.description)}
                          </td>
                            

                            {/* <td
                              className="brandName-text"
                              style={{
                                maxWidth: 150,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {ele.description ? ele.description : ""}
                            </td> */}

                            <td
                              className="date-text"
                              style={{
                                maxWidth: `${columnSize}px`,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                fontWeight:'400',
                                fontSize:'18px',
                                color:"#191919"
                              }}
                            >
                              {ele.created_at.slice(0, 10)}
                            </td>
                            <td>
                              <div className="dropdown text-end dropup">
                                {/* <button className="btn btn-secondary" type="button">
                                      Dropdown button
                                    </button> */}

                                <BsThreeDotsVertical
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  className="three-dot-op dropdown-toggle"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleDotClick(ele)
                                  }}
                                  // size={18}
                                  style={{color:"#006FF4"}}
                                />
                                <ul className="dropdown-menu">
                                  <li>
                                    <Button
                                      className="dropdown-item"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        handleViewClick(ele)
                                      }}
                                    >
                                      {t(
                                        "recentOperation.seeAllModal.button.view"
                                      )}
                                    </Button>
                                  </li>
                                  <React.Fragment>
                                    <hr />
                                    <li>
                                      <Button
                                        className="dropdown-item"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          createAndDownloadPdf();
                                        }}
                                      >
                                        {t(
                                          "recentOperation.seeAllModal.button.download"
                                        )}
                                      </Button>
                                    </li>
                                  </React.Fragment>
                                </ul>
                              </div>
                            </td>
                          </tr>}
                          {isLoadingSkeleton && <tr>
                  <td colSpan={2} className="amount-op-text newClass">
                    <Box sx={{ width: "100%" }}>
                      <Skeleton animation="wave" />
                      <Skeleton animation={false} />
                    </Box>
                  </td>
                  <td className="negative-op-text-new">
                    <Box sx={{ width: "100%" }}>
                      <Skeleton animation="wave" />
                      <Skeleton animation={false} />
                    </Box>
                  </td>
                  <td className="negative-op-text-new">
                    <Box sx={{ width: "100%" }}>
                      <Skeleton animation="wave" />
                      <Skeleton animation={false} />
                    </Box>
                  </td>
                  <td className="negative-op-text-new">
                    <Box sx={{ width: "100%" }}>
                      <Skeleton animation="wave" />
                      <Skeleton animation={false} />
                    </Box>
                  </td>
                  <td>
                    <Box sx={{ width: "100%" }}>
                      <Skeleton animation="wave" />
                      <Skeleton animation={false} />
                    </Box>
                  </td>
                </tr>}
                        </>
                      );
                    })
                ) : (
                  <div className="no-data-container">
                    <span className="no-recent-op">
                      {t("recentOperation.noData")}
                    </span>
                  </div>
                )}
               
                
             </TableBody>
              <TableFooter style={{display:'table-caption', position: "sticky", bottom: "-15px" }}>
              <Pagination
        sx={{
          marginTop: "10px",
          width:'100%',
          '& ul': { justifyContent:'center' },
        }}
        color="primary"
        variant="outlined"
        shape="rounded"
        size={screenSize.width <= 500 || (screenSize.width < 1005 && screenSize.width > 760) ? "small" : "medium"}
        showFirstButton
        showLastButton
        count={Math.ceil(recentOpSelector?.data?.pagination?.pages * 10 / recentOpSelector?.data?.pagination?.pageSize)} // pagesize is rows per page
        page={recentOpSelector?.data?.pagination?.currentPage}
        onChange={handlePageChange}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            component="div"
            sx={{
              fontFamily: 'Montserrat',
              margin: "0px 1px !important",
              border: "1px solid rgba(0, 0, 0, 0.23) !important",
              borderRadius: "6px !important",
               padding: "0px 4px !important",
              '&:hover:not(:active)': { backgroundColor: '#006FF4', color: '#FFFFFF' },
              "&.Mui-selected": {
                backgroundColor: "#006FF4",
                color: "white",
                borderColor: "transparent",
                borderRadius: "6px"
              }
            }}
            slots={{
              previous: () => screenSize.width <= 768
                ? <GrFormPrevious width={"1em"} height={"1em"} fontSize={"1.15rem"} />
                : <p style={{ margin: 0 }}>{t("pagination.previous")}</p>,
              next: () => screenSize.width <= 768
                ? <GrFormNext width={"1em"} height={"1em"} fontSize={"1.15rem"} />
                : <p style={{ margin: 0 }}>{t("pagination.next")}</p>,
            }}
          />
        )}
      />
              </TableFooter>

            </Table>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        // style={{ marginTop: 100 }}
        show={transactionModal}
        onHide={handleCloseTransaction}
        backdrop="static"
        keyboard={false}
        className="transactionDetailsModal"

      >
        {/* <Modal.Header closeButton className="mt-4"> */}
          {/* <Modal.Title>
            {t("recentOperation.viewDetailModal.title")}
          </Modal.Title> */}
        {/* </Modal.Header> */}
        <ModalHeader onClose={handleCloseTransaction}/>
        <Modal.Body className="pt-3 pb-5 transactionDetailsModal-body">
          <div className="text-center">
            <p className="transactionDetailHeading">
            {t("recentOperation.viewDetailModal.title")}
            </p>
            <p className="transactionDetail-subHeading m-0">
            {t("recentOperation.viewDetailModal.amount")}
            </p>
            <p className="paymentAmountAmount">
            {transactionData?.amount} {transactionData?.currency}
            </p>
          </div>

            <Row>
              <Col xs={6} sm={6} md={6} lg={6}>
                {transactionData?.posting_key == "cr" ?
                  <p style={{fontWeight: 600}} className="text-start">{t("recentOperation.viewDetailModal.companyName")}:</p>
                  :
                  <p style={{fontWeight: 600}} className="text-start">{t("recentOperation.viewDetailModal.recipientName")}:</p>
                }
              </Col>
              <Col xs={6} sm={6} md={6} lg={6}>
                <p className="text-end">{
                  transactionData?.posting_key == "cr" ? companyName :
                    transactionData?.offsetting_transaction_entity ? transactionData?.offsetting_transaction_entity : "Self"
                }
                </p>
              </Col>
            </Row>
          {transactionData?.beneficiary_iban && (
            <Row>
               <Col xs={6} sm={6} md={6} lg={6}>
                  <p style={{ fontWeight: 600 }} className="text-start">IBAN:</p>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6}>
                <p className="text-end">
                  {transactionData?.beneficiary_iban}
                </p>
              </Col>
            </Row>
          )}

            <Row>
               <Col xs={6} sm={6} md={6} lg={6}>
                 <p style={{ fontWeight: 600 }} className="text-start">{t("recentOperation.viewDetailModal.transactionDate")}:</p>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6}>
                <p className="text-end">
                   {transactionData?.created_at?.slice(0, 10)}
                </p>
              </Col>
            </Row>


          {(transactionData?.posting_key == "cr" && transactionData?.payer_account) &&
            <Row>
              <Col>
                <p style={{fontWeight: 600}} className="text-start">{t("recentOperation.viewDetailModal.payerAccount")}</p>
              </Col>
              <Col>
                <p className="text-end">{transactionData?.payer_account}</p>
              </Col>
            </Row>
          }
          {(transactionData?.posting_key == "cr" && transactionData.payer_name) &&
            <Row>
              <Col>
                <p style={{fontWeight: 600}} className="text-start">{t("recentOperation.viewDetailModal.payer")}</p>
              </Col>
              <Col>
                <p className="text-end">{transactionData?.payer_name}</p>
              </Col>
            </Row>
          }
          

            <Row>
              <Col xs={6}  sm={6} md={6} lg={6}>
                <p style={{fontWeight: 600}} className="text-start">{t("recentOperation.viewDetailModal.paymentRef")}</p>
              </Col>

              <Col xs={6}  sm={6} md={6} lg={6}>
                  <p className="text-end">{
                  // transactionData.posting_key === "dr" ? 
                  //   (transactionData.details ? transactionData.details : transactionData.reference ? transactionData.reference : transactionData.external_ref) 
                  //   : transactionData.reference ? transactionData.reference : transactionData.external_ref
                  transactionData.details ? transactionData.details :
                  transactionData.reference ? transactionData.reference :
                    transactionData.external_ref
                    }</p>
              </Col>
            </Row>
            
            <Row>
              <Col xs={6}  sm={6} md={6} lg={6}>
                  <p style={{fontWeight: 600}} className="text-start">{t("recentOperation.viewDetailModal.currency")}:</p>
              </Col>
              <Col xs={6}  sm={6} md={6} lg={6}>
                    <p className="text-end">{transactionData?.currency}</p></Col>
            </Row>
            
           

          {transactionData?.description && <Row>
            <Col xs={6} sm={6} md={6} lg={6}>
              <p style={{fontWeight: 600}} className="text-start">{t("recentOperation.viewDetailModal.description")}:</p>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6}>
              <p className="text-end">{transactionData?.description
                ? transactionData?.description
                : "None"}</p></Col>
          </Row>}
          {transactionData?.offsetting_transaction_type && <Row>
            <Col xs={6} sm={6} md={6} lg={6}>
              <p style={{fontWeight: 600}} className="text-start">{t("recentOperation.viewDetailModal.transactionType")}:</p>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6}>
              <p className="text-end" style={{ textTransform: 'capitalize'}}>{transactionData?.offsetting_transaction_type}</p>
            </Col>
          </Row>}
          {transactionData?.payment_scheme  && <Row>
            <Col xs={6} sm={6} md={6} lg={6}>
              <p style={{fontWeight: 600}} className="text-start">{t("recentOperation.viewDetailModal.paymentScheme")}:</p>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6}>
              <p className="text-end" style={{ textTransform: 'capitalize'}}>{transactionData?.payment_scheme}</p>
            </Col>
          </Row>}



        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RecentOperationsCard;
