import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import SettingButtons from "./SettingButtons";

import { AiOutlineArrowLeft } from "react-icons/ai";

import FreezeCardIcon from "../../../assets/images/cardAssets/freezeCard.svg";
import UnfreezeCardIcon from "../../../assets/images/cardAssets/unfreezeCard.svg";
import ShowPinIcon from "../../../assets/images/cardAssets/showPin.svg";
import CardLabelIcon from "../../../assets/images/cardAssets/cardLabel.svg";
import CardStolenIcon from "../../../assets/images/cardAssets/reportCardStolen.svg";
import LostCardIcon from "../../../assets/images/cardAssets/reportCardLost.svg";
import InternationalPaymentIcon from "../../../assets/images/cardAssets/internationalPayments.svg";
import PaymentMethodsIcon from "../../../assets/images/cardAssets/managePaymentMethods.svg";
import CardLegalInformation from "../../../assets/images/cardAssets/limits.svg";
import CardLimitsIcon from "../../../assets/images/cardAssets/card-limit.svg"
import CardUnloadIcon from "../../../assets/images/cardAssets/unload-money.svg"

import ConfirmPasswordModal from "../CardSettingModals/ConfirmPasswordModal";
import InternationalPaymentsModal from "../CardSettingModals/InternationalPaymentsModal";
import ShowPinModal from "../CardSettingModals/ShowPinModal";
import ManagePaymentMethodsModal from "../CardSettingModals/ManagePaymentMethodsModal";
import LegalInformationModal from "../CardSettingModals/LegalInformationModal";
import ConfigureLimitsModal from "../CardSettingModals/ConfigureLimitsModal";
import ReportCardModal from "../CardSettingModals/ReportCardModal";
import UserConfirmationModal from "../CardSettingModals/ConfirmationModal";
import CardLabelModal from "../CardSettingModals/CardLabelModal";
import UnloadMoneyModal from "../CardSettingModals/UnloadMoneyModal";

import { useTranslation } from "react-i18next";
import { FormattedCard } from "../interface";
import PrepaidCard from "../PrepaidCard";

import { getAllCards, updatePrepaidCard } from "../../../redux/toolkit/cards/operation";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { CardStatusType, UpdateCardDataPayload } from "../../../redux/toolkit/cards/interface";

import Swal from "sweetalert2";
import { ErrorImage, SuccessImage, UpdateSuccessImage } from "../../../constants/Images";
import { showAlert } from "../../../redux/toolkit/swal/reducer";

const CardSettings = (props: { setShowCardSettings: any, selectedCardId: string, selectedCard: FormattedCard }) => {
  const { setShowCardSettings, selectedCardId, selectedCard } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [passwordReauth, setPasswordReauth] = useState<boolean>(false);
  const [showIntPayConfig, setShowIntPayConfig] = useState<boolean>(false);
  const [showMngPayMthdConfig, setShowMngPayMthdConfig] = useState<boolean>(false);
  const [showLegalInfo, setShowLegalInfo] = useState<boolean>(false);
  const [showLimitsConfig, setShowLimitsConfig] = useState<boolean>(false);
  const [showCardLabel, setShowCardLabel] = useState<boolean>(false);
  const [showUnloadMoney, setShowUnloadMoney] = useState<boolean>(false);

  // USE THIS STATE FOR USER CONFIRMATION (Yes or No).
  const [showUserConfirmation, setShowUserConfirmation] = useState<boolean>(false);
  // USE THIS STATE TO TRACK USER REAUTHENTICATION FOR SCA.
  const [showReportCardModal, setShowReportCardModal] = useState<boolean>(false);
  // action to perform after password reauthentication is done
  const [reauthAction, setReauthAction] = useState<"freeze" | "unfreeze" | "showPin" | "showPAN" | "lost" | "stolen" | "">("");

  // ARRAY FOR SETTINGS BUTTONS TO RENDER AND PERFORM ACTIONS
  const CardsSettingData = [
    {
      mainIcon: FreezeCardIcon,
      name: t("cardsSettings.freezeCard"),
      onClick: () => {
        setReauthAction("freeze")
        setShowConfirmPassword(true)
      },
      isDisabled: selectedCard.status === "Suspended"
        || selectedCard.status === "Lost"
        || selectedCard.status === "Stolen"
        || selectedCard.status === "Cancelled" ? "disabled" : "",
      tooltipMessage: t("cardsSettings.cardIsBlocked"),
    },
    {
      mainIcon: UnfreezeCardIcon,
      name: t("cardsSettings.unfreezeCard"),
      onClick: () => {
        setReauthAction("unfreeze")
        setShowConfirmPassword(true)
      },
      isDisabled: selectedCard.status === "Active"
        || selectedCard.status === "Lost"
        || selectedCard.status === "Stolen"
        || selectedCard.status === "Cancelled" ? "disabled" : "",
      tooltipMessage: t("cardsSettings.cardIsActive"),
    },
    {
      mainIcon: ShowPinIcon,
      name: t("cardsSettings.showPIN"),
      onClick: () => {
        setReauthAction("showPin")
        setShowConfirmPassword(true)
      },
      isDisabled: selectedCard.cardType === "Virtual Card" ? "disabled" : "",
      tooltipMessage: t("cardsSettings.notApplicableToVirtualCards"),
    },
    {
      mainIcon: CardLabelIcon,
      name: t("cardsSettings.cardLabel"),
      onClick: () => setShowCardLabel(true),
    },
    {
      mainIcon: CardStolenIcon,
      name: t("cardsSettings.reportCard"),
      onClick: () => setShowReportCardModal(true),
      // isDisabled: selectedCard.cardType === "Virtual Card" ? "disabled" : "",
      tooltipMessage: t("cardsSettings.notApplicableToVirtualCards"),
    },
    // {
    //   mainIcon: InternationalPaymentIcon,
    //   name: "International Payments",
    //   onClick: () => setShowIntPayConfig(true),
    // },
    // {
    //   mainIcon: PaymentMethodsIcon,
    //   name: "Manage Payment Methods",
    //   onClick: () => setShowMngPayMthdConfig(true),
    // },
    {
      mainIcon: CardLimitsIcon,
      name: t("cardsSettings.limits"),
      onClick: () => (setShowLimitsConfig(true)),
    },
    {
      mainIcon: CardLegalInformation,
      name: t("cardsSettings.legalInformation"),
      onClick: () => setShowLegalInfo(true),
    },
    {
      mainIcon: CardUnloadIcon,
      name: t("cardsSettings.unloadMoney"),
      onClick: () => setShowUnloadMoney(true),
    },
  ];

  // UNIVERSAL HANDLE CLOSE FOR ALL MODALS
  const handleClose = () => {
    setShowConfirmPassword(false);
    setShowIntPayConfig(false);
    setPasswordReauth(false);
    setShowMngPayMthdConfig(false);
    setShowLegalInfo(false);
    setShowLimitsConfig(false);
    setShowReportCardModal(false);
    setShowUserConfirmation(false);
    setShowCardLabel(false);
    setShowUnloadMoney(false);
  };

  // HELPER FUNCTION TO UPDATE CARD STATUS BASED ON PARAMETER PASSED
  const updateCardStatus = (statusToUpdate: CardStatusType) => {
    const statusPayload: UpdateCardDataPayload = {
      userId: localStorage.getItem("userId")!,
      //company UUID (B4B account UUID) for the cards
      // uuid: "bed202f2-5b7c-484c-af69-7b374c0c76d5",
      uuid: selectedCard.accB4BUUID || "",
      card_code: selectedCard.cardId,
      status: statusToUpdate,
    }
    dispatch(updatePrepaidCard(statusPayload))
      .unwrap()
      .then((resp) => {
        dispatch(getAllCards({ userId: localStorage.getItem('userId')! }))
        setShowUserConfirmation(false);
        // Swal.fire({
        //   icon: "success",
        //   title: "Success",
        //   confirmButtonColor: "#86B6EF",
        //   text: "Your card status has been updated!",
        // });
        dispatch(showAlert({
          type: 'success',
          title: `${t("sweetAlerts.success")}`,
          text: `${t("sweetAlerts.cardStatusUpdated")}`,
          imageUrl: SuccessImage,
          imageAlt: 'Success Image'
        }));
      })
      .catch((err) => {
        console.log(err);
        setShowUserConfirmation(false);
        // Swal.fire({
        //   icon: "error",
        //   title: "Error",
        //   confirmButtonColor: "#86B6EF",
        //   text: "Error while updating card, please try again!",
        // });
        dispatch(showAlert({
          type: 'error',
          title: `${t("sweetAlerts.error")}`,
          text: `${t("sweetAlerts.errorUpdatingCard")}`,
          imageUrl: ErrorImage,
          imageAlt: 'Error Image'
        }));
      })
  }

  // PERFORM SUBSEQUENT ACTION AFTER PASSWORD REAUTHENTICATION IS SUCCESSFUL
  const handlePassReauth = () => {
    switch (reauthAction) {
      case "freeze":
        // console.log("User wants to freeze the card.")
        return updateCardStatus("suspended");
      case "unfreeze":
        // console.log("User wants to UN-freeze the card.")
        return updateCardStatus("active");
      case "lost":
        // console.log("User wants to report card LOST.")
        return updateCardStatus("lost");
      case "stolen":
        // console.log("User wants to report card STOLEN.")
        return updateCardStatus("stolen");
      case "showPin":
        // console.log("User wants to view PIN.")
        return setPasswordReauth(true);
      default:
        return null;
    }
  };

  return (
    <>
      {/* CARD SETTINGS UI */}
      <div className="cardsSettingsDiv">
        <div className="row w-100 mb-3">
          <div
            className="col-sm-12 col-md-12 col-lg-8 col-xl-9"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div
              className="goBackIcon"
              onClick={() => setShowCardSettings(false)}
            >
              <AiOutlineArrowLeft />
            </div>
            <h2 className="generalInfo-heading m-0">
              {t('cardsSettings.title')}
            </h2>
          </div>
        </div>

        <div className="row w-100 mb-3">
          <PrepaidCard
            showStatus
            data={selectedCard}
            onClick={() => ({})}
          />
        </div>

        <div className="cardSettingsOptionsDiv container">
          <Row className="cardSettingBox pt-3 ps-3 pe-3 pe-xl-0 pb-1">
            {CardsSettingData?.map((item, index) => {
              return (
                <SettingButtons data={item} key={index} />
              );
            })}
          </Row>
        </div>
      </div>

      {/* COMFIRM YOUR PASSWORD MODAL */}
      {showConfirmPassword &&
        <ConfirmPasswordModal
          showConfirmPassword={showConfirmPassword}
          setShowConfirmPassword={setShowConfirmPassword}
          handleClose={handleClose}
          setShowUserConfirmation={setShowUserConfirmation}
          handlePassReauth={handlePassReauth}
          reauthAction={reauthAction}
        />}

      {/* CONFIGURE INTERNATIONAL PAYMENTS MODAL */}
      {showIntPayConfig &&
        <InternationalPaymentsModal
          showIntPayConfig={showIntPayConfig}
          selectedCardId={selectedCardId}
          selectedCardType={selectedCard.cardType}
          handleClose={handleClose}
        />}

      {/* CONFIGURE PAYMENT METHODS MODAL */}
      {showMngPayMthdConfig &&
        <ManagePaymentMethodsModal
          showMngPayMthdConfig={showMngPayMthdConfig}
          selectedCardId={selectedCardId}
          selectedCardType={selectedCard.cardType}
          handleClose={handleClose}
        />}

      {/* CONFIGURE LIMITS MODAL */}
      {showLimitsConfig &&
        <ConfigureLimitsModal
          showLimitsConfig={showLimitsConfig}
          cardCurrency={selectedCard.currency}
          handleClose={handleClose}
        />}

      {/* SHOW SELECTED CARD PIN MODAL */}
      {passwordReauth &&
        <ShowPinModal
          showPin={passwordReauth}
          selectedCardId={selectedCardId}
          selectedCardAccountId={selectedCard.accB4BUUID || ""}
          handleClose={handleClose}
        />}

      {/* SHOW LEGAL INFORMATION MODAL */}
      {showLegalInfo &&
        <LegalInformationModal
          showLegalInfo={showLegalInfo}
          handleClose={handleClose}
        />}

      {/* SHOW USER CONFIRMATION MODAL */}
      {showUserConfirmation &&
        <UserConfirmationModal
          showUserConfirmation={showUserConfirmation}
          handlePassReauth={handlePassReauth}
          handleClose={handleClose}
        />}

      {showReportCardModal &&
        <ReportCardModal
          showReportCardModal={showReportCardModal}
          setshowReportCardModal={setShowReportCardModal}
          selectedCardStatus={selectedCard.status}
          handleClose={handleClose}
          setReauthAction={setReauthAction}
          setShowUserConfirmation={setShowUserConfirmation}
        />}

      {showCardLabel &&
        <CardLabelModal
          showCardLabel={showCardLabel}
          selectedCard={selectedCard}
          selectedCardId={selectedCardId}
          handleClose={handleClose}
        />}

      {/* Unload Money modal */}
      {showUnloadMoney &&
        <UnloadMoneyModal
          showUnloadMoneyModal={showUnloadMoney}
          setShowUnloadMoney={setShowUnloadMoney}
          handleClose={handleClose}
          selectedCard={selectedCard}

        />
      }

    </>
  );
};

export default CardSettings;