import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import { Form, Modal, ModalBody, ModalFooter, Col, Spinner } from "react-bootstrap";
import CloseIcon from '@mui/icons-material/Close';
import AtmWithdrawalIcon from "../../../../assets/images/cardAssets/atmWithdrawal.svg";
import OnlinePaymentIcon from "../../../../assets/images/cardAssets/onlinePayment.svg";

import { UsageLimit } from "../../../../redux/toolkit/cards/interface";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { getCardDetails } from "../../../../redux/toolkit/cards/operation";
import cardSelectors from "../../../../redux/toolkit/cards/selector";
import Swal from "sweetalert2";
import BasicButton from "../../../../components/shared/Buttons/Basic";
import { useTranslation } from "react-i18next";

const ManagePaymentMethodsModal = (props: {
  showMngPayMthdConfig: boolean,
  selectedCardId: string,
  selectedCardType: string,
  handleClose: any
}) => {
  const { showMngPayMthdConfig, handleClose, selectedCardId, selectedCardType } = props;
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [switchValues, setSwitchValues] = useState({
    atmWithdrawals: false,
    onlineTransactions: false
  });
  // STATE TO HOLD EXISTING ATM LIMITS IF SET ANY BY USER.
  const [currLimits, setCurrLimits] = useState<UsageLimit[]>([
    {
      code: "24H",
      values: [{
        code: "ATM",
        singleAmount: 0
      }]
    }, {
      code: "WEEKLY",
      values: [{
        code: "ATM",
        singleAmount: 0
      }]
    }, {
      code: "MONTHLY",
      values: [{
        code: "ATM",
        singleAmount: 0
      }]
    }
  ]);

  const cardDetailsSelector = useSelector(cardSelectors.cardDetailsSelect);
  const updateCardSelector = useSelector(cardSelectors.updateCardSelect);

  // This useEffect sets the current applied blocks to show in UI
  // Gets specifically for the card that was selected
  useEffect(() => {
    dispatch(getCardDetails({ userId: localStorage.getItem('userId')!, cardId: selectedCardId }))
      .unwrap()
      .then((resp) => {
        // IN CASE sumAmount ALSO NEEDS TO BE 0, UNCOMMENT THE BELOW COMMENTED CODE SNIPPETS
        let atmSingleAmountsAreZero = false;
        const currAtmLimits: UsageLimit[] = [];
        // let atmSumAmountsAreZero = false;
        resp.data.usageLimits.forEach((limit) => {
          if (limit.values) {
            const atmValues = limit.values.filter(value => value.code === 'ATM');
            currAtmLimits.push({ code: limit.code, values: atmValues })
            atmSingleAmountsAreZero = atmValues.every(value => value.singleAmount === 0);
            // atmSumAmountsAreZero = atmValues.every(value => value.sumAmount === 0);
          }

          // if (!atmSingleAmountsAreZero || !atmSumAmountsAreZero) {
          if (!atmSingleAmountsAreZero) {
            return; // Exit loop if any ATM value is not zero
          }
        });
        setCurrLimits(currAtmLimits);
        setSwitchValues(() => {
          return {
            onlineTransactions: resp.data.regionAndEcommBlocking.ecomm,
            atmWithdrawals: atmSingleAmountsAreZero
          }
        });
      })
      .catch((err) => { console.log("Error while getting card details: ", err) })
  }, [])

  // BLOCK ATM WITHDRAWALS AND ONLINE TRANSACTIONS PAYLOAD
  const handleVariation1 = () => {
    return {
      usageLimits: [
        {
          code: "24H",
          values: [
            { code: "ATM", singleAmount: 0 }
          ]
        },
        {
          code: "WEEKLY",
          values: [
            { code: "ATM", singleAmount: 0 }
          ]
        },
        {
          code: "MONTHLY",
          values: [
            { code: "ATM", singleAmount: 0 }
          ]
        }
      ],
      regionAndEcommBlocking: {
        ecomm: true,
      },
    };
  };

  // BLOCK ATM WITHDRAWALS PAYLOAD
  const handleVariation2 = () => {
    return {
      usageLimits: [
        {
          code: "24H",
          values: [
            { code: "ATM", singleAmount: 0 }
          ]
        },
        {
          code: "WEEKLY",
          values: [
            { code: "ATM", singleAmount: 0 }
          ]
        },
        {
          code: "MONTHLY",
          values: [
            { code: "ATM", singleAmount: 0 }
          ]
        }
      ],
      regionAndEcommBlocking: {
        ecomm: switchValues.onlineTransactions,
      },
    };
  };

  // BLOCK ONLINE TRANSACTION PAYLOAD
  const handleVariation3 = () => {
    return {
      usageLimits: currLimits.map(limit => ({
        code: limit.code,
        values: limit!.values!.map(value => ({
          code: value.code,
          ...(value.singleAmount === 0 ? { reset: true } : { singleAmount: value.singleAmount })
        }))
      })),
      regionAndEcommBlocking: {
        ecomm: true,
      },
    };
  };

  // UNBLOCK ONLINE TRANSACTIONS AND ATM WITHDRAWALS
  const handleVariation4 = () => {
    return {
      usageLimits: currLimits.map(limit => ({
        code: limit.code,
        values: limit!.values!.map(value => ({
          code: value.code,
          ...(value.singleAmount === 0 ? { reset: true } : { singleAmount: value.singleAmount })
        }))
      })),
      regionAndEcommBlocking: {
        ecomm: false,
      },
    };
  };

  // BASED ON SWITCH VARIATION, PROVIDE THE PAYLOAD BODY
  const variations: Record<string, () => object> = {
    // atmWithdrawals,onlineTransactions
    'true,true': handleVariation1,
    'true,false': handleVariation2,
    'false,true': handleVariation3,
    'false,false': handleVariation4,
  };
  const handleSwitchChange = (value: any) => (event: any) => {
    setSwitchValues((prevValues) => ({
      ...prevValues,
      [value]: event.target.checked,
    }));
  };

  const handleSave = () => {
    const variationKey = `${switchValues.atmWithdrawals},${switchValues.onlineTransactions}`;
    const requestPayload = variations[variationKey]();
    const finalPayload = {
      userId: localStorage.getItem("userId")!,
      cardId: selectedCardId,
      ...requestPayload
    }
    // if (selectedCardType === "Physical Card") {
    //   dispatch(updatePrepaidCard(finalPayload))
    //     .unwrap()
    //     .then((resp) => {
    //       dispatch(getAllCards({ userId: localStorage.getItem('userId')! }))
    //       dispatch(getAllCardAccounts({ userId: localStorage.getItem('userId')! }))
    //       Swal.fire({
    //         icon: "success",
    //         title: "Success",
    //         confirmButtonColor: "#86B6EF",
    //         text: "Your card status has been updated!",
    //       }).then(() => { handleClose(); });
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       Swal.fire({
    //         icon: "error",
    //         title: "Error",
    //         confirmButtonColor: "#86B6EF",
    //         text: "Error while updating card, please try again!",
    //       }).then(() => { handleClose(); });
    //     })
    // } else {
    //   dispatch(updateVirtualCard(finalPayload))
    //     .unwrap()
    //     .then((resp) => {
    //       dispatch(getAllCards({ userId: localStorage.getItem('userId')! }))
    //       dispatch(getAllCardAccounts({ userId: localStorage.getItem('userId')! }))
    //       Swal.fire({
    //         icon: "success",
    //         title: "Success",
    //         confirmButtonColor: "#86B6EF",
    //         text: "Your card status has been updated!",
    //       }).then(() => { handleClose(); });
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       Swal.fire({
    //         icon: "error",
    //         title: "Error",
    //         confirmButtonColor: "#86B6EF",
    //         text: "Error while updating card, please try again!",
    //       }).then(() => { handleClose(); });
    //     })
    // }
  };

  return (
    <Modal
      show={showMngPayMthdConfig}
      centered
      onHide={handleClose}
    >
      <Modal.Header style={{ display: 'flex', alignItems: 'center', borderBottom: 0 }}>
        <div>
          <Modal.Title>{t("managePaymentMethodModal.title")}</Modal.Title>
        </div>
        <IconButton
          style={{ backgroundColor: '#006ff4', color: 'white', width: '22px', height: '22px' }}
          onClick={handleClose}
        >
          <CloseIcon style={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Modal.Header>

      <ModalBody className="pt-2 pb-2">
        <Form>
          {/* ATM Withdawals Switch */}
          <div style={{
            display: 'flex',
            alignItems: 'center'
          }}>
            < Col className="col-1" >
              <div className="mainBox-icon" style={{ height: "40px", width: "40px" }}>
                <img src={AtmWithdrawalIcon} />
              </div>
            </Col>
            <Col className="col-9">
              <p style={{ margin: 0, paddingLeft: "20px", fontWeight: "bold" }}>{t("managePaymentMethodModal.ATMWithdrawals")}</p>
            </Col>
            <Col className="col-2" style={{ display: "flex", justifyContent: "flex-end" }}>
              {cardDetailsSelector.status === "loading"
                ? <div style={{ display: "flex", justifyContent: "center", alignContent: "center", width: '100%' }}>
                  <Spinner animation={"border"} size="sm" />
                </div>
                : <Form.Check
                  type="switch"
                  id="atmWithdrawals-switch"
                  onChange={handleSwitchChange("atmWithdrawals")}
                  checked={switchValues.atmWithdrawals}
                />}
            </Col>
          </div>

          {/* Online Payments Switch */}
          <div style={{
            marginTop: "10px",
            display: 'flex',
            alignItems: 'center'
          }}>
            < Col className="col-1" >
              <div className="mainBox-icon" style={{ height: "40px", width: "40px" }}>
                <img src={OnlinePaymentIcon} />
              </div>
            </Col>
            <Col className="col-9">
              <p style={{ margin: 0, paddingLeft: "20px", fontWeight: "bold" }}>{t("managePaymentMethodModal.onlineTransactions")}</p>
            </Col>
            <Col className="col-2" style={{ display: "flex", justifyContent: "flex-end" }}>
              {cardDetailsSelector.status === "loading"
                ? <div style={{ display: "flex", justifyContent: "center", alignContent: "center", width: '100%' }}>
                  <Spinner animation={"border"} size="sm" />
                </div>
                : <Form.Check
                  type="switch"
                  id="onlineTransactions-switch"
                  onChange={handleSwitchChange("onlineTransactions")}
                  checked={switchValues.onlineTransactions}
                />}
            </Col>
          </div>
        </Form>
      </ModalBody >

      <ModalFooter style={{ borderTop: "0px" }}>
        <BasicButton
          type="submit"
          onClick={handleSave}
          text= {t("internationalPaymentModal.save")}
          isLoading={updateCardSelector.status === "loading"}
        />
      </ModalFooter>
    </Modal >
  )
};

export default ManagePaymentMethodsModal;