import '../../../index.css'
import {
  ProfileDetailsPayload,
  ProfileDetailsResponse,
  LoginDataPayload,
  LoginResponse,
  QrPayload,
  QrResponse,
  ForgotPassPayload,
  ForgotPassResponse,
  ResetPassPayload,
  ResetPassResponse,
  VerifyTokenPayload,
  VerifyTokenResponse,
  LogoutResponse,
  LogoutPayload,
  LastLoginResponse,
  OTPPayload,
  OTPResponse,
  VerifyOTPResponse,
  VerifyOTPPayload,
  ProfileUpdatePayload,
  ProfileUpdateResponse,
  UploadCompanyLogoResponse,
  UploadCompanyLogoPayload
} from "./interface";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiHelper } from "../../../api helper";
import Swal from "sweetalert2";
import { SuccessImage, EmailSentImage, ErrorImage, UpdateSuccessImage } from "../../../constants/Images";
import i18next from 'i18next';
import { showAlert } from '../swal/reducer';

const OTP_AUTH_USERNAME = 'otpUserService'
const OTP_AUTH_PASSWORD = 'OTp$3nd!ngS3rVicEPas5w0rD'

const ENCODED_OTP_AUTH_64 = btoa(`${OTP_AUTH_USERNAME}:${OTP_AUTH_PASSWORD}`);

const SuccessToast = Swal.mixin({
  toast: true,
  position: 'top-end',
  // iconColor: 'green',
  customClass: {
    popup: 'colored-toast',
  },
  showConfirmButton: false,
  timer: 1500,
})

export const UserRegistration = createAsyncThunk<
  ProfileDetailsResponse, ProfileDetailsPayload
>("profileDetails/getprofileDetails", async (payload) => {
  try {
    const response = await apiHelper(
      "user/register",
      "post",
      payload,
      {
        "Content-Type": "application/json",
      }
    );

    // Swal.fire({
    //   icon: "success",
    //   title: "Registered !!",
    //   text: "User has been Registered successfully",
    // });
    // Swal.fire({
    //   imageUrl: SuccessImage,
    //   title: "Registered !!",
    //   text: "User has been Registered successfully",
    //   // confirmButtonColor:'red',
    //   customClass: {
    //     confirmButton: 'modalBtnOK'
    //   }
    // });
    // dispatch(showAlert({
    //   type: 'success',
    //   title: i18next.t("sweetAlerts.registered"),
    //   text: i18next.t("sweetAlerts.userHasBeenRegistered")!,
    //   imageUrl: SuccessImage,
    //   imageAlt: 'Success Image'
    // }));
    if (response.data) {
      SuccessToast.fire({
        icon: 'success',
        title: 'User and company successfully registered!',
        iconColor: 'green'
      })
    }
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
});

export const UserUpdate = createAsyncThunk<
  ProfileUpdateResponse,
  ProfileUpdatePayload
>("updateProfileDetails/getupdateProfileDtails", async (payload, { dispatch }) => {

  const formData = new FormData();
  // formData.append('_method', 'put');
  formData.append('email', payload.email);
  formData.append('phoneNo', payload.phoneNo);
  formData.append('firstName', payload.firstName);
  formData.append('lastName', payload.lastName);
  formData.append('city', payload.city);
  formData.append('country', payload.country);
  formData.append('postalCode', payload.postalCode);
  formData.append('countryCode', payload.countryCode);
  formData.append('companyRegNumber', payload.companyRegNumber);
  formData.append('companyName', payload.companyName);
  if (payload.companyLogoBase64)
    formData.append('companyLogoBase64', payload.companyLogoBase64);
  // const dataBody = {
  //   email: payload.email,
  //   phoneNo: `${payload.phoneNo}`,
  //   firstName: payload.firstName,
  //   lastName: payload.lastName,
  //   city: payload.city,
  //   country: payload.country,
  //   postalCode: payload.postalCode,
  //   countryCode: payload.countryCode,
  //   companyRegNumber: payload.companyRegNumber,
  //   companyName: payload.companyName,
  //   companyLogoBase64: payload.companyLogoBase64
  // };
  try {
    // const data = await apiHelper(`user/update/${payload.userId}`, "put", formData, {
    const data = await apiHelper(`user/update/${payload.userId}`, "put", formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      Authorization: `Bearer ${payload.token}`,
    });
    // Swal.fire({
    //   imageUrl: UpdateSuccessImage,
    //   title: "Record Updated",
    //   text: "User has been Updated successfully",
    //   // confirmButtonColor:'red',
    //   customClass: {
    //     confirmButton: 'modalBtnOK'
    //   }
    // });
    dispatch(showAlert({
      type: 'success',
      title: i18next.t("sweetAlerts.recordUpdated"),
      text: i18next.t("sweetAlerts.userUpdated")!,
      imageUrl: UpdateSuccessImage,
      imageAlt: 'Success Image'
    }))

    return data;
  } catch (error: any) {

    if (error?.message?.includes("E11000 duplicate key") && error?.message?.includes("email_1 dup key")) {
      // Swal.fire({
      //   icon: "error",
      //   title: "Oopss",
      //   text: "Email address already in use",
      // });
      dispatch(showAlert({
        type: 'error',
        title: "Oopss",
        text: i18next.t("sweetAlerts.emailAlreadyExists")!,
        imageUrl: ErrorImage,
        imageAlt: 'Error Image'
      }))
    } else if (error?.cause == undefined) {
      // Swal.fire({
      //   icon: "error",
      //   title: "Oopss",
      //   text: "Company logo is too large",
      // });
      dispatch(showAlert({
        type: 'error',
        title: "Oopss",
        text: i18next.t("sweetAlerts.somethingWrongWhileSending")!,
        imageUrl: ErrorImage,
        imageAlt: 'Error Image'
      }))
    } else {
      // Swal.fire({
      //   icon: "error",
      //   title: "Oopss",
      //   text: error,
      // });
      dispatch(showAlert({
        type: 'error',
        title: "Oopss",
        text: error.message,
        imageUrl: ErrorImage,
        imageAlt: 'Error Image'
      }))

    }


    throw new Error();
  }
});
export const UserLogin = createAsyncThunk<LoginResponse, LoginDataPayload>(
  "loginDetails/getLoginDetails",
  async (payload, { dispatch }) => {
    const dataBody = {
      email: payload.email,
      phoneNo: payload.phoneNo,
      password: payload.password,
    };
    try {
      const data = await apiHelper("user/login", "post", dataBody);
      data.data.data.email = payload.email;
      // data.data.data.phoneNo = payload.phoneNo;
      console.log(data.data)
      return data.data;
    } catch (error: any) {
      // Swal.fire({
      //   // icon: "error",
      //   imageUrl: ErrorImage,
      //   title: "Oopss",
      //   // confirmButtonColor: "#86B6EF",
      //   text: error,
      //   customClass: {
      //     confirmButton: 'modalBtnOK'
      //   }
      // });
      dispatch(showAlert({
        type: 'error',
        title: "Oopss",
        text: error,
        imageUrl: ErrorImage,
        imageAlt: 'Error Image'
      }))
      throw new Error();
    }
  }
);

export const QrVerification = createAsyncThunk<QrResponse, QrPayload>(
  "qrDetails/getQrDetails",
  async (payload, { dispatch }) => {
    const dataBody = {
      email: payload.email,
      phoneNo: payload.phoneNo,
      token: payload.token,
      lastSync: new Date().toLocaleString('en-US', {
        hour12: true
      }),
    };
    let data: any = {};
    try {
      data = await apiHelper("user/verifyQr", "post", dataBody);
      localStorage.setItem("userMerchants", JSON.stringify(data.data.data.userMerchants));

      return data.data;
    } catch (error: any) {
      // Swal.fire({
      //   // icon: "error",
      //   imageUrl: ErrorImage,
      //   title: "Oopss",
      //   text: "2FA not verfied",
      //   // confirmButtonColor: "#86B6EF",
      //   customClass: {
      //     confirmButton: 'modalBtnOK'
      //   }
      // });
      dispatch(showAlert({
        type: 'error',
        title: "Oopss",
        text: i18next.t("sweetAlerts.2FANotVerified")!,
        imageUrl: ErrorImage,
        imageAlt: 'Error Image'
      }));
    }
    return data;
  }
);
export const OTPVerification = createAsyncThunk<VerifyOTPResponse, VerifyOTPPayload>(
  "verifyOTPDetails/getOtpVerifyDetails",
  async (payload, { dispatch }) => {
    const dataBody = {
      phoneNo: payload.phoneNo,
      otp: payload.otp,
    };
    let data: any = {};
    try {
      data = await apiHelper("user/verify-otp", "post", dataBody);

      return data.data;
    } catch (error: any) {
      console.log('OTPerror: ', error);
      // Swal.fire({
      //   // icon: "error",
      //   imageUrl: ErrorImage,
      //   title: "Oopss",
      //   text: error.message,
      //   // confirmButtonColor: "#86B6EF",
      //   customClass: {
      //     confirmButton: 'modalBtnOK'
      //   }
      // });
      dispatch(showAlert({
        type: 'error',
        title: "Oopss",
        text: error.message,
        imageUrl: ErrorImage,
        imageAlt: 'Error Image'
      }));
    }
    return data;
  }
);
export const ForgotPasswordOp = createAsyncThunk<
  ForgotPassResponse,
  ForgotPassPayload
>("forgotPassDetails/getForgotPassDetails", async (payload, { dispatch }) => {
  const dataBody = {
    email: payload.email,
  };
  try {
    const data = await apiHelper("user/forgetPassword", "post", dataBody);
    // Swal.fire({
    //   // icon: "success",
    //   imageUrl: EmailSentImage,
    //   title: "Email sent",
    //   text: "Your email has been sent successfully",
    //   // confirmButtonColor: "#86B6EF",
    //   customClass: {
    //     confirmButton: 'modalBtnOK'
    //   }
    // });
    dispatch(showAlert({
      type: 'success',
      title: i18next.t("sweetAlerts.emailSent"),
      text: i18next.t("sweetAlerts.emailSentSuccess")!,
      imageUrl: EmailSentImage,
      imageAlt: 'Success Image'
    }));

    return data.data;
  } catch (error: any) {
    // Swal.fire({
    //   // icon: "error",
    //   imageUrl: ErrorImage,
    //   title: "Oopss",
    //   // confirmButtonColor: "#86B6EF",
    //   text: error,
    //   customClass: {
    //     confirmButton: 'modalBtnOK'
    //   }
    // });
    dispatch(showAlert({
      type: 'error',
      title: "Oopss",
      text: error,
      imageUrl: ErrorImage,
      imageAlt: 'Error Image'
    }));

    throw new Error();
  }
});

export const ResetPasswordOp = createAsyncThunk<
  ResetPassResponse,
  ResetPassPayload
>("resetPassDetails/getResetPassDetails", async (payload, { dispatch }) => {
  const dataBody = {
    password: payload.password,
  };
  try {
    const data = await apiHelper(
      `user/resetPassword/${payload.token}`,
      "post",
      dataBody
    );
    // Swal.fire({
    //   // icon: "success",
    //   imageUrl: SuccessImage,
    //   title: "Password reset",
    //   // confirmButtonColor: "#86B6EF",
    //   text: "Your password has been updated successfully",
    //   customClass: {
    //     confirmButton: 'modalBtnOK'
    //   }
    // });
    dispatch(showAlert({
      type: 'success',
      title: i18next.t("sweetAlerts.passwordReset"),
      text: i18next.t("sweetAlerts.passwordResetSuccess")!,
      imageUrl: SuccessImage,
      imageAlt: 'Success Image'
    }));


    return data.data;
  } catch (error: any) {
    // Swal.fire({
    //   // icon: "error",
    //   imageUrl: ErrorImage,
    //   title: "Oopss",
    //   // confirmButtonColor: "#86B6EF",
    //   text: "Invalid token",
    //   customClass: {
    //     confirmButton: 'modalBtnOK'
    //   }
    // });
    dispatch(showAlert({
      type: 'error',
      title: "Oopss",
      text: i18next.t("sweetAlerts.invalidToken")!,
      imageUrl: ErrorImage,
      imageAlt: 'Error Image'
    }));
    throw new Error();
  }
});

export const VerifyToken = createAsyncThunk<
  VerifyTokenResponse,
  VerifyTokenPayload
>("VerifyTokenDetails/getVerifyTokenDetails", async (payload) => {
  const data = await apiHelper(`user/verify/${payload.token}`, "get");
  return data.data;
});

export const Logout = createAsyncThunk<LogoutResponse, LogoutPayload>(
  "LogoutDetails/getLogoutDetails",
  async (token) => {
    const data = await apiHelper(`user/logout/${token}`, "get");
    return data.data;
  }
);

export const GetLastLogin = createAsyncThunk<LastLoginResponse, LogoutPayload>(
  "lastLoginDetails/getLastLoginDetails",
  async (token) => {
    const data = await apiHelper(`user/getLastLogin`, "get", {}, {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    return data.data;
  }
);

export const SendOTP = createAsyncThunk<OTPResponse, OTPPayload>(
  "sendOTP/sendOTPDetails",
  async (payload, { dispatch }) => {
    const dataBody = {
      phoneNo: payload.phoneNo,
    };
    let data: any = {};
    try {
      data = await apiHelper(
        "user/send-otp",
        "post",
        dataBody,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          Authorization: `Bearer ${ENCODED_OTP_AUTH_64}`,
        }
      );
      console.log("OTP SEND", data)
      // Swal.fire({
      //   // icon: "success",
      //   imageUrl: SuccessImage,
      //   title: "One-Time Password",
      //   text: data.data.message,
      // });
      dispatch(showAlert({
        type: 'success',
        title: i18next.t("sweetAlerts.oneTimePassword"),
        text: data.data.message,
        imageUrl: SuccessImage,
        imageAlt: 'Success Image'
      }));
      return data.data;
    } catch (error: any) {
      // Swal.fire({
      //   // icon: "error",
      //   imageUrl: ErrorImage,
      //   title: "Oopss",
      //   text: error,
      //   // confirmButtonColor: "#86B6EF",
      //   customClass: {
      //     confirmButton: 'modalBtnOK'
      //   }
      // });
      dispatch(showAlert({
        type: 'error',
        title: "Oopss",
        text: error,
        imageUrl: ErrorImage,
        imageAlt: 'Error Image'
      }));
    }
    return data;
  }
);
export const SendOTPVoice = createAsyncThunk<OTPResponse, OTPPayload>(
  "sendOTP/sendOTPVoiceDetails",
  async (payload, { dispatch }) => {
    const dataBody = {
      phoneNo: payload.phoneNo,
    };
    let data: any = {};
    try {
      data = await apiHelper("user/send-otp-voice", "post", dataBody);
      console.log("OTP SEND", data)
      // Swal.fire({
      //   // icon: "success",
      //   imageUrl: SuccessImage,
      //   title: "One-Time Password",
      //   text: data.data.message,
      //   customClass: {
      //     confirmButton: 'modalBtnOK'
      //   }
      // });
      dispatch(showAlert({
        type: 'success',
        title: i18next.t("sweetAlerts.oneTimePassword"),
        text: data.data.message,
        imageUrl: SuccessImage,
        imageAlt: 'Success Image'
      }));

      return data.data;
    } catch (error: any) {
      // Swal.fire({
      //   // icon: "error",
      //   imageUrl: ErrorImage,
      //   title: "Oopss",
      //   text: error,
      //   // confirmButtonColor: "#86B6EF",
      //   customClass: {
      //     confirmButton: 'modalBtnOK'
      //   }
      // });
      dispatch(showAlert({
        type: 'error',
        title: "Oopss",
        text: error,
        imageUrl: ErrorImage,
        imageAlt: 'Error Image'
      }));
    }
    return data;
  }
);

export const UploadCompanyLogo = createAsyncThunk<
  UploadCompanyLogoResponse,
  UploadCompanyLogoPayload
>("user/uploadCompanyLogo", async (payload) => {
  try {
    const formData = new FormData();
    formData.append('companyID', payload.companyID);
    formData.append('companyLogoBase64', payload.companyLogoBase64);

    const response = await apiHelper("user/uploadCompanyLogo", "post", formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
});