import React from "react";
import { useTranslation } from "react-i18next";
// import NotificationIcon from "../../../assets/images/not-icone.png";
import NotificationIcon from "../../../assets/images/Notification Bell.png";
import "./index.css";

const NotificationCard = () => {
  const {t} = useTranslation()
  return (
    <div className="dropdown">
      <div className="notificatons-btn">
        <img className="not-icone" height={"35vh"} src={NotificationIcon} alt="not-icone"></img>
        <div className="not-text">{t("notification.title")}</div>
      </div>
      <div className="dropdown-content">
        <p className="not-drp-text">{t("notification.description")}</p>
      </div>
    </div>
  );
};
export default NotificationCard;
