import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InputComponent from "../../components/shared/Input/Index";
import { errorMsgs, LOGO_URL } from "../../constants/stringConstants";
import { RootState, AppDispatch } from "../../redux/store";
import SendIcon from '@mui/icons-material/Send';
import SmsIcon from '@mui/icons-material/Sms';
import EastIcon from '@mui/icons-material/East';
import CallIcon from '@mui/icons-material/Call';
import {
  OTPVerification,
  QrVerification,
  SendOTP,
  SendOTPVoice,
} from "../../redux/toolkit/auth/operation";
// import Accordion from "@mui/material/Accordion";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./index.css";
import OtpInput from "../../components/shared/OTP";
import LanguageDropdown from "../../components/shared/TranslationDropdown";
import { Button, Link } from "@mui/material";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { OMWLogoWhite, QRCode,TwoFactorAuthentication,OTPSMSVerification,OTPCallVerification } from "../../constants/Images";
import AccordianHeaderData from "../../components/shared/Accordian/AccordianSummary";
import Accordion from 'react-bootstrap/Accordion';
import InputTypeText from "../../components/shared/Input/Basic/InputTypeText";
import BasicButton from "../../components/shared/Buttons/Basic";
import IconButton from "../../components/shared/Buttons/IconButton";
import { PathStrConst } from "../../constants";

const Verfication = () => {
  const [token, setToken] = useState("");
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [showPhoneOTPInput, setShowPhoneOTPInput] = useState(false);

  const [tokenError, setTokenError] = useState("");
  const [btnSMSVisible, setBtnSMSVisible] = useState(false);
  const [btnVoiceVisible, setBtnVoiceVisible] = useState(false);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [loadingOTP, setLoadingOTP] = useState(false);
  const [loadingOTPVerify, setLoadingOTPVerify] = useState(false);

  const [loadingSmsOTPVerify, setLoadingSmsOTPVerify] = useState(false);
  const [loadingPhoneOTPVerify, setLoadingPhoneOTPVerify] = useState(false);
  const [loadingOTPVoice, setLoadingOTPVoice] = useState(false);
  const [otpErrorMsg,setOtpErrorMsg] = useState('');

  const [otpSmsErrorMsg, setOtpSmsErrorMsg] = useState("");
  const [otpPhoneErrorMsg, setOtpPhoneErrorMsg] = useState("");

  const [userPhone] = useState(location?.state?.data?.phoneNo);
  const [userEmail] = useState(location?.state?.data?.email);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const qrDetailss: any = useSelector((state: RootState) => state.qrDetails);
  const otpVerifyDetails: any = useSelector(
    (state: RootState) => state.otpVerifyDetails
  );
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [otpBtnText, setOtpBtnText] = useState("Send");
  const [callotpBtnText, setCallOtpBtnText] = useState("call");
  const [otp, setOtp] = useState("");
  const [phoneOtp, setPhoneOtp] = useState("");
  const onChange = (value: string) => setOtp(value);
  const onChangePhone = (value: string) => setPhoneOtp(value);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  useEffect(() => {
    if(location.state ===null || location.state===undefined){
      navigate(PathStrConst.signin)
    }

    if (qrDetailss && Object.keys(qrDetailss.data).length !== 0) {

      const token = qrDetailss.data.data.accessToken;
      localStorage.setItem("token", token);
      const userId = qrDetailss.data?.data.user?._id;
      localStorage.setItem("userId", userId);
      const firstName = qrDetailss.data?.data.user.firstName;
      localStorage.setItem("firstName", firstName);
      const lastName = qrDetailss.data?.data.user.lastName;
      localStorage.setItem("lastName", lastName);
      const postalCode = qrDetailss.data?.data.user.postalCode;
      localStorage.setItem("postalCode", postalCode);
      const city = qrDetailss.data?.data.user.city;
      localStorage.setItem("city", city);
      const country = qrDetailss.data?.data.user.country;
      localStorage.setItem("country", country);
      const companyName = qrDetailss.data.data.companyName;
      localStorage.setItem("companyName", companyName);
      const companyRegNumber = qrDetailss.data?.data.user.companyRegNumber;
      localStorage.setItem("companyRegNumber", companyRegNumber);
      const companyLogo =  qrDetailss.data.data.companyLogoBase64
      localStorage.setItem("companyLogo", companyLogo);
      // Adding Roles to localStorage
      const roles = qrDetailss?.data?.data?.user?.roles;
      const rolesSlug: any = [];
      if(roles) {
        roles.forEach((el: any) => {
          rolesSlug.push(el?.slug)
        })
      }
      localStorage.setItem("roles", JSON.stringify(rolesSlug));
      const countryCode =  qrDetailss.data?.data?.user?.countryCode
      localStorage.setItem("countryCode", countryCode);

      if (localStorage.getItem("token")) {
        navigate("/home", { state: qrDetailss.data });
      }
    } else if (
      otpVerifyDetails &&
      Object.keys(otpVerifyDetails.data).length !== 0
    ) {
      const token = otpVerifyDetails.data.data.accessToken;
      localStorage.setItem("token", token);
      const userId = otpVerifyDetails.data?.data.user?._id;
      localStorage.setItem("userId", userId);
      const firstName = otpVerifyDetails.data?.data.user.firstName;
      localStorage.setItem("firstName", firstName);
      const lastName = otpVerifyDetails.data?.data.user.lastName;
      localStorage.setItem("lastName", lastName);
      const postalCode = otpVerifyDetails.data?.data.user.postalCode;
      localStorage.setItem("postalCode", postalCode);
      const city = otpVerifyDetails.data?.data.user.city;
      localStorage.setItem("city", city);
      const country = otpVerifyDetails.data?.data.user.country;
      localStorage.setItem("country", country);
      const companyName = otpVerifyDetails.data.data.companyName;
      localStorage.setItem("companyName", companyName);
      const companyRegNumber = otpVerifyDetails.data?.data.user.companyRegNumber;
      localStorage.setItem("companyRegNumber", companyRegNumber);
      // Adding Roles to localStorage
      const roles = qrDetailss?.data?.data?.user?.roles;
      const rolesSlug: any = [];
      if(roles) {
        roles.forEach((el: any) => {
          rolesSlug.push(el?.slug)
        })
      }
      localStorage.setItem("roles", rolesSlug);
      const countryCode =  otpVerifyDetails.data?.data.user.countryCode
      localStorage.setItem("countryCode", countryCode);
      if (localStorage.getItem("token")) {
        navigate("/home", { state: otpVerifyDetails.data });
      }
    }

    // setShowOTPInput(false);
  }, [otpVerifyDetails, qrDetailss]);
  const onChangeToken = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    setTokenError("");
    setToken(item);
  };

  const validate = () => {
    if (token === "") setTokenError(errorMsgs.emptyIdentity);
    return token === "" || tokenError ? false : true;
  };
  const dispatch = useDispatch<AppDispatch>();
  const handleButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      const payload = {
        phoneNo: userPhone,
        email: userEmail,
        token: token,
      };
      dispatch(QrVerification(payload)).then(() => setLoading(false));
    }
  };
  const handleSendOTPButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    setBtnVoiceVisible(true);
    setLoadingOTP(true);
    const payload = {
      phoneNo: userPhone,
    };
    dispatch(SendOTP(payload)).then(() => {
      setBtnVoiceVisible(false);
      setLoadingOTP(false);
      setShowOTPInput(true);
      setOtpBtnText("Resend");
    });
  };
  const handleSendOTPVoiceButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    setBtnSMSVisible(true);
    setLoadingOTPVoice(true);
    const payload = {
      phoneNo: userPhone,
    };
    dispatch(SendOTPVoice(payload)).then(() => {
      setBtnSMSVisible(false);
      setLoadingOTPVoice(false);
      setShowPhoneOTPInput(true);
      setCallOtpBtnText("callagain");
    });
  };
  const handleVerifyOTPButtonClick = (otp = "") => {
    if(otp.length < 6){
      setOtpErrorMsg('Enter all otp digits')
    }
    else{
    setLoadingOTPVerify(true);

      const payload = {
        phoneNo: userPhone,
        otp,
      };
      dispatch(OTPVerification(payload)).then(() => setLoadingOTPVerify(false));
    }
  };


  const handleVerifySmsOTPButtonClick = (otp = "") => {
    if(otp.length < 6){
      setOtpSmsErrorMsg('Enter all otp digits')
    }
    else{
    setLoadingSmsOTPVerify(true);

      const payload = {
        phoneNo: userPhone,
        otp,
      };
      dispatch(OTPVerification(payload)).then(() => setLoadingSmsOTPVerify(false));
    }
  };


  const handleVerifyPhoneOTPButtonClick = (otp = "") => {
    if(otp.length < 6){
      setOtpPhoneErrorMsg('Enter all otp digits')
    }
    else{
    setLoadingPhoneOTPVerify(true);

      const payload = {
        phoneNo: userPhone,
        otp,
      };
      dispatch(OTPVerification(payload)).then(() => setLoadingPhoneOTPVerify(false));
    }
  };



  const verifyOTPLengthOnBlur = (e:any) => {
    if(e.length < 6) {
      setOtpErrorMsg('Enter all otp digits')
    }
    else{
      setOtpErrorMsg('')
    }
    console.log('BlurOTP-E',e.target.value)
  }

  const verifyOTPSmsLengthOnBlur = (e:any) => {
    if(e.length < 6) 
      setOtpSmsErrorMsg('Enter all otp digits')
    else
      setOtpSmsErrorMsg('')
  }

  const verifyOTPPhoneLengthOnBlur = (e:any) => {
    if(e.length < 6) 
      setOtpPhoneErrorMsg('Enter all otp digits')
    else
      setOtpPhoneErrorMsg('')
  }




  return (
    <div className="Verification">
      <div className="onBoardingImage onBoardingBGImg" style={{margin: "5px 0 7px 5px"}}>
        <img className="omwLogo" src={OMWLogoWhite} />
        {/* <div className="logo-box">
          <div className="logo-wrapper">
            <img
              className="logo"
              style={{ width: 210, height: 'auto',filter:"drop-shadow(0 0 0.4px)"}}
              src={LOGO_URL}
              alt="One Money Way"
            ></img>
          </div>
        </div> */}
        {/* <div className="left-title-box">
          <h2 className="left-title">{t("login.title.payment")} </h2>
          <h2 className="left-title-second">{t("login.title.platform")}</h2>
          <h2 className="left-title-third">{t("login.title.SMEs")}</h2>
        </div>
        <div className="welcome-box">
          <p className="welcome">{t("login.title.welcome")}</p>
          <p>{t("login.welcomeDesc")}</p>
        </div>
        <div className="circle-box">
          <div className="blue-circle"></div>
          <div className="white-circle"></div>
        </div> */}
      </div>
      <div className="onBoardingForm verificationForm d-flex flex-column justify-content-start justify-content-sm-center">
      <div className="top-banner">
        <img src={OMWLogoWhite}/>
      </div>
        <div className="lng-dropdown">
          <LanguageDropdown />
        </div>

        <div className="row w-100 mb-4">
          <div
            className="col-sm-12 col-md-12 col-lg-8 col-xl-9"
            style={{ display: "flex", alignItems: "center" }}
          >
            {/* <div
              className="goBackIcon"
              onClick={() => navigate('/')}
            >
              <AiOutlineArrowLeft />
            </div> */}
            {/* <h2 className="generalInfo-heading m-0 mb-8">
              {t('verify.title.back')}
            </h2> */}
          </div>
        </div>
        {/* <div className="point-box">
          <p className="left-point"></p>
          <p className="middle-point"></p>
          <p className="right-point"></p>
        </div> */}
        {/* <h2 className="ver-right-title">{t("verify.title.heading")}</h2> */}
        <p className="onBoarding-title">{t("verify.title.heading")}</p>
        <p className="onBoarding-text TwofA_AuthText">{t("login.enterDesc")}</p>
        {location?.state?.data?.qr && <Accordion
          // expanded={expanded === "panel3"}
          //  defaultActiveKey="0"
          // onChange={handleChange("panel3")}
          className="verifiactionAccordion"
        >
          <Accordion.Item eventKey="0">
            <AccordianHeaderData
              img={QRCode}
              accordianTitle="Scan QR Code"
              accordianDes="Verify by scanning this QR code"
            />

            <Accordion.Body style={{textAlign:'center', padding:'0px'}}>
              {location?.state?.data?.qr ? (
                <img src={location?.state?.data?.qr} />
              ) : (
                " QR code is not available"
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
}  

        <Accordion
         style={{ marginTop: "10px" }}
         className="verifiactionAccordion"
         >
          <Accordion.Item eventKey="1">
          <AccordianHeaderData
              img={TwoFactorAuthentication}
              accordianTitle={t("verifyNew.2faTitle")}
              accordianDes={t("verifyNew.2faDescription")}
            />
            <Accordion.Body>
            <form className="verification-form">
          <p className="inputLabel">{t("verifyNew.enterCode")}</p>

                <InputTypeText
                  className="inputText"
                  // placeholder="Enter code"
                  autoFocus={true}
                  value={token}
                  onChange={onChangeToken}
                  errorMsg={tokenError && `${t("errorMsgs.emptyIdentity")}`}
                />
                {/* <Button
                  style={{ marginTop: 20 }}
                  disabled={loading ? true : false}
                  variant="contained"
                  type="submit"
                  className="send-otp-btn"
                  onClick={handleButtonClick}
                  endIcon={<EastIcon />}
                >
                  {loading ? (
                    <Spinner animation={"border"} />
                  ) : (
                    `${t("verify.verify")}`
                  )}
                </Button> */}
                <div className="col-12">
                <BasicButton  type="submit" text={t("verify.verify")} isLoading={loading ? true : false} disabled={loading ? true : false} onClick={handleButtonClick}/>
                </div>

                {/* <button className="send-btn" onClick={handleButtonClick}>
                {loading ? (
                  <Spinner animation={"border"} />
                ) : (
                  `${t("verify.verify")}`
                )}
              </button> */}
              </form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>


        <Accordion
         style={{ marginTop: "10px" }}
         className="verifiactionAccordion"
         >
          <Accordion.Item eventKey="1">
          <AccordianHeaderData
              img={OTPSMSVerification}
              accordianTitle={t("verifyNew.otpSmsTitle")}
              accordianDes={t("verifyNew.otpSmsDescription")}
            />
            <Accordion.Body>
            <div className="mr-xl-1">
              {/* <Button
                hidden={btnSMSVisible}
                disabled={loadingOTP ? true : false}
                className="send-otp-btn"
                variant="contained"
                onClick={handleSendOTPButtonClick}
                endIcon={<SmsIcon />}>
                {loadingOTP ? (
                  <>
                    {" "}
                    Sending.. <Spinner animation={"border"} />
                  </>
                ) : (
                  // `${otpBtnText} SMS OTP to ${userPhone}`
                  `${otpBtnText} ${userPhone}`
                )}
              </Button> */}
              <IconButton 
              text={`${otpBtnText} ${userPhone || ''}`} 
              suffixIcon={<SmsIcon />}
              disabled={loadingOTP ? true : false}
              onClick={handleSendOTPButtonClick}
              isLoading={loadingOTP ? true : false}
              />
              {/* <button
                hidden={btnSMSVisible}
                disabled={loadingOTP ? true : false}
                className="send-otp-btn"
                onClick={handleSendOTPButtonClick}
              >
                {loadingOTP ? (
                  <>
                    {" "}
                    Sending.. <Spinner animation={"border"} />
                  </>
                ) : (
                  // `${otpBtnText} SMS OTP to ${userPhone}`
                  `${otpBtnText}`
                )}
              </button> */}
            </div>

            {showOTPInput ? (
              <>
                <div
                  style={{ alignContent: "center", width: "100%", padding: 10 }}
                >
                  <OtpInput value={otp} valueLength={6} onChange={onChange} onBlur={verifyOTPSmsLengthOnBlur}/>
                  <p style={{color:'red'}}>{otpSmsErrorMsg}</p>
                  {/* <Button
                    style={{ marginTop: 20, width:"100%" }}
                    disabled={loadingOTPVerify ? true : false}
                    variant='contained'
                    className="send-otp-btn"
                    onClick={handleVerifyOTPButtonClick}
                    endIcon={<EastIcon/>}
                  >
                    {loadingOTPVerify ? (
                      <>
                        Verifying
                        <Spinner animation={"border"} />
                      </>
                    ) : (
                      "Verify"
                    )}
                  </Button> */}
                  <BasicButton
                   text={t("verify.verify")}
                  disabled={loadingSmsOTPVerify ? true : false}
                  // onClick={() => handleVerifyOTPButtonClick(otp)}
                  onClick={() => handleVerifySmsOTPButtonClick(otp)}
                  // suffixIcon={<EastIcon/>}
                  isLoading={loadingSmsOTPVerify? true : false}
                   />
                  {/* <button
              hidden={btnVoiceVisible}
              disabled={loadingOTPVoice ? true : false}
              className="send-otp-btn"
              onClick={handleSendOTPVoiceButtonClick}
            >
              {loadingOTPVoice ? (
                <>
                  {" "}
                  Sending.. <Spinner animation={"border"} />{" "}
                </>
              ) : (
                `${otpBtnText} Voice Call OTP to ${userPhone}`
              )}
            </button> */}
                </div>
                {/* <div onClick={handleSendOTPVoiceButtonClick} style={{ fontSize: 13, textAlign:'center' }}> Having trouble Receiving SMS, {loadingOTPVoice ?
                  <Spinner style={{width:15,height:15}} animation={"border"} /> : <CallIcon color='primary' style={{ cursor: 'pointer' }} />} call me instead.</div> */}
              </>
            ) : (
              <></>
            )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>


        <Accordion
         style={{ marginTop: "10px" }}
         className="verifiactionAccordion"
         >
          <Accordion.Item eventKey="1">
          <AccordianHeaderData
              img={OTPCallVerification}
              accordianTitle={t("verifyNew.otpCallTitle")}
              accordianDes={t("verifyNew.otpCallDescription")}
            />
            <Accordion.Body>
            {/* <form className="verification-form"> */}

            <div className="mr-xl-1">
              <IconButton 
              text={`${t(`verify.${callotpBtnText}`)} ${userPhone || ''}`} 
              suffixIcon={<CallIcon />}
              disabled={loadingOTPVoice ? true : false}
              onClick={handleSendOTPVoiceButtonClick}
              isLoading={loadingOTPVoice ? true : false}
              />
            </div>

            {showPhoneOTPInput ? (
              <>
                <div
                    style={{ alignContent: "center", width: "100%", padding: 10 }}
                  >
                    <OtpInput value={phoneOtp} valueLength={6} onChange={onChangePhone} onBlur={verifyOTPPhoneLengthOnBlur}/>
                    <p style={{color:'red'}}>{otpPhoneErrorMsg}</p>
                
                    <BasicButton
                    text="Verify"
                    disabled={loadingPhoneOTPVerify ? true : false}
                    // onClick={() => handleVerifyOTPButtonClick(phoneOtp)}
                    onClick={() => handleVerifyPhoneOTPButtonClick(phoneOtp)}
                    isLoading={loadingPhoneOTPVerify? true : false}
                    />
                </div>
              </>
            ) : (
              <></>
            )}
            
               {/* <p className="inputLabel">Enter Code</p>
                <InputTypeText
                  className="inputText"
                  autoFocus={true}
                />

                <BasicButton
                text={t("verify.verify")}
                disabled={loading ? true : false}
                isLoading={loading ? true : false}
                /> */}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default Verfication;












        // <Accordion
        //   expanded={expanded === "panel1"}
        //   onChange={handleChange("panel1")}
        // >
        //   <AccordionSummary
        //     expandIcon={<ExpandMoreIcon />}
        //     aria-controls="panel1bh-content"
        //     id="panel1bh-header"
        //     style={{ alignItems: 'center' }}
        //   >
        //     <p
        //       style={{
        //         width: "40%",
        //         fontWeight: "bold",
        //         flexShrink: 0,
        //         textAlign: "left",
        //         margin: '0'
        //       }}
        //     >
        //       2FA
        //     </p>
        //     <p style={{ textAlign: "left", margin: '0px' }}>{t("verify.title.description")}</p>
        //   </AccordionSummary>
        //   <AccordionDetails>
        //     {location.state.data.qr ? (
        //       <img src={location.state.data.qr} />
        //     ) : (
        //       " "
        //     )}
        //     <form className="verification-form">
        //       <InputComponent
        //         className="enter-inp"
        //         placeholder="Enter code"
        //         autoFocus={true}
        //         value={token}
        //         onChange={onChangeToken}
        //         errorMsg={tokenError}
        //       />
        //       <Button
        //             style={{ marginTop: 20 }}
        //             disabled={loading ? true : false}
        //             variant='contained'
        //             type="submit"
        //             className="send-otp-btn"
        //             onClick={handleButtonClick}
        //             endIcon={<EastIcon/>}
        //           >
        //             {loading ? (
        //           <Spinner animation={"border"} />
        //         ) : (
        //           `${t("verify.verify")}`
        //         )}
        //           </Button> 
                  
        //       {/* <button className="send-btn" onClick={handleButtonClick}>
        //         {loading ? (
        //           <Spinner animation={"border"} />
        //         ) : (
        //           `${t("verify.verify")}`
        //         )}
        //       </button> */}
        //     </form>
        //   </AccordionDetails>
        // </Accordion>
        
        // <Accordion
        //   expanded={expanded === "panel2"}
        //   onChange={handleChange("panel2")}
        // >
        //   <AccordionSummary
        //     expandIcon={<ExpandMoreIcon />}
        //     aria-controls="panel2bh-content"
        //     id="panel2bh-header"
        //   >
        //     <p
        //       style={{
        //         width: "40%",
        //         margin: '0px',
        //         fontWeight: "bold",
        //         flexShrink: 0,
        //         textAlign: "left",
        //       }}
        //     >
        //       {t("verify.otp.title")}
        //     </p>
        //     <p style={{ textAlign: "left", margin: '0px' }}>{t("verify.otp.description")}</p>
        //   </AccordionSummary>
        //   <AccordionDetails>
        //     <p>We will send OTP to your registered number</p>
            
        //     <div className="mr-xl-1">
        //       <Button
        //         hidden={btnSMSVisible}
        //         disabled={loadingOTP ? true : false}
        //         className="send-otp-btn"
        //         variant="contained"
        //         onClick={handleSendOTPButtonClick}
        //         endIcon={<SmsIcon />}>
        //         {loadingOTP ? (
        //           <>
        //             {" "}
        //             Sending.. <Spinner animation={"border"} />
        //           </>
        //         ) : (
        //           // `${otpBtnText} SMS OTP to ${userPhone}`
        //           `${otpBtnText} ${userPhone}`
        //         )}
        //       </Button>
        //       {/* <button
        //         hidden={btnSMSVisible}
        //         disabled={loadingOTP ? true : false}
        //         className="send-otp-btn"
        //         onClick={handleSendOTPButtonClick}
        //       >
        //         {loadingOTP ? (
        //           <>
        //             {" "}
        //             Sending.. <Spinner animation={"border"} />
        //           </>
        //         ) : (
        //           // `${otpBtnText} SMS OTP to ${userPhone}`
        //           `${otpBtnText}`
        //         )}
        //       </button> */}
        //     </div>

        //     {showOTPInput ? (
        //       <>
        //         <div
        //           style={{ alignContent: "center", width: "100%", padding: 10 }}
        //         >
        //           <OtpInput value={otp} valueLength={6} onChange={onChange} onBlur={verifyOTPLengthOnBlur}/>
        //           <p style={{color:'red'}}>{otpErrorMsg}</p>
        //           <Button
        //             style={{ marginTop: 20 }}
        //             disabled={loadingOTPVerify ? true : false}
        //             variant='contained'
        //             className="send-otp-btn"
        //             onClick={handleVerifyOTPButtonClick}
        //             endIcon={<EastIcon/>}
        //           >
        //             {loadingOTPVerify ? (
        //               <>
        //                 Verifying
        //                 <Spinner animation={"border"} />
        //               </>
        //             ) : (
        //               "Verify"
        //             )}
        //           </Button>
        //           {/* <button
        //       hidden={btnVoiceVisible}
        //       disabled={loadingOTPVoice ? true : false}
        //       className="send-otp-btn"
        //       onClick={handleSendOTPVoiceButtonClick}
        //     >
        //       {loadingOTPVoice ? (
        //         <>
        //           {" "}
        //           Sending.. <Spinner animation={"border"} />{" "}
        //         </>
        //       ) : (
        //         `${otpBtnText} Voice Call OTP to ${userPhone}`
        //       )}
        //     </button> */}
        //         </div>
        //         <div onClick={handleSendOTPVoiceButtonClick} style={{ fontSize: 13 }}> Having trouble Receiving SMS, {loadingOTPVoice ?
        //           <Spinner style={{width:15,height:15}} animation={"border"} /> : <CallIcon color='primary' style={{ cursor: 'pointer' }} />} call me instead.</div>
        //       </>
        //     ) : (
        //       <></>
        //     )}
        //   </AccordionDetails>
        // </Accordion>