import { ExchangeRateResponse } from "./interface";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiHelper } from "../../../api helper";

export const ExchangeRates = createAsyncThunk<ExchangeRateResponse, any>(
  "exchnageRate/Rate",
  async (payload) => {
    const token = localStorage.getItem("token");
    const data = await apiHelper("fxQuotes/currencyRates", "post", payload, {
      Authorization: `Bearer ${token}`,
    });
    return data;
  }
);
//main call getting rates from b4b
export const TempExchangeRates = createAsyncThunk<ExchangeRateResponse, any>(
  "tempExchangeRates/Rate",
  async (payload) => {
    const token = localStorage.getItem("token");
    const data = await apiHelper("fxQuotes/tempCurrencyRates", "post", payload, {
      Authorization: `Bearer ${token}`,
    });
    localStorage.setItem("tempExchangeRates", JSON.stringify(data.data.data));
    return data;
  }
);

