import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import LeftSideBar from "../../components/shared/LeftSidebar";
import PaymentReceiverCard from "../../components/shared/PaymentReceiverCard";
import PaymentReceiverCardNew from "../../components/shared/PaymentReceiverCard/index_new";
import RecentOperationsCard from "../../components/shared/RecentOperationCard";
import { CustomDataTable } from "../../components/shared/BankAccounts/CustomDataTable";
import RightSideBar from "../../components/shared/RightSidebar";
import { AppDispatch, RootState, store } from "../../redux/store";
import BankCard from "../../components/shared/BankCard";
import { getAllAccounts } from "../../redux/toolkit/getAllUserAccount/operation";
import { getRecurringPayments, getSchedulingPayments, SendScheduledFundsData } from "../../redux/toolkit/sendFunds/operations";
import { Button, Modal, Spinner, Table } from "react-bootstrap";
import CardDetailsTypes from "./interface";
import Footer from "../../components/shared/Footer";
import SkeletonCard from "../../components/shared/BankCard/SkeletonCard";
import greetiingsGif from "../../assets/images/fintech.gif";
import "./index.css";
import HamburgerMenu from "../../components/shared/HamburgerMenu";
import { BsArrowRight, BsThreeDotsVertical } from "react-icons/bs";
import InterestAccountCard from "../../components/shared/InterestAccountCard";
import Swal from "sweetalert2";
import {
  GetALlbeneficiariesPaginated,
  UpdateBeneficiary,
  deleteBeneficiaryAsync,
} from "../../redux/toolkit/beneficiaries/operation";
import InputComponent from "../../components/shared/Input/Index";
import { errorMsgs, regexStrConst } from "../../constants/stringConstants";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MUIDataTable, { TableFilterList ,MUIDataTableColumnDef  } from 'mui-datatables'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Logo from "../../assets/images/omw-logo.png";
import { updatePaymentStatus } from "../../redux/toolkit/sendFunds/operations";
import html2pdf from "html2pdf.js/dist/html2pdf.js";
import ProofOfAccountModal from "../../components/shared/PoaModal";
import _ from "lodash"

import { DeleteBlackIcon, EditPenIcon, SearchFilter, PrintFilter, DownloadFilter, FilterLines, ConfimationImage, TransferMoney, SelectedTransfer } from "../../constants/Images";
import { Toolbar } from "@mui/material";
import { GridToolbarContainer } from '@mui/x-data-grid';
import InputTypeText from "../../components/shared/Input/Basic/InputTypeText";
import BasicButton from "../../components/shared/Buttons/Basic";
import ExpeneAndPayments from "../../components/shared/Expense&Payments";
import ModalHeader from "../../components/shared/Modal/ModalHeader";
import AccountStatementModal from "./AccountStatementModal";
import ActiveUser from "./activeUser";
import { CustomSchedulingDataTable } from "../../components/shared/SchedulingPayments/CustomDataTable";
import { CiViewColumn } from "react-icons/ci";
import FundsTransferForm from "../SendFunds/FundsTransferForm";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

import CreateCardNowPrompt from "../BankAccountCard/CardSettingModals/CreateCardNowPrompt";
import cardSelectors from "../../redux/toolkit/cards/selector";
import { showCreateCardPrompt } from "../../redux/toolkit/cards/operation";
import { CreateCardPromptResponse } from "../../redux/toolkit/cards/interface";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { useScreenSize } from "../../components/shared/ResizeHook";
import InfoMessage from "../../components/shared/InfoMessage";

const BankAccounts = () => {
  const dispatch = useDispatch<AppDispatch>();
  const accountSelector: any = useSelector((state: RootState) => state.getAllAccounts.data);
  const isVisible = useSelector((state: RootState) => state.infoMessage.isVisible);
  const accountData: any = localStorage.getItem("AccountData");
  const parsedData: any = JSON.parse(accountData);
  const firstName: any = localStorage.getItem("firstName");
  const lastName: any = localStorage.getItem("lastName");
  const activeUser: any = localStorage.getItem("IsActiveUser");
  const [flag, setFlag] = useState(false);
  const [cardDetails, setCardDetails] = useState<any>([]);
  const userId: any = localStorage.getItem("userId");
  const companyRegNumber: any = localStorage.getItem("companyRegNumber");
  const companyName: any = localStorage.getItem("companyName");
  const roles: any = JSON.parse(localStorage.getItem("roles") || "[]");
  const [bankCode, setBankCode] = useState("");
  const [loader, setloader] = useState(false);
  const [show, setShow] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const beneficiariesData: any = useSelector((state: RootState) => state.beneficiary.data);
  const [beneficiariesState, setBeneficiariesState] = useState<[] | any>([]);
  const [beneficiaryPagination, setBeneficiaryPagination] = useState<any>({});
  const [benNameError, setBenNameError] = useState("");
  const [benEmailError, setBenEmailError] = useState("");
  const [benEmptyEmailErrMsg, setBenEmailEmptyErrMsg] = useState("");
  const [updateBenData, setUpdateBenData] = useState<any>([]);
  const [recurringTableData, setRecurringTableData] = useState<any>([]);
  const [schedulingTableData, setSchedulingTableData] = useState<any>([]);
  const [showPoaModal, setshowPoaModal] = useState<boolean>(false);
  const [beneficiaryData, setBeneficiaryData] = useState("")
  const [showSendMoney, setShowSendMoney] = useState(false);
  const [wasShowAllOpen, setWasShowAllOpen] = useState(false);
  const [show2FA, setShow2FA] = useState(false);

  const [tableSearchText, setTableSearchText] = useState("");
  const localAccountId: any = localStorage.getItem("accountId");

  const [selectedCard, setSelectedCard] = useState("")
  const { t } = useTranslation();
  const screenSize = useScreenSize();
  const trandferMoneyModalRef = useRef(null);
  const seeAllModalRef = useRef(null);
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);

  type AddressType = {
    line_1: string;
    postcode: string;
    city: string;
    country: string;
  }
  
  type Beneficiary = {
    name: string;
    account: string;
    email?: string;
    address?: AddressType;
    _id: string;
  }
  const [selectedRowData, setSelectedRowData] = useState<Beneficiary | null>(null);
  const [showRowModal, setShowRowModal] = useState<boolean>(false);


  useEffect(() => {
    const handleResize = () => {
      updateVisibleColumns(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Initial column visibility setup
    updateVisibleColumns(window.innerWidth);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const updateVisibleColumns = (width: number) => {
    if (width <= 576) {
      setVisibleColumns(['name', 'account','actions','email']); // Only show 'name' and 'account' columns for small screens
    } else {
      setVisibleColumns(['name', 'account', 'address', 'email', 'postcode', 'city', 'actions']); // Show all columns for larger screens
    }
  };

  // console.log(trandferMoneyModalRef, "######### ref check")

  const createCardPromptSelector: any = useSelector(cardSelectors.createCardPromptSelect);
  const [showCreateCardNowPrompt, setShowCreateCardNowPrompt] = useState<boolean>(false);

  const storedDataString = localStorage.getItem('AccountData');
  const getSelectedCardFromLS: any = storedDataString ? JSON.parse(storedDataString) : {};
  const beneficiaryReqPayload = {
    accountId: localAccountId,
    page: 1,
    limit: 10,
    search: ''
  }


  const beneficiariesHeaders: MUIDataTableColumnDef[]  = [
    {
      name: "name", label: 'Name', options: {

        //  filter: false,
        //   sort: false,
        //   viewColumns: true,
        display: visibleColumns.includes('name') ? 'true' : 'false',
        customBodyRenderLite: (dataIndex: any) => {
          return <><div style={{ marginLeft: '0px !important' }} >{beneficiariesState[dataIndex]?.name}</div></>
        }
      }
    },
    {
      name: "account", label: 'Account', options: {
        display: visibleColumns.includes('account') ? 'true' : 'false',
        customBodyRenderLite: (dataIndex: any) => {
          return <><div style={{ marginLeft: '0%' }} >{beneficiariesState[dataIndex]?.account}</div></>
        }
      }
    },
    // {
    //   name: "address", label: 'Address', options: {
    //     display: visibleColumns.includes('address') ? 'true' : 'false',
    //     customBodyRenderLite: (dataIndex: any) => {
    //       return <><div style={{ marginLeft: '0%' }} >{beneficiariesState[dataIndex]?.address?.line_1}</div></>
    //     }
    //   }
    // },
    // {
    //   name: "postcode", label: 'Post Code', options: {
    //     display: visibleColumns.includes('postcode') ? 'true' : 'false',
    //     customBodyRenderLite: (dataIndex: any) => {
    //       return <><div style={{ marginLeft: '0%' }} >{beneficiariesState[dataIndex]?.address?.postcode}</div></>
    //     }
    //   }
    // },
    {
      name: "email", label: 'Email', options: {
        display: visibleColumns.includes('email') ? 'true' : 'false',
        customBodyRenderLite: (dataIndex: any) => {
          return <><div style={{ marginLeft: '0%' }}>{beneficiariesState[dataIndex]?.email}</div></>
        }
      }
    },
    {
      name: "city", label: 'City', options: {
        display: visibleColumns.includes('city') ? 'true' : 'false',
        customBodyRenderLite: (dataIndex: any) => {
          return <><div style={{ marginLeft: '0%' }}>{beneficiariesState[dataIndex]?.address?.city}</div></>
        }
      }
    },
    {
      name: "actions", label: 'Actions', options: {
        display: visibleColumns.includes('actions') ? 'true' : 'false',
        customBodyRenderLite: (dataIndex: any) => {
          return <>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              {/* <EditIcon
                style={{ cursor: "pointer", marginRight: 20 }}
                color="primary"
                onClick={() => {
                  editBeneficiary(beneficiariesState[dataIndex]?._id);
                }}
                fontSize="small"
              /> */}
              <img src={SelectedTransfer} style={{ cursor: "pointer", }}
              onClick={(e) => {
                e.stopPropagation();
                handelBeneficiaryData(beneficiariesState[dataIndex]);
              }} 
              />
              <img src={EditPenIcon} style={{ cursor: "pointer", }}
              onClick={(e) => {
                e.stopPropagation();
                editBeneficiary(beneficiariesState[dataIndex]?._id);
              }} 
              />
              <img src={DeleteBlackIcon}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.stopPropagation();
                  deleteBeneficiary(beneficiariesState[dataIndex]?._id);
                }} 
                />
              {/* <DeleteIcon
                style={{ cursor: "pointer" }}
                color="error"
                onClick={() => deleteBeneficiary(beneficiariesState[dataIndex]?._id)}
                fontSize="small"
              /> */}
            </div></>
        }
      }
    }
  ]

  // Custom Checks for Session User
  useEffect(() => {
    if(accountSelector?.data) {
      if (!localStorage.getItem("accountId") || localStorage.getItem("accountId") === 'null') {
        localStorage.setItem("accountId", accountSelector?.data?.data[0]?.accB4BUUID);
        const customEvent = new CustomEvent("CardValue", {
          detail: {
            accountId: accountSelector?.data?.data[0]?.accB4BUUID,
            activeAccId: accountSelector?.data?.data[0],
          },
        });
        document.dispatchEvent(customEvent);
      }
    } else {
      setCardDetails([])
    }
  }, [accountSelector])

  useEffect(() => {

    if (parsedData?.IBAN.slice(0, 2) === "DK") {
      setBankCode(parsedData?.IBAN.slice(4, 8));
      setFlag(true);
    } else {
      setFlag(false);
    }
  }, [show]);

  useEffect(() => {
    !createCardPromptSelector.status
      && dispatch(showCreateCardPrompt({ userId: localStorage.getItem("userId")! }))
        .unwrap()
        .then((resp: CreateCardPromptResponse) => {
          if (resp.showPrompt)
            setShowCreateCardNowPrompt(resp.showPrompt)
        })
        .catch((err) => {
          console.log("Error occured in determining if create card prompt shown:", err);
        });

    dispatch(getAllAccounts(userId));
    setloader(true);
  }, [dispatch, loader]);

  useEffect(() => {
    if (accountSelector?.data) {
      getRecPayments();
      getSchPayments();
      setCardDetails(accountSelector?.data.data);
      if (!localStorage.getItem("AccountData")) {
        localStorage.setItem("AccountData", JSON.stringify(accountSelector?.data?.data[0]));
      }
      if (!localStorage.getItem("activeAccId")) {
        localStorage.setItem("activeAccId", accountSelector?.data?.data[0]?._id);
      }
      if (!localStorage.getItem("accountId")) {
        localStorage.setItem("accountId", accountSelector?.data?.data[0]?.accB4BUUID);
        const customEvent = new CustomEvent("CardValue", {
          detail: {
            accountId: accountSelector?.data?.data[0]?.accB4BUUID,
            activeAccId: accountSelector?.data?.data[0],
          },
        });
        document.dispatchEvent(customEvent);
      }
      setloader(false);
    }
  }, [accountSelector, cardDetails, loader]);

  useEffect(() => {
    if (beneficiariesData) {
      // console.log("BeneficiariesDATAAAAAAAAAAA:", beneficiariesData);
      setBeneficiariesState(beneficiariesData?.data?.data);
      setBeneficiaryPagination(beneficiariesData?.data?.pagination);
      // console.log("Beneficiaries:", beneficiariesState);
      setloader(false);
    }
  }, [beneficiariesData, beneficiariesState, loader]);

  useEffect(() => {
    validation();
  }, [updateBenData]);

  const handleClickCard = (
    ele: string,
    id: string,
    data: {
      _id: React.Key | null | undefined;
      currency: string | undefined;
      accountName: string | undefined;
      IBAN: string | undefined;
      accB4BUUID: unknown;
    }
  ) => {
    const customEvent = new CustomEvent("CardValue", {
      detail: { accountId: ele, activeAccId: id },
    });
    setSelectedCard(id)
    document.dispatchEvent(customEvent);
    localStorage.setItem("accountId", ele);
    localStorage.setItem("activeAccId", id);
    localStorage.setItem("AccountData", JSON.stringify(data));
    getRecPayments(ele);
    getSchPayments(ele);
  };

  const eyeClick = () => {
    setShow(true);
  };
  const handleViewAccClick = () => {
    // setShowAcc(true);
    setshowPoaModal(true);
  };
  const handleCloseViewAccClick = () => {
    // setShowAcc(false);
    setshowPoaModal(false);
  };

  const handleClose = () => {
    setShow(false);
  };


  const handleClosePR = () => {
    setBeneficiariesState([])
    setShowAll(false);
  };

  const handleCloseEditBen = () => {
      setShowEdit(false);
      if (wasShowAllOpen) {
        setShowAll(true);
        setWasShowAllOpen(false);
      }
  };
  const getRecPayments = async (id: any = null) => {
    const { payload } = await dispatch(getRecurringPayments(id || parsedData?.accB4BUUID));
    if (payload) {
      const da = JSON.parse(JSON.stringify(payload));
      setRecurringTableData(da.data)
    }
  };

  const getSchPayments = async (id: any = null) => {
    const { payload } = await dispatch(getSchedulingPayments(id || parsedData?.accB4BUUID));
    if (payload) {
      const da = JSON.parse(JSON.stringify(payload));
      setSchedulingTableData(da.data)
    }
  };

  const deleteBeneficiary = (beneficiaryId: any) => {
    Swal.fire({
      imageUrl: ConfimationImage,
      title: `${t("sweetAlerts.areYouSure")}`,
      text: `${t("sweetAlerts.deleteRecord")}`,
      // icon: "question",
      showCancelButton: true,
      confirmButtonText: `${t("sweetAlerts.yes")}`,
      cancelButtonText: `${t("sweetAlerts.no")}`,
      customClass: {
        confirmButton: 'modalBtnOK',
        cancelButton: "modalBtnCancel"
      }
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteBeneficiaryAsync(beneficiaryId));
        //  setloader(true);
        // setBeneficiariesState((beneficiariesState) => beneficiariesState.filter((item) => item._id !== beneficiaryId));
      } else {
        // User clicked "No" or closed the SweetAlert popup
        // Handle the cancellation or any other action you want
      }
    });
  };

  const editBeneficiary = (beneficiaryId: any) => {
    const singleBeneficiary: any = beneficiariesState.filter(
      (ele: any) => ele._id === beneficiaryId
    );
    setUpdateBenData(singleBeneficiary[0]);
    if(showAll){
      setWasShowAllOpen(true);
      setShowAll(false);
    }
    setShowEdit(true);
  };

  const handelBeneficiaryData = (beneficiaryData: any) => {
    setBeneficiaryData(beneficiaryData)
    if (showAll) {
      setWasShowAllOpen(true);
      setShowAll(false);
    }
    setShowSendMoney(true);
  }
  const handleCloseModal = () => {
    setShowSendMoney(false);
    if (wasShowAllOpen) {
      paymentReceiverShowAllHandler()
      setWasShowAllOpen(false);
    }
  }

  const newBenData = (e: any) => {
    // validation(e);
    setUpdateBenData({ ...updateBenData, [e.target.name]: e.target.value });
  };

  const handleUpdateBen = (e: any) => {
    e.preventDefault();
    if (validate()) {
      dispatch(UpdateBeneficiary(updateBenData));
    }
  };

  const validate = () => {
    return benNameError !== "" ||
      benEmailError !== "" ||
      benEmptyEmailErrMsg !== ""
      ? false
      : true;
  };

  const validation = () => {
    updateBenData && updateBenData.name === ""
      ? setBenNameError(errorMsgs.emptyName)
      : setBenNameError("");

    if (updateBenData.email !== "") {
      const item = updateBenData.email;
      const regex = regexStrConst.emailRegex;
      if (!regex.test(item)) {
        setBenEmailError(errorMsgs.incorrectEmail);
      } else {
        setBenEmailError("");
      }
    } else if (updateBenData.email === "") {
      setBenEmailError(errorMsgs.emptyEmail);
    }
  };


  const renderIcon = (icon: string) => <img src={icon} alt="" />

  const customIcons: any = {
    SearchIcon: () => renderIcon(SearchFilter),
    PrintIcon: () => renderIcon(PrintFilter),
    DownloadIcon: () => renderIcon(DownloadFilter),
    FilterIcon: () => renderIcon(FilterLines),
    ViewColumnIcon: () => <CiViewColumn style={{ color: 'black', fontSize: "larger" }} />,
  }
  const paymentReceiverShowAllHandler = () => {
    dispatch(GetALlbeneficiariesPaginated({ ...beneficiaryReqPayload, page: 1, accountId: localAccountId })).then((res) => {
      const data = JSON.parse(JSON.stringify((res as any).payload?.data.data));
      setBeneficiariesState(data)
    })
    setShowAll(true)
  }

  const CustomPagination = (props: { count: number, rowsPerPage: number, page: number, onChangePage: (newPage: number) => void }) => {
    const { count, rowsPerPage, page, onChangePage } = props;

    const handlePageChange = async (event: React.ChangeEvent<unknown>, newPage: number) => {
      await dispatch(GetALlbeneficiariesPaginated({ ...beneficiaryReqPayload, page: newPage, accountId: localAccountId, search: tableSearchText ? tableSearchText : '' }))
      onChangePage(newPage);
    };

    // const dataTableSearchChange=(searchText:any)=>{
    //   dispatch(GetALlbeneficiariesPaginated({...beneficiaryReqPayload,page:1,accountId:localAccountId,search:searchText ? searchText:''}))
    //   setTableSearchText(searchText)
    // }
    // Implement your custom rendering logic here
    return (
      <Pagination
        sx={{
          marginTop: "5px",
          '& ul': { justifyContent: 'center' },
        }}
        color="primary"
        variant="outlined"
        shape="rounded"
        size={screenSize.width <= 500 || (screenSize.width < 1005 && screenSize.width > 760) ? "small" : "medium"}
        showFirstButton
        showLastButton
        count={Math.ceil(count / rowsPerPage)}
        page={page + 1}
        onChange={handlePageChange}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            component="div"
            sx={{
              fontFamily: 'Montserrat',
              margin: "0px 1px !important",
              border: "1px solid rgba(0, 0, 0, 0.23) !important",
              borderRadius: "6px !important",
               padding: "0px 4px !important",
              '&:hover:not(:active)': { backgroundColor: '#006FF4', color: '#FFFFFF' },
              "&.Mui-selected": {
                backgroundColor: "#006FF4",
                color: "white",
                borderColor: "transparent",
                borderRadius: "6px"
              }
            }}
            slots={{
              previous: () => screenSize.width <= 768
                ? <GrFormPrevious width={"1em"} height={"1em"} fontSize={"1.15rem"} />
                : <p style={{ margin: 0 }}>{t("pagination.previous")}</p>,
              next: () => screenSize.width <= 768
                ? <GrFormNext width={"1em"} height={"1em"} fontSize={"1.15rem"} />
                : <p style={{ margin: 0 }}>{t("pagination.next")}</p>,
            }}
          />
        )}
      />
    );
  };

  const onClosePaymentReceiverModal = () => {
    dispatch(GetALlbeneficiariesPaginated({ ...beneficiaryReqPayload, page: 1, accountId: localAccountId, search: '', limit: 3 }));
    setShowAll(false);
  }

  const dataTableSearchChange = (text: any) => {
    setTableSearchText(text);
    debouncedSave(text)
  }
  const debouncedSave = useCallback(
    _.debounce(
      (value: string) =>
        dispatch(GetALlbeneficiariesPaginated({ ...beneficiaryReqPayload, page: 1, accountId: localAccountId, search: value ? value.trim() : '' })),
      600,
    ),
    [], // will be created only once initially
  );

  const handleRowClick = (rowData: Beneficiary): void => {
    setSelectedRowData(rowData);
    if (showAll) {
      setWasShowAllOpen(true);
      setShowAll(false);
    }
    setShowRowModal(true);
  };

  const handleCloseRowModal = () => {
    setShowRowModal(false);
    setSelectedRowData(null);
    if (wasShowAllOpen) {
      setWasShowAllOpen(false);
      setShowAll(true);
    }
  };



  return (
    <>
      {/* <div className="hamburgerBackground">
        <HamburgerMenu name="bankAccount" />
      </div> */}
      <div className="Bank-info-main">
      <div>
      {isVisible &&
        <InfoMessage />}
      </div>

        {/* <div className="left_sidebar position-fixed">
          <LeftSideBar name="bankAccount" />
        </div> */}

        <div className="inner__content position-relative">
          {activeUser === "true" ? (
            <div>
              <div className="row p-0">
                <div className="col-12">
                  <div className="row d-flex justify-content-between">
                    {/* <div className="heading_title">
                    <h2 className="generalInfo-heading">
                      {t("bankAccounts.title")}
                    </h2>
                  </div> */}
                    {/* Cards */}
                    <div className="row col-sm-12 col-md-8 col-lg-8 bank-card bacnkAccount-SectionBackground m-0" >
                      {loader ? (
                        <>
                          <SkeletonCard />
                          <SkeletonCard />
                          <SkeletonCard />
                        </>
                      ) : (
                        cardDetails.length > 0 &&
                        cardDetails.map(
                          (ele: CardDetailsTypes, index: number) => (
                            <Col xs={6} sm={6} md={6} lg={4} xl={4} className="bankCard-col p-1" style={{ zIndex: 'unset' }}
                              // className="col-xl-4 col-md-6 col-lg-4 col-sm-12 p-1"
                              key={ele._id}>
                              <BankCard
                                // className={(getSelectedCardFromLS?._id == ele?._id && !ele.inactive) ? "cardSelected":''}
                                accId={ele.accB4BUUID}
                                currency={ele.currency}
                                name={ele.accountName}
                                index={index}
                                ibanNo={ele.IBAN}
                                inactive={ele.inactive}
                                handleEyeClick={eyeClick}
                                handleViewAccClick={handleViewAccClick}
                                handleClick={() => {
                                  if (!ele.inactive) {
                                    handleClickCard(ele.accB4BUUID, ele._id, ele);
                                  }
                                }}
                              />
                            </Col>
                          )
                        )
                      )}
                    </div>
                    {/* Cards */}
                    {/* Right bar */}
                    <div className="monthlyRevenueTrackerDiv col-sm-12 col-md-4 col-lg-4">
                      {/* <RightSideBar expense_payments summaryBox interestAccount /> */}
                      <ExpeneAndPayments />
                    </div>

                    {/* Right bar */}

                  </div>
                </div>
                {/* <div className="col-12">
                  <div className="interest-account-container">
                    <InterestAccountCard />
                  </div>
                </div> */}
                <div className="col-12 mt-4 bacnkAccount-SectionBackground">
                  <div className="card-details">
                    <RecentOperationsCard />
                  </div>
                </div>

                {
                  roles && roles?.length > 0 && !roles?.includes('ACCOUNTANT') ? (<>
                  {/* Recurring patments Table */}
                    <div className="col-12 mt-4 bacnkAccount-SectionBackground materialActionsTable recurring-table">
                      <CustomDataTable data={recurringTableData} updatePaymentStatus={updatePaymentStatus} getRecPayments={getRecPayments} className='RecuringTable' />
                    </div>
                    {/* Scheduling payments Table */}
                    <div className="col-12 mt-4 bacnkAccount-SectionBackground materialActionsTable pay-scheduling-table">
                      <CustomSchedulingDataTable data={schedulingTableData} SendScheduledFundsData={SendScheduledFundsData} getSchPayments={getSchPayments} className='RecuringTable' />
                    </div>
                  </>) : null
                }
                {/*  Recurring Section */}
                {/* <div className="col-12 mt-4 bacnkAccount-SectionBackground materialActionsTable">
                    <CustomDataTable data={recurringTableData} updatePaymentStatus={updatePaymentStatus} getRecPayments={getRecPayments} className='RecuringTable'/>
                </div> */}

                <div className="payment-receiver-contentt col-12 mt-4 bacnkAccount-SectionBackground p-3">
                  <div className="row">
                    <div className="payment-receiver-content-box col-12 mb-3">
                      <div className="title-see-all-button">
                        <p className="payment-receiver-text m-0">
                          {t("bankAccounts.receiver")}
                        </p>
                      </div>
                      <div className="load-more-text-recent-div">
                        <span
                          className="load-more-text-recent"
                          onClick={paymentReceiverShowAllHandler}
                        >
                          {t("recentOperation.seeAll")}
                          {/* <BsArrowRight size={12} /> */}
                        </span>
                      </div>
                    </div>
                    {/* Old card payments component */}
                    {/* Payment receivers old */}
                    {/* <div className="main-payment-receivers-container ">
                      <Row style={{padding:'0 15px', width:'100%'}}>
                      <PaymentReceiverCard />
                      </Row>
                    </div> */}

                    <div>
                      <Row className="new-card-payment-container">
                        <PaymentReceiverCardNew />
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <ActiveUser firstName={firstName} lastName={lastName} />
          )}
          {/* <Footer /> */}
        </div>
        {/* <RightSideBar expense_payments summaryBox interestAccount /> */}
        {/* Busiiness account card || account details modal */}

        {/* three dot icon click modal || account details modal */}
        <Modal
          style={{ marginTop: 100 }}
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          className="account-detail-modal-root"

        >
          {/* <Modal.Header closeButton>
            <Modal.Title>{t("bankAccounts.modal.title")}</Modal.Title>
          </Modal.Header> */}
          <ModalHeader title={t("bankAccounts.modal.title")} onClose={() => setShow(false)} />
          <Modal.Body>

            <Table className="account-details-table">
              <tbody>
                <tr>
                  <td style={{ fontWeight: '600' }}>{t("bankAccounts.modal.bank")}:</td>
                  <td>{parsedData?.bank}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: '600' }}>{t("bankAccounts.modal.address")}:</td>
                  <td>{parsedData?.bankAddress}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: '600' }}>{t("bankAccounts.modal.name")}:</td>
                  <td>{parsedData?.accountName}</td>
                </tr>
                {parsedData?.currency === "GBP" &&
                  <tr>
                    <td style={{ fontWeight: '600' }}>{t("bankAccounts.modal.sortCode")}:</td>
                    <td>{parsedData?.sortCode}</td>
                  </tr>
                }

                {flag ? (
                  <tr>
                    <td style={{ fontWeight: '600' }}>{t("bankAccounts.modal.bankCode")}:</td>
                    <td>{bankCode}</td>
                  </tr>
                ) : (
                  ""
                )}
                <tr>
                  <td style={{ fontWeight: '600' }}>{t("bankAccounts.modal.accountNo")}:</td>
                  <td>
                    {flag
                      ? parsedData?.IBAN.slice(8)
                      : parsedData?.accountNumber}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: '600' }}>{t("bankAccounts.modal.iban")}:</td>
                  <td>{parsedData?.IBAN}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: '600' }}>{t("bankAccounts.modal.swiftCode")}:</td>
                  <td>{parsedData?.swiftCode}</td>
                </tr>
              </tbody>
            </Table>
            <div>
              <Button variant="primary"
              style={{fontWeight: 600 , fontSize: "14px", fontFamily: "Montserrat"}}
              className="d-block w-100"
              onClick={handleViewAccClick}
              >
                {t('statement.pdf.proofofAccountStatement')}
              </Button>
              </div>
          </Modal.Body>
        </Modal>

        {/* Account card right tiles icon click modal */}
        {showPoaModal && (
          <ProofOfAccountModal
            showPoaModal={showPoaModal}
            handleClose={handleCloseViewAccClick}

          />
        )}
      </div>

      {/* payment receiver "See all" modal */}
      <Modal
        // style={{ marginTop: 100, maxHeight: "75%" }}
        show={showAll}
        onHide={handleClosePR}
        backdrop="static"
        keyboard={false}
        width={"100%"}
        scrollable
        size="xl"
        dialogClassName="my-modal"
      >
        <ModalHeader title={t("paymentReceiver.seeAllModal.title")} onClose={onClosePaymentReceiverModal} modalBodyRef={seeAllModalRef} />
        <Modal.Body ref={seeAllModalRef}>
          <div className="materialActionsTable allPaymentReceiverList header-bg-blue">
            <MUIDataTable
              title={""}
              data={beneficiariesState}
              columns={beneficiariesHeaders}
              components={{ icons: customIcons }}
              options={{
                serverSide: true,
                selectableRows: "single",
                selectableRowsHideCheckboxes: true,
                selectableRowsOnClick: false,
                filter: false,
                responsive: 'standard',
                onRowClick: (rowData, rowMeta) => {
                  handleRowClick(beneficiariesState[rowMeta.dataIndex]);
                },
                onSearchChange: (searchText) => { dataTableSearchChange(searchText) },
                // onSearchClose:()=>{
                //   dispatch(GetALlbeneficiariesPaginated({...beneficiaryReqPayload,page:1,accountId:localAccountId,search:''}))
                // },               
                // customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => (
                //   <CustomPagination
                //     count={beneficiaryPagination?.pages ? beneficiaryPagination?.pages * 10 : count}
                //     page={beneficiaryPagination?.currentPage - 1}
                //     rowsPerPage={rowsPerPage}
                //     onChangePage={changePage}
                //   />
                // ),
                customFooter: () => null,
                setRowProps: () => {
                  return {
                    style: { cursor: 'pointer' },
                  };
                },
                onDownload: (buildHead: any, buildBody: any, columns, data) => {
                  const formattedData: any = [];
                  data.forEach((el: any) => {
                    formattedData.push({
                      index: el.index,
                      data: [
                        el?.data[0],
                        el?.data[1],
                        el?.data[2]?.line_1,
                        el?.data[2]?.postcode,
                        el?.data[2]?.city,
                      ]
                    })
                  })
                  return "\uFEFF" + buildHead(columns) + buildBody(formattedData);
                }
              }}
            />
            {/* <table className="table p-4">
              <thead>
                <tr>
                  <td className="amount-op-text">Name</td>
                  <td className="amount-op-text">IBAN</td>
                  <td className="amount-op-text">Address</td>
                  <td className="amount-op-text">PostCode</td>
                  <td className="amount-op-text">City</td>
                  <td className="amount-op-text" colSpan={2}>
                    Actions
                  </td>
                </tr>
              </thead>
              <tbody>
                {beneficiariesState && beneficiariesState.length > 0 ? (
                  beneficiariesState
                    .slice(0)
                    .reverse()
                    .map((ele: any, index: number) => {
                      return (
                        <>
                          <tr key={index}>
                            <td
                              className="newClass"
                              style={{
                                maxWidth: 245,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {ele.name}
                            </td>
                            <td
                              className=""
                              style={{
                                maxWidth: 400,
                                whiteSpace: "nowrap",
                              }}
                            >
                              {ele.account}
                            </td>
                            <td
                              style={{
                                maxWidth: 180,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {ele.address.line_1}
                            </td>
                            <td
                              className=""
                              style={{
                                maxWidth: 150,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {ele.address.postcode}
                            </td>
                            <td
                              className=""
                              style={{
                                maxWidth: 100,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {ele.address.city}
                            </td>
                            <td style={{ display: "flex" }}>
                              <EditIcon
                                style={{ cursor: "pointer", marginRight: 20 }}
                                color="primary"
                                onClick={() => {
                                  editBeneficiary(ele._id);
                                }}
                                fontSize="small"
                              />
                              <DeleteIcon
                                style={{ cursor: "pointer" }}
                                color="error"
                                onClick={() => deleteBeneficiary(ele._id)}
                                fontSize="small"
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })
                ) : (
                  <div className="no-data-container">
                    <span className="no-recent-op">
                      {t("paymentReceiver.noData")}
                    </span>
                  </div>
                )}
              </tbody>
            </table> */}
          </div>
        </Modal.Body>
        <Modal.Footer style={{width: "100%",display: "flex", justifyContent: "center",border: "none"}}>
          <CustomPagination
            count={beneficiaryPagination?.pages ? beneficiaryPagination?.pages * 10 : 0}
            page={beneficiaryPagination?.currentPage - 1}
            rowsPerPage={10}
            onChangePage={(newPage:number) => {
              // Handle page change
            }}
          />
        </Modal.Footer>
      </Modal>

      {/* edit benificiety modal */}
      <Modal
        style={{ marginTop: 100 }}
        show={showEdit}
        onHide={handleCloseEditBen}
        backdrop="static"
        keyboard={false}
        className="edit-benificiety-modal-root"
      >
        <ModalHeader title={t("paymentReceiver.editBeneficiaryModal.title")} onClose={handleCloseEditBen}/> 
        <Modal.Body>
          <form onSubmit={handleUpdateBen}>
            <p className="formInputLabel">{t("addRecipient.form.name")}</p>
            <InputTypeText
              className="mt-2"
              name="name"
              placeholder={t("addRecipient.form.nameP")}
              defaultValue={updateBenData && updateBenData?.name}
              // onBlur={() =>
              //   updateBenData && updateBenData.name === ""
              //     ? setBenNameError(errorMsgs.emptyName)
              //     : setBenNameError("")
              // }
              onChange={newBenData}
              errorMsg={benNameError}
            />
            <p className="formInputLabel">{t("addRecipient.form.email")}</p>
            <InputTypeText
              className="mt-2"
              name="email"
              placeholder={t("addRecipient.form.emailP")}
              defaultValue={updateBenData && updateBenData?.email}
              // onBlur={() =>
              //   updateBenData.email === ""
              //     // ? setEmailError(errorMsgs.emptyEmail)
              //     // : !regexStrConst.emailRegex.test(email)
              //     ? setBenEmailEmptyErrMsg(errorMsgs.emptyEmail)
              //     : setBenEmailEmptyErrMsg("")
              // }
              onChange={newBenData}
              errorMsg={benEmailError}
            />
            <div className="btn-send mt-4">
              <BasicButton type="submit" isLoading={loader ? true : false} disabled={loader ? true : false} text={t("paymentReceiver.editBeneficiaryModal.button")} />
              {/* {loader ? (
                  <Spinner animation={"border"} />
                ) : (
                  `${t("paymentReceiver.editBeneficiaryModal.button")}`
                )} */}
              {/* </BasicButton> */}
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* Fund transfer moder */}
      <Modal
        show={showSendMoney}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        size="lg"
        className={show2FA ? 'd-none' : 'd-block'}
      >
        <ModalHeader title={t("sendFunds.title")} onClose={handleCloseModal} modalBodyRef={trandferMoneyModalRef} />
        <Modal.Body
          ref={trandferMoneyModalRef}
        >
          <FundsTransferForm beneficiaryData={beneficiaryData} setShow2FA={setShow2FA} setShowSendMoney={setShowSendMoney} />
        </Modal.Body>
      </Modal>

      {/* Recipient Detail Modal */}
      <Modal
        style={{ marginTop: 100 }}
        show={showRowModal}
        onHide={handleCloseRowModal}
        backdrop="static"
        keyboard={false}
        className="recipient-detail-modal-root"
      >
        <ModalHeader title={t("recipient.modal.title")} onClose={handleCloseRowModal}/> 
        <Modal.Body>
          <Table>
            <tbody>
              <tr>
                <td className="detailLabel">{t("recipient.modal.name")}:</td>
                <td>{selectedRowData?.name}</td>
              </tr>
              <tr>
                <td className="detailLabel">{t("recipient.modal.iban")}:</td>
                <td>{selectedRowData?.account}</td>
              </tr>
              <tr>
                <td className="detailLabel">{t("recipient.modal.address")}:</td>
                <td>{selectedRowData?.address?.line_1}</td>
              </tr>
              <tr>
                <td className="detailLabel">{t("recipient.modal.postCode")}:</td>
                <td>{selectedRowData?.address?.postcode}</td>
              </tr>
              <tr>
                <td className="detailLabel">{t("recipient.modal.city")}:</td>
                <td>{selectedRowData?.address?.city}</td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      {/* Modal: Create Card prompt for clients who have:
      1. Passed their KYB process and have account details issued and added to OMW
      2. Have not yet issued any cards for themselves

      PS.
      1. Only to be shown ONCE after the clients login
      2. Shown a maximum of three times only */}
      {!createCardPromptSelector.shownOnLogin && showCreateCardNowPrompt && (
        <CreateCardNowPrompt
          showCreateCardNowPrompt={showCreateCardNowPrompt}
          setShowCreateCardNowPrompt={setShowCreateCardNowPrompt}
        />
      )}
    </>
  );
};

export default BankAccounts;
