import { SednFundsResponse } from "./interface";
import { createSlice } from "@reduxjs/toolkit";
import { SendFundsData,SendFundsRecurring } from "./operations";

const initialState = {
  data: {},
} as SednFundsResponse;

const sendFundsDetailsSlice = createSlice({
  name: "sendFundsDtails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(SendFundsData.fulfilled, (state: any, action) => {
      state.data = action.payload;
    });
    builder.addCase(SendFundsRecurring.fulfilled, (state: any, action) => {
      state.data = action.payload;
    });
  },
});

export default sendFundsDetailsSlice.reducer;
