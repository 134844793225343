import { SendScheduledFundsRequestData, SednFundsResponse, SendFundsRequestData, RecurringPaymentsResponse, GetRecurringPaymentsPayload, SendRecurringFundsRequestData, UpdateRecurringStatusPayload, UpdateRecurringStatusResponse } from "./interface";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiHelper } from "../../../api helper";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { ErrorImage } from "../../../constants/Images";
import { showAlert } from "../swal/reducer";

export const SendFundsData = createAsyncThunk<
  SednFundsResponse,
  SendFundsRequestData
>("sendFunds/SendFunds", async (payload, {dispatch}) => {
  const dataBody = {
    beneficiary_uuid: payload.beneficiary_uuid,
    // description: payload.description,
    payment_reference: payload.payment_reference,
    payment_amount: payload.payment_amount,
    payment_currency: payload.payment_currency,
    payment_reason: payload.payment_reason,
    uuid: payload.uuid,
    userID: payload.userID,
  };
  const token = localStorage.getItem("token");
  try {
    const data = await apiHelper("payment", "post", dataBody, {
      Authorization: `Bearer ${token}`,
    });
    if (data.data.data.status !== "ERROR") {
      // Swal.fire({
      //   icon: "info",
      //   title: data.data.data.status,
      //   confirmButtonColor: "#86B6EF",
      //   text: "Payment is being processed!",
      // });
    } else {
      throw new Error();
    }
    return data;
  } catch (error: any) {
    // Swal.fire({
    //   imageUrl: ErrorImage,
    //   // icon: "error",
    //   title: "Oopss",
    //   // confirmButtonColor: "#86B6EF",
    //   text: error,
    //   customClass:{
    //     confirmButton:'modalBtnOK'
    //   }
    // });
    dispatch(showAlert({
      type: 'error',
      title: "Oopss",
      text: error,
      imageUrl: ErrorImage,
      imageAlt: 'Error Image'
    }));
    
    throw new Error(error);
  }
});

export const SendFundsRecurring = createAsyncThunk<
  SednFundsResponse,
  SendRecurringFundsRequestData
>("sendFunds/SendFundsRecurring", async (payload, {dispatch}) => {
  const token = localStorage.getItem("token");
  try {
    const data = await apiHelper("payment/recurring", "post", payload, {
      Authorization: `Bearer ${token}`,
    });
    // console.log("data from operations=>", data)
    if (data.data.data.status !== "ERROR") {
      // Swal.fire({
      //   icon: "info",
      //   title: data.data.data.status,
      //   confirmButtonColor: "#86B6EF",
      //   text: "Payment is being processed!",
      // });
    } else {
      throw new Error();
    }
    return data;
  } catch (error: any) {
    // Swal.fire({
    //   // icon: "error",
    //   imageUrl: ErrorImage,
    //   title: "Oopss",
    //   // confirmButtonColor: "#86B6EF",
    //   text: error,
    //   customClass:{
    //     confirmButton:'modalBtnOK'
    //   }
    // });
    dispatch(showAlert({
      type: 'error',
      title: "Oopss",
      text: error,
      imageUrl: ErrorImage,
      imageAlt: 'Error Image'
    }));
    throw new Error();
  }
});

// get recurring transactions from sended funds
export const getRecurringPayments = createAsyncThunk<RecurringPaymentsResponse, GetRecurringPaymentsPayload>(
  "payment/recurring",
  async (payload) => {
    // alert(payload)
    const token = localStorage.getItem("token");
    const data = await apiHelper<RecurringPaymentsResponse>(`payment/get-recurring-payments?accountId=${payload}`, "get", {}, {
      Authorization: `Bearer ${token}`,
    }
    );
    // return data;
    return data.data;
  });

  export const SendScheduledFundsData = createAsyncThunk<
  SednFundsResponse,
  SendScheduledFundsRequestData
>("sendFunds/SendFunds", async (payload, {dispatch}) => {
  const dataBody = {
    beneficiary_uuid: payload.beneficiary_uuid,
    // description: payload.description,
    payment_reference: payload.payment_reference,
    payment_amount: payload.payment_amount,
    payment_currency: payload.payment_currency,
    payment_reason: payload.payment_reason,
    uuid: payload.uuid,
    userID: payload.userID,
  };
  const token = localStorage.getItem("token");
  try {
    const data = await apiHelper(`payment/scheduling/${payload?.id}`, "post", dataBody, {
      Authorization: `Bearer ${token}`,
    });
    if (data.data.data.status !== "ERROR") {
      // Swal.fire({
      //   icon: "info",
      //   title: data.data.data.status,
      //   confirmButtonColor: "#86B6EF",
      //   text: "Payment is being processed!",
      // });
    } else {
      throw new Error();
    }
    return data;
  } catch (error: any) {
    // Swal.fire({
    //   icon: "error",
    //   title: "Oopss",
    //   confirmButtonColor: "#86B6EF",
    //   text: error,
    // });
    dispatch(showAlert({
      type: 'error',
      title: "Oopss",
      text: error,
      imageUrl: ErrorImage,
      imageAlt: 'Error Image'
    }));
    throw new Error();
  }
});

// get recurring transactions from sended funds
export const getSchedulingPayments = createAsyncThunk<RecurringPaymentsResponse, GetRecurringPaymentsPayload>(
  "payment/scheduling",
  async (payload) => {
    // alert(payload)
    const token = localStorage.getItem("token");
    const data = await apiHelper<RecurringPaymentsResponse>(`payment/get-scheduling-payments?accountId=${payload}`, "get", {}, {
      Authorization: `Bearer ${token}`,
    }
    );
    // return data;
    return data.data;
  });

export const updatePaymentStatus = createAsyncThunk<UpdateRecurringStatusResponse, UpdateRecurringStatusPayload>(
  "payment/recurring-update-status",
  async (payload) => {
    // alert(payload)
    const token = localStorage.getItem("token");
    const data = await apiHelper<UpdateRecurringStatusResponse>(`payment/recurring-update-status`, "put", payload, {
      Authorization: `Bearer ${token}`,
    }
    );
    // return data;
    return data.data;
  });
