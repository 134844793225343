import { createSlice } from "@reduxjs/toolkit";
import { InfoMessageState } from "./interface";
import { Logout } from "../auth/operation";

const initialState: InfoMessageState = {
    isVisible: true,
  };
  
  export const infoMessageSlice = createSlice({
    name: 'infoMessage',
    initialState,
    reducers: {
      showMessage: (state) => {
        state.isVisible = true;
      },
      hideMessage: (state) => {
        state.isVisible = false;
      },
    },
    extraReducers: (builder) => {
        builder.addCase(Logout.fulfilled, (state: any) => {
            state.isVisible = true;
        });
    },
  });
  
  export const { showMessage, hideMessage } = infoMessageSlice.actions;
  export default infoMessageSlice.reducer;