import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import il8next from "../../../i18next/config";
import { withTranslation } from "react-i18next";
import { BsGlobe2,BsChevronUp,BsChevronDown } from "react-icons/bs";
import COLORS from "../../../utils/colors";
import Form from 'react-bootstrap/Form';

import "./index.css";
import { LanguageIcon } from "../../../constants/Images";

const LanguageDropdown = ({ props }: any) => {
  const [selected, setSelected] = useState<any>(localStorage.getItem("lng") || "en");
  const [isDropdownOpen,setIsDropdownOpen] = useState<boolean>(false)
  const { t, i18n } = useTranslation();
  const changeLanguage = (language: any) => {
    i18n.changeLanguage(language);
    localStorage.setItem("lng", language);
    setSelected(language);
    setIsDropdownOpen(false)
  };

  const storedLanguage = localStorage.getItem("lng");
  console.log('storedLanguage: ', storedLanguage);
  useEffect(() => {
    // Set the selected language from local storage on component mount
  
    if (storedLanguage) {
      setSelected(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    } else {
      // If local storage doesn't have a language, set default to "en"
      const defaultLanguage = "en";
      localStorage.setItem("lng", defaultLanguage);
      setSelected(defaultLanguage);
      i18n.changeLanguage(defaultLanguage);
    }
  }, []);

    
  return (
    <div className="cstm-btn">
      {/* <div className="icon-select">
        <BsGlobe2 size={28} style={{ color: COLORS.pimary }} />
      </div> */}
      {/* <div>
        <select
          className="form-select"
          aria-label="Default select example"
          onChange={(e) => changeLanguage(e.target.value)}
          value={selected}
        >
          // <option value="">{t("Choose Language")}....</option>
          <option value="en">{t("English")}</option>
          <option value="da">{t("Dansk")}</option>
          <option value="sv">{t("Swedish")}</option>
        </select>
      </div> */}
      <div className="languageDropdown">
        <div style={{cursor:"pointer"}} onClick={isDropdownOpen ? () =>setIsDropdownOpen(false) : () =>setIsDropdownOpen(true) }>
          <div className="langIcon">
            <img src={LanguageIcon}/>
          </div>
          <div className="lang">
            <p>
              {selected == "sv"
                ? "Swedish"
                : selected == "da"
                ? "Danish"
                : "English"}
            </p>
          </div>
          <div className="dropdownOpen">
            {
              isDropdownOpen ?  <BsChevronUp /> : <BsChevronDown />
            }
            
          </div>
        </div>
        {
            isDropdownOpen && 
        <div className="languageList">
          {/* <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios1"
              value="option1"
              checked
            />
            <label className="form-check-label">Englist</label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios2"
              value="option2"
            />
            <label className="form-check-label">Danish</label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios2"
              value="option2"
            />
            <label className="form-check-label">Danish</label>
          </div> */}
          
          <Form.Group className="langDropDownList" controlId="kindOfStand">
            <Form.Check type="radio" aria-label="radio 0" name="group1" id="t00">
              <Form.Check.Input
                defaultChecked={storedLanguage == "en" ? true :false}
                value="en"
                onChange={(e) => changeLanguage(e.target.value)}
                type="radio"
                name="group1"
                isValid
                
              />
              <Form.Check.Label htmlFor="t00">
                {/* {t("addRecipient.form.local")} */}
                English
              </Form.Check.Label>
            </Form.Check>
            <Form.Check type="radio" aria-label="radio 1" name="group1" id="t01">
              <Form.Check.Input
              defaultChecked={storedLanguage == "da" ? true :false}
                value="da"
                onChange={(e) => changeLanguage(e.target.value)}
                type="radio"
                name="group1"
                isValid
              />
              <Form.Check.Label htmlFor="t01">
                {/* {t("addRecipient.form.sepa")} */}
                Danish
              </Form.Check.Label>
            </Form.Check>
            <Form.Check type="radio" aria-label="radio 2" id="t23" name="group1">
              <Form.Check.Input
              defaultChecked ={storedLanguage == "sv" ? true :false}
                value="sv"
                onChange={(e) => changeLanguage(e.target.value)}
                type="radio"
                name="group1"
                isValid
              />
              <Form.Check.Label htmlFor="t23">
                {/* {t("addRecipient.form.sortCodeAndAcc")} */}
                Swedish
              </Form.Check.Label>
            </Form.Check>
          </Form.Group>

          {/* className={`${isDropdownOpen ? "langDropDownListShow" :"langDropDownListHide"}`} */}
        </div>
          }
      </div>
    </div>
  );
};

export default withTranslation()(LanguageDropdown);
