import React, { Component, useState } from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import BASE_URL from "../../utils/baseURL";

const DownloadPDF = () => {
  const [state, setState] = useState({
    name: "",
    receiptId: 0,
    price1: 0,
    price2: 0,
  });
  // const [val, setVal] = useState()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const createAndDownloadPdf = () => {
    axios
      .post(BASE_URL+"create-pdf", state)
      .then(() => axios.get(BASE_URL+"fetch-pdf", { responseType: "blob" }))
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });

        saveAs(pdfBlob, "newPdf.pdf");
      });
  };
  return (
    // <div className="App">
    //   <input
    //     type="text"
    //     placeholder="Name"
    //     name="name"
    //     // value={state.name}
    //     onChange={handleChange}
    //   />
    //   <input
    //     type="number"
    //     placeholder="Receipt ID"
    //     name="receiptId"
    //     value={state.receiptId}
    //     onChange={handleChange}
    //   />
    //   <input
    //     type="number"
    //     placeholder="Price 1"
    //     name="price1"
    //     value={state.price1}
    //     onChange={handleChange}
    //   />
    //   <input
    //     type="number"
    //     placeholder="Price 2"
    //     name="price2"
    //     value={state.price2}
    //     onChange={handleChange}
    //   />
    <button
      onClick={() => {
        console.log("state before pdf", state);
        createAndDownloadPdf();
      }}
    >
      Download PDF
    </button>
    // </div>
  );
};

export default DownloadPDF;
