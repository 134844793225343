import React, { useEffect, useState } from "react";
import InputComponent from "../../components/shared/Input/Index";
import { useTranslation, withTranslation } from "react-i18next";
import {
  errorMsgs,
  regexStrConst,
  LOGO_URL
} from "../../constants/stringConstants/index";
import { useNavigate } from "react-router-dom";
import { Col, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { UserLogin } from "../../redux/toolkit/auth/operation";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import "./index.css";
import LanguageDropdown from "../../components/shared/TranslationDropdown";
import { onBoardingBackground,OMWLogoWhite } from "../../constants/Images";
import Form from 'react-bootstrap/Form';
import { BsEye,BsEyeSlash } from "react-icons/bs";
import InputTypeSecure from "../../components/shared/Input/InputTypeSecure";
import BasicButton from "../../components/shared/Buttons/Basic";
import InputTypeText from "../../components/shared/Input/Basic/InputTypeText";


const SignIn = () => {
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [password, setPassword] = useState("");
  const [inputError, setInputError] = useState("");
  const [passError, setPassError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPassShow, setIsPassShow] = useState(true)

  const { t } = useTranslation();

  const navigate = useNavigate();
  const loginDetailss = useSelector((state: RootState) => state.loginDetails);

  useEffect(() => {
    if (loginDetailss && Object.keys(loginDetailss.data).length !== 0) {
      navigate("/verification", { state: loginDetailss.data });
      console.log("User Data", loginDetailss.data);
      localStorage.setItem("userEmail", email);
      localStorage.setItem("userPhone",loginDetailss.data.data.phoneNo);
      localStorage.setItem("IsActiveUser", loginDetailss.data.data.isActive)
    }
  }, [loginDetailss]);

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    const isphone = /^\+[0-9]/.test(item);
    if (regexStrConst.phoneNumberRegex.test(item) || isphone) {
      const cleanedPhoneNumber = item.replace(/[\s()-]/g, "");
      setInputError("");
      setPhoneNo(cleanedPhoneNumber);
      setEmail("");
    } else if (regexStrConst.emailRegex.test(item)) {
      setInputError("");
      setEmail(item.toLowerCase());
      setPhoneNo("");
    } else {
      setInputError(errorMsgs.emptyInput);
    }
  };

  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    if (regexStrConst.passwordRegex.test(item)) {
      setPassError("");
    } else {
      setPassError(errorMsgs.emptyPassword);
    }
    setPassword(item);
  };

  const validate = () => {
    if (email === "" && phoneNo === "") setInputError(errorMsgs.emptyInput);
    if (password === "" || password?.length < 8) setPassError(errorMsgs.emptyPassword);

    return (email === "" && phoneNo === "") ||
      password === "" || password?.length < 8 ||
      inputError ||
      passError
      ? false
      : true;
  };

  const dispatch = useDispatch<AppDispatch>();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validate()) {
      setLoading(true);
      const payload = {
        email: email,
        phoneNo: phoneNo,
        password: password,
      };
      dispatch(UserLogin(payload)).then(() => {
        setLoading(false);
      });
    }
  };

  const isValidateEmailOrPhoneOnBlur =(e: any) => {
    if(!e && Number(e)){
      if(!regexStrConst.emailRegex.test(e)){
        setInputError(errorMsgs.emptyInput)
      }
    }
    else if(!e && !Number(e)){
      if(!regexStrConst.phoneNumberRegex.test(e)){
        setInputError(errorMsgs.emptyInput)
      }
    }
  }

  const showPass =(e:boolean) => {
    setIsPassShow(e)
  }
console.log('isPassShow',isPassShow);
  return (
    <div className="Enter">
      <div className="onBoardingImage onBoardingBGImg " style={{margin: "5px 0 7px 5px"}}>
        {/* <div className="logo-box">
          <div className="logo-wrapper">
            <img
              className="logo"
              // style={{ width: 210, height: 'auto',filter:"drop-shadow(0 0 0.4px)"}}
              src={onBoardingBackground}
              alt="One Money Way"
            ></img>
          </div>
        </div> */}
        {/* <div className="left-title-box"> */}
        <img className="omwLogo" src={OMWLogoWhite} />
        {/* <h2 className="left-title">{t("login.title.payment")} </h2>
          <h2 className="left-title-second">{t("login.title.platform")}</h2>
          <h2 className="left-title-third">{t("login.title.SMEs")}</h2> */}
        {/* </div> */}
        {/* <div className="welcome-box">
          <p className="welcome">{t("login.title.welcome")}</p>
          <p className="after-welcome">{t("login.welcomeDesc")}</p>
        </div>
        <div className="circle-box">
          <div className="blue-circle"></div>
          <div className="white-circle"></div>
        </div> */}
      </div>
      <div className="onBoardingForm form_section d-flex flex-column justify-content-start justify-content-sm-center">
      <div className="top-banner">
        <img src={OMWLogoWhite}/>
      </div>
        <div className="lng-dropdown">
          <LanguageDropdown />
        </div>
        {/* <h2 className="right-title">{t("login.title.enter")}</h2>
        <p className="right-text">{t("login.enterDesc")}</p> */}

        <p className="onBoarding-title mt-4">{t("login.title.welcome")}</p>
        <p className="onBoarding-text">{t("login.enterDesc")}</p>

        <form className="enter-form " onSubmit={handleSubmit}>
          <p className="inputLabel">{t("emailOrNumber")}</p>
          {/* <input
            type="text"
            className="inputTextBox"
            // placeholder={t("pEmailOrNumber")}
            defaultValue={email ? email : phoneNo}
            onBlur={
              (e) => isValidateEmailOrPhoneOnBlur(e.target.value)
              // email === "" && phoneNo === ""
              //   ? setInputError(errorMsgs.emptyInput)
              //   : setInputError("")
            }
            onChange={onChangeEmail}
          />

          <span style={{ color: "red" }}>{inputError}</span> */}
          <InputTypeText
            type="text"
            className="inputText"
            // placeholder={t("pEmailOrNumber")}
            defaultValue={email ? email : phoneNo}
            onBlur={
              (e) => isValidateEmailOrPhoneOnBlur(e.target.value)
              // email === "" && phoneNo === ""
              //   ? setInputError(errorMsgs.emptyInput)
              //   : setInputError("")
            }
            onChange={onChangeEmail}
            // errorMsg={inputError}
            errorMsg={inputError && `${t("errorMsgs.emptyInput")}`}
          />
          <p className="inputLabel pass-text">{t("password")}</p>
          {/* <div className="inputPassDiv">
            <input
              type={`${isPassShow ? 'password' : 'text'}`}
              className="inputTextBox inputPass"
              // placeholder={t("enterPass")}
              value={password}
              onBlur={() =>
                password === ""
                  ? setPassError(errorMsgs.emptyPassword)
                  : setPassError("")
              }
              onChange={onChangePassword}
            />
            <span className="viewPassIcon">
              {isPassShow ? (
                <BsEyeSlash className="showPassIcon" onClick={() => showPass(false)}/>
              ) : (
                <BsEye className="showPassIcon" onClick={() => showPass(true)}/>
              )}
            </span>
          </div>
            <span style={{ color: "red" }}>{passError}</span> */}




{/* <hr/> */}
          <InputTypeSecure
            type={`${isPassShow ? 'password' : 'text'}`}
            className="inputTextBox inputPass"
            value={password}
            onBlur={() =>
              password === ""
                ? setPassError(errorMsgs.emptyPassword)
                : setPassError("")
            }
            // errorMsg={passError}
            errorMsg={passError && `${t("errorMsgs.emptyPassword")}`}
            onChange={onChangePassword}
          />

          {/* <InputComponent
            type="password"
            className="inputText"
            placeholder={t("enterPass")}
            value={password}
            onBlur={() =>
              password === ""
                ? setPassError(errorMsgs.emptyPassword)
                : setPassError("")
            }
            onChange={onChangePassword}
            errorMsg={passError}
          /> */}
          <div className="rememberMe-forgotPassDiv">
            <div className="rememberMe">
              <Form.Check // prettier-ignore
                type={"checkbox"}
                id={`default-checkbox`}
                label={t("rememberMe")}
              />
            </div>
            <div className="forgetPass">
              <Link className="forgot-link" to="/forgotPassword">
                {/* Forget Password? */}
                {t("forgotPass")}
              </Link>
            </div>
          </div>
          {/* <Link className="forgot-link" to="/forgotPassword">
            {t("forgotPass")}
          </Link> */}
          <div className="btn-box">
            <BasicButton
            type='submit'
            isLoading={loading}
            disabled={loading ? true : false}
            text={t("signin")}
            />
            <div className="onBoardingFooterText mt-3">
              <p>
                {/* Don&apos;t have an account? */}
                {t("login.dontHaveAccount")}
                <span
                  // className="registration"
                  onClick={() => navigate("/registration")}
                >
                  {" "}
                  {t("register")+'?'}
                </span>
              </p>
            </div>
            {/* <button
              className="reg-btn btn btn-primary"
              onClick={() => navigate("/registration")}
            >
              {t("register")}
            </button> */}
          </div>
        </form>
        {/* <form className="enter-form" onSubmit={handleSubmit}>
          <p className="email-text">{t("emailOrNumber")}</p>
          <InputComponent
            type="text"
            placeholder={t("pEmailOrNumber")}
            defaultValue={email ? email : phoneNo}
            onBlur={(e) => isValidateEmailOrPhoneOnBlur(e.target.value)
              // email === "" && phoneNo === ""
              //   ? setInputError(errorMsgs.emptyInput)
              //   : setInputError("")
            }
            onChange={onChangeEmail}
            errorMsg={inputError}
          />
          <p className="pass-text">{t("password")}</p>
          <InputComponent
            type="password"
            className="pass-inp"
            placeholder={t("enterPass")}
            value={password}
            onBlur={() =>
              password === ""
                ? setPassError(errorMsgs.emptyPassword)
                : setPassError("")
            }
            onChange={onChangePassword}
            errorMsg={passError}
          />
          <Link className="forgot-link" to="/forgotPassword">
            {t("forgotPass")}
          </Link>
          <div className="btn-box">
            <button type="submit" className="sign-btn btn btn-primary">
              {loading ? <Spinner animation={"border"} /> : `${t("signin")}`}
            </button>
            <button
              className="reg-btn btn btn-primary"
              onClick={() => navigate("/registration")}
            >
              {t("register")}
            </button>
          </div>
        </form> */}
      </div>
    </div>
  );
};

export default withTranslation()(SignIn);
